import React from 'react';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import * as segment from './segmentLogger';
import {
  ACCEPTED_UPLOAD_TYPES,
  MATERIAL_CODES_WITH_REACH_LETTER
} from './constants';

// Retrieved from https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
const isIe11 = () => !!window.MSInputMethodContext && !!document.documentMode;

const pipe = (...fns) => arg => fns.reduce((prev, fn) => fn(prev), arg);

const getAnnouncementMutationSuccessAlert = queryParams => {
  if (
    queryParams.get('create-success') === 'true' ||
    queryParams.get('edit-success') === 'true'
  ) {
    const title =
      queryParams.get('create-success') === 'true' ? 'Created' : 'Edited';
    return (
      <OxSoftAlert
        title={`Your Announcement Was Successfully ${title}`}
        icon="check"
        alertType="success"
        giDataAttr={`${title.toLowerCase()}-announcement__success`}
        isOutlined
      />
    );
  }
  return null;
};

/**
 * Use RegEx to determine if a string is a valid email address
 * @param {string} email
 */
const isEmailFormatValid = email => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && !email.endsWith('.co') && regEx.test(email);
};

/**
 * Used for when user presses 'Enter' while searching for an account or an order
 * @param {*} event
 * @param {*} history
 * @param {*} searchType
 * @param {*} callback
 * @param {*} path
 */
const handleKeyUpForSearch = (event, history, searchType, callback, path) => {
  let gaAction = '';
  const gaCategory = 'Search';
  let gaLabel = '';
  const logSegmentEvent = () => {
    segment.logEvent({
      actionName: gaAction,
      categoryName: gaCategory,
      labelName: gaLabel
    });
  };
  if (event.key === 'Enter') {
    gaLabel = path;
    if (searchType === 'account') {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/ /g, '');
      gaAction = 'Search for an Account';
      logSegmentEvent();
      history.push(
        `/accounts/${
          isEmailFormatValid(event.target.value) ? 'email' : 'details'
        }/${encodeURIComponent(event.target.value)}`
      );
    } else if (searchType === 'order') {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/ /g, '');
      gaAction = 'Search for an Order';
      logSegmentEvent();
      history.push(
        `/my-orders/details/${encodeURIComponent(event.target.value)}`
      );
    } else if (searchType === 'resources' && !!event.target.value) {
      gaAction = 'Searched for a Document';
      gaLabel = 'Resource Section';
      logSegmentEvent();

      history.push(
        `/resources/search?query=${encodeURIComponent(event.target.value)}`
      );
    }
  }
  /* There is an optional callback to pass into OxSearch component and this function.
  The default callback will set state in the OxSearch component to the User Input.
  If you would like to handle input differently, pass in a inputFunction prop to OxSearch. */
  if (callback) {
    callback();
  }
};

// Determines if an array is empty for arrays returned from graphql
const isGraphqlArrayEmpty = (response, property) =>
  response && response[property] && response[property].length === 0;

// Determines if an array is empty when retrieving nested data
const isGraphqlArrayEmptyForNested = (response, property) =>
  response &&
  response[property] &&
  ((response[property].data && response[property].data.length === 0) ||
    response[property].data === null);

const toTitleCase = title =>
  title.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()
  );

const isInternal = data => {
  return (
    data &&
    data.userInfo &&
    data.userInfo.userType &&
    (data.userInfo.userType.name === 'internal' ||
      data.userInfo.userType.name === 'api')
  );
};

const isExternalUser = data => {
  return (
    data &&
    data.userInfo &&
    data.userInfo.userType &&
    data.userInfo.userType.name === 'external'
  );
};

const isExternalUrl = url => {
  return url && (url.includes('http://') || url.includes('https://'));
};

const getNYearFromNowDate = yearsAgo => {
  return `${
    new Date(new Date().setFullYear(new Date().getFullYear() - yearsAgo))
      .toISOString()
      .split('-')
      .join('')
      .split('T')[0]
  } 140000.000`;
};

const IsValidInteger = orderString => /^[0-9]{1,9}$/.test(orderString);

/**
 * Given a javascript File object, returns a base64 encoding of the file.
 * See https://stackoverflow.com/a/57272491 for more info
 * @param {File} file
 */
const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = error => reject(error);
  });
};

/**
 * Determine if a file is one of the accepted upload types we are able to process
 * @param {File} file
 */
const isAcceptedImageFile = file => {
  const uploadExtension = file.name
    .toLowerCase()
    .split('.')
    .pop();
  const imageTypeExtensions = ACCEPTED_UPLOAD_TYPES.map(
    fileType => fileType.extension
  );

  return imageTypeExtensions.some(extension => extension === uploadExtension);
};

/**
 * Takes a human readable file size and converts into bytes for comparison
 * Ex: getFileSizeFromString('63.5kb') returns 65024
 * See https://stackoverflow.com/a/6974728 for more
 */
const getFileSizeFromString = text => {
  const powers = { k: 1, m: 2, g: 3, t: 4 };
  const regex = /(\d+(?:\.\d+)?)\s?(k|m|g|t)?b?/i;

  const res = regex.exec(text);

  return res[1] * 1024 ** powers[res[2].toLowerCase()];
};

/**
 * Determines if a given file is a ThermoDrive Engineering Manual
 * @param {ProductDocument} doc
 * @returns {Boolean}
 */
const isThermoDriveManual = doc =>
  doc.name.toLowerCase().includes('thermodrive');

/**
 *  Sorts engineering manuals putting thermodrive last
 */

const sortEngineeringManuals = engineeringManuals => {
  const manuals = engineeringManuals.slice();
  manuals
    .sort((docA, docB) => {
      const isSeriesManual = doc =>
        doc.name.match(/S\d+/) || doc.name.includes('sprocket');

      if (isSeriesManual(docA) && isSeriesManual(docB)) {
        // Sort manuals by series number ascending
        const docASeriesNumber = docA.name.match(/S\d+/)
          ? +docA.name.match(/\d+/)[0]
          : 0;
        const docBSeriesNumber = docB.name.match(/S\d+/)
          ? +docB.name.match(/\d+/)[0]
          : 0;
        return docASeriesNumber - docBSeriesNumber;
      }
      return 0;
    });
  manuals
    .sort((docA, docB) => {
      // Put ThermoDrive Engineering Manuals at the bottom
      let returnValue = 0;

      if (isThermoDriveManual(docA) && !isThermoDriveManual(docB)) {
        returnValue = 1;
      } else if (
        (isThermoDriveManual(docA) && isThermoDriveManual(docB)) ||
        (!isThermoDriveManual(docA) && !isThermoDriveManual(docB))
      ) {
        returnValue = 0;
      } else if (!isThermoDriveManual(docA) && isThermoDriveManual(docB)) {
        return -1;
      }

      return returnValue;
    });
  return manuals;
};
/**
 * Sort function to be used when sorting files by file size
 */
const fileSizeSortFunction = (fileA, fileB, sortDirection) => {
  const fileASize = getFileSizeFromString(fileA.size);
  const fileBSize = getFileSizeFromString(fileB.size);
  return sortDirection === 'ASC'
    ? fileASize - fileBSize
    : fileBSize - fileASize;
};

/**
 * Sort languages alphabetically
 */
const alphabeticalSortFunction = (fileA, fileB, sort) => {
  return sort.order === 'ASC'
    ? fileA[sort.orderBy].localeCompare(fileB[sort.orderBy])
    : fileB[sort.orderBy].localeCompare(fileA[sort.orderBy]);
};

/**
 * Sort function to be used when sorting by document type
 */
const docTypeSortFunction = (fileA, fileB, sortDirection) => {
  // If documents are the same type, don't change the order
  let returnValue = fileA.documentType === fileB.documentType ? 0 : undefined;

  // Only go into one of the if blocks if returnValue is undefined
  if (sortDirection === 'ASC' && typeof returnValue === 'undefined') {
    returnValue =
      fileA.documentType.toLowerCase() > fileB.documentType.toLowerCase()
        ? 1
        : -1;
  } else if (sortDirection === 'DESC' && typeof returnValue === 'undefined') {
    returnValue =
      fileA.documentType.toLowerCase() > fileB.documentType.toLowerCase()
        ? -1
        : 1;
  }

  return returnValue;
};

const materialCodeGetsReachLetter = materialCodeToCheck => {
  return MATERIAL_CODES_WITH_REACH_LETTER.some(
    materialCode => materialCode === materialCodeToCheck
  );
};

const getAccountIdFromParams = params => params.accountId;

const matchExternalAccountId = (userData, params) => {
  const info = userData.accountId.accountIds.includes(
    getAccountIdFromParams(params)
  );
  return !!info;
};

/**
 * Starts a download of a file with a given URL by
 * creating a "ghost" anchor element and clicking it
 * @param {*} url
 */
const downloadFileFromUrl = url => {
  const link = document.createElement('a');
  link.download = url;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const canShareDocument = (userData, document) => {
  if (
    userData &&
    userData.userInfo &&
    (userData.userInfo.userType.name === 'internal' ||
      userData.userInfo.userType.name === 'api') &&
    getFileSizeFromString(document.size) < 10e6 // 10mb
  ) {
    const shareableDocumentTypes = [
      'Commercial Invoice',
      'Accounting Invoice',
      'Packing Slip',
      'Technical Document',
      'Style Specific Manual',
      'Series Specific Manual',
      'Design Guidelines',
      'Installation Instructions',
      'Checklist',
      'Product Line Extensions',
      'User Manual',
      'Compliance Document',
      'Material Specific Compliance Letter'
    ];

    return shareableDocumentTypes.includes(document.documentType);
  }
  return false;
};

const uniqueArrayByValue = (documentArray, uniqueIdentifier) => {
  const uniqueSet = new Set();
  return documentArray.reduce((uniqueArray, doc) => {
    if (!uniqueSet.has(doc[uniqueIdentifier])) {
      uniqueSet.add(doc[uniqueIdentifier]);
      uniqueArray.push(doc);
    }
    return uniqueArray;
  }, []);
};

/**
 * Modified implementation of https://stackoverflow.com/a/26528271
 * Given a string length, returns an alphanumeric string consisting
 * of random letters/numbers
 * @param {Number} length
 * @returns {String}
 */
const getRandomAlphanumericString = length => {
  const pattern = /[a-zA-Z0-9]/;

  const _getRandomByte = () => {
    const result = new Uint8Array(1);
    window.crypto.getRandomValues(result);
    return result[0];
  };

  // eslint-disable-next-line prefer-spread
  return Array.apply(null, { length })
    .map(() => {
      let result = '';

      while (!pattern.test(result)) {
        result = String.fromCharCode(_getRandomByte());
      }

      return result;
    }, this)
    .join('');
};

const seperateOutdatedUniqueDocs = docArray => {
  const year = new Date().getFullYear().toString();
  const uniqueSet = new Set();
  const outdatedDocs = [];
  const dictionary = {};

  const documentNameYearRemoved = inputString =>
    inputString.slice(0, inputString.length - year.length);
  const lastCharacters = inputString => inputString.slice(-year.length);

  const upToDateDocs = [];

  docArray.reduce((seed, current) => {
    // if is unique keep going
    if (!uniqueSet.has(current.name)) {
      uniqueSet.add(current.name);
    } else {
      // if copy then return
      return seed;
    }

    // we're testing to see if this document ends with a space followed by 4 digits
    // if not a year, fast track to the next doc.
    // Not quite Y3K compliant but it's safe
    if (
      !current.name.match(/\s\d{4}$/) &&
      current.name[current.name.length - 4] !== year[0]
    ) {
      upToDateDocs.push(current);
      return seed;
    }

    // IF any two docs share all of the same characters until the last 4, this will beccome an issue,
    // but until then this seemed a reasonable compramise.
    const dictionaryLookup = dictionary[documentNameYearRemoved(current.name)];
    const currentDocYear = Number(lastCharacters(current.name));
    if (dictionaryLookup && !Number.isNaN(dictionaryLookup.year)) {
      // is higher year
      if (currentDocYear > dictionaryLookup.year) {
        outdatedDocs.push(dictionaryLookup.doc);
        dictionaryLookup.year = currentDocYear;
        dictionaryLookup.doc = current;
      } else {
        outdatedDocs.push(current);
      }
    } else {
      dictionary[documentNameYearRemoved(current.name)] = {
        year: currentDocYear,
        doc: current
      };
    }

    return seed;
  }, []);

  // all current dictionary values are up to date
  let currentDocs = upToDateDocs.concat(
    Object.values(dictionary).map(document => document.doc)
  );

  // The order that the documents should appear in
  const documentTypeRanks = {
    'accounting invoice': 1,
    'packing slip': 2,
    'commercial invoice': 3,
    'series specific manual': 4,
    'technical document': 5,
    'design guidelines': 6,
    'material specific compliance letter': 7,
    'compliance document': 8
  };

  // Ensure documents are in correct order
  currentDocs = currentDocs.sort((docA, docB) => {
    // If document type not listed in rank, put it at the end
    return (
      (documentTypeRanks[docA.documentType.toLowerCase()] ||
        Number.MAX_SAFE_INTEGER) -
      (documentTypeRanks[docB.documentType.toLowerCase()] ||
        Number.MAX_SAFE_INTEGER)
    );
  });

  return {
    currentDocs,
    outdatedDocs
  };
  // loop through array, if ("ends with current year to string")
  // add to set
  // if not year dated, add anyway
  // loop through again for not 2021
  // if set includes newer version, add to aux array
};

/**
 * Determine if app is embedded in iframe
 * See: https://stackoverflow.com/a/326076
 * @returns {Boolean} if app is inside of an iframe
 */
const isEmbedded = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Determine if user is an admin user
 * @param {*} userData
 */
const userIsAdmin = userData => {
  return userData.userInfo.allFeatures.some(
    f => f.name.toLowerCase() === 'admin'
  );
};

/**
 * Determine if user can see commercial invoices
 *
 * @param {*} userData
 * @returns {Boolean}
 */
const showCommercialInvoices = userData => {
  const allowedJobTitles = [
    'Account Representative',
    'Account Manager - Internal',
    'Credit Coordinator', // Andrew Koehler approved access for this role on 2023-01-20
    'Credit Specialist', // Andrew Koehler approved access for this role on 2023-01-20
  ];

  // Show commercial invoices if user is admin, has an allowed job title or is in Customer Service
  return userData && userData.userInfo && userData.userInfo.jobTitle
    ? userIsAdmin(userData) ||
        allowedJobTitles.some(
          jobTitle =>
            jobTitle.toLowerCase() === userData.userInfo.jobTitle.toLowerCase()
        ) ||
        userData.userInfo.jobTitle.toLowerCase().indexOf('customer service') !==
          -1
    : false;
};

/**
 * Determine if a shipment is overdue
 * @param {*} shipment
 * @returns {Boolean} true if shipment's expected delivery date is more than one day overdue
 */
const isShipmentOverdue = shipment => {
  return (
    !shipment.trackingNumber.value.includes('expired') &&
    shipment.deliveryStatus.value !== 'Delivered' &&
    new Date().getTime() -
      new Date(shipment.expectedDeliveryDate.value).getTime() >
      86400000 // One day in ms
  );
};

/**
 * Uses the existing "REACT_APP_REPOREF" environment variable to determine
 * what environment the app is running in.  When in prod, "REACT_APP_REPOREF" 
 * will be a tag or version number, otherwise, it will contain a string with 
 * the environment we are running in.
 */ 
const getCurrentEnv = () => {
  const nonProdEnvs = ["local", "dev", "stage"];
  const repoRef = process.env.REACT_APP_REPOREF;

  return !nonProdEnvs.includes(repoRef) ? "prod" : repoRef;
};

export {
  getAnnouncementMutationSuccessAlert,
  handleKeyUpForSearch,
  isIe11,
  isGraphqlArrayEmpty,
  isExternalUrl,
  isGraphqlArrayEmptyForNested,
  toTitleCase,
  isInternal,
  getNYearFromNowDate,
  IsValidInteger,
  pipe,
  toBase64,
  isAcceptedImageFile,
  getFileSizeFromString,
  isEmailFormatValid,
  isExternalUser,
  isThermoDriveManual,
  fileSizeSortFunction,
  docTypeSortFunction,
  sortEngineeringManuals,
  materialCodeGetsReachLetter,
  matchExternalAccountId,
  getAccountIdFromParams,
  alphabeticalSortFunction,
  downloadFileFromUrl,
  canShareDocument,
  uniqueArrayByValue,
  getRandomAlphanumericString,
  seperateOutdatedUniqueDocs,
  isEmbedded,
  showCommercialInvoices,
  userIsAdmin,
  isShipmentOverdue,
  getCurrentEnv,
};
