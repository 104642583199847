import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import '../../styles/components/_ox-notification.scss';
import * as segment from '../../helpers/segmentLogger';

export const OxNotificationContext = React.createContext({
  // eslint-disable-next-line no-unused-vars
  showNotification: (newHeader, newMessage, newIcon, giDataAttr, buttons) => {},
  hideNotification: () => {}
});
export const OxNotificationProvider = OxNotificationContext.Provider;
export const OxNotificationConsumer = OxNotificationContext.Consumer;

export const OxNotification = ({ children }) => {
  const [isVisible, _setIsVisible] = useState(false);
  const [header, _setHeader] = useState('');
  const [message, _setMessage] = useState('');
  const [icon, _setIcon] = useState('');
  const [buttons, _setButtons] = useState([]);

  const _showNotification = (
    newHeader,
    newMessage,
    newIcon,
    giDataAttr,
    newButtons
  ) => {
    _setHeader(newHeader);
    _setMessage(newMessage);
    _setIcon(newIcon);
    _setButtons(newButtons);
    _setIsVisible(true);
    if (giDataAttr) {
      segment.logEvent({
        giDataAttr,
        eventName: segment.eventTypes.notification
      });
    }
  };

  const _hideNotification = () => _setIsVisible(false);

  return (
    <OxNotificationProvider
      value={{
        isVisible,
        showNotification: _showNotification,
        hideNotification: _hideNotification
      }}
    >
      <div className={`ox-notification ${isVisible ? 'show' : 'hidden'}`}>
        <div className="units-row stacked">
          <FeatherIcon
            className="ox-notification__icon unit-20 icon-width"
            icon={icon}
          />
          <h4 className="unit-auto ox-notification__header">{header}</h4>
          <button
            className="btn-icon unit-20 ox-notification__close-icon icon-width"
            type="button"
            tabIndex={isVisible ? '-1' : '0'}
            onClick={_hideNotification}
          >
            <FeatherIcon icon="x" />
          </button>
        </div>
        <div className="units-row">
          <div className="unit-20 icon-width" />
          <span className="unit-auto ox-notification__message">
            <p className="small">{message}</p>
            {buttons ? (
              <div className="ox_notification__buttons">
                {buttons.map(button => (
                  <button
                    className={button.className}
                    onClick={button.onClick}
                    id={button.id}
                    key={button.id}
                    type="button"
                  >
                    {button.text}
                  </button>
                ))}
              </div>
            ) : null}
          </span>
        </div>
      </div>
      {children}
    </OxNotificationProvider>
  );
};
