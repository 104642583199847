import { gql } from '@apollo/client';

const GET_ANNOUNCEMENTS_BY_USER = gql`
  query {
    announcementsByUser {
      id
      body
      action
      actionText
      type
      createdAt
      expireAt
      newTab
      downloadAction
    }
  }
`;

const GET_ANNOUNCEMENTS = gql`
  query announcement($where: AnnouncementWhereInput) {
    announcements(where: $where) {
      id
      body
      action
      actionText
      type
      createdAt
      expireAt
      newTab
      downloadAction
      userRoles {
        id
        name
      }
      features {
        id
        name
      }
      userTypes {
        id
        name
      }
    }
  }
`;

export { GET_ANNOUNCEMENTS_BY_USER, GET_ANNOUNCEMENTS };
