import React from 'react';
import propTypes from 'prop-types';
import '../../styles/components/_ox-loader.scss';

// Retrieved from https://codepen.io/aarjithn/pen/bXEWVG
const OxLoader = ({ title, subtitle, fullContainer }) => {
  OxLoader.propTypes = {
    title: propTypes.string,
    subtitle: propTypes.string,
    fullContainer: propTypes.bool
  };

  return (
    <div className="ox-loader-container">
      <div
        className={
          fullContainer
            ? 'loading-bar-container full-width'
            : 'loading-bar-container'
        }
      >
        <div className="loading-bar-container__loader" />
      </div>
      {title ? <h4>{title}</h4> : null}
      {subtitle ? <div className="small">{subtitle}</div> : null}
    </div>
  );
};

export default OxLoader;
