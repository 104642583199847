import React from 'react';
import { useQuery } from '@apollo/client';
import OxCard from '../components/molecules/OxCard';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import { GET_USER_INFO } from '../api/user/query';

const _getExternalMessage = () => {
  return (
    <p className="no-access-external-text">
      We&apos;re sorry, but we cannot show you this information. If you feel
      like this is in error, please contact your customer service
      representative.
    </p>
  );
};

const _getInternalMessage = () => {
  return (
    <p className="no-access-internal-text">
      We&apos;re sorry, but we cannot show you this information. If you feel
      like this is in error, please create an IT ticket.
    </p>
  );
};

const NoAccess = () => {
  const { data } = useQuery(GET_USER_INFO);
  return data && data.userInfo && data.userInfo.userType ? (
    <main className="interior-layout">
      <div className="interior-layout__content">
        <OxCard header={{ title: 'No Access' }} id="no-access_oxcard">
          <OxSoftAlert
            alertType="info"
            eventName="access"
            id="no-access-alert"
            giDataAttr="no-access__alert-title"
            title="Sorry, you don't have access to this page"
          >
            {data.userInfo.userType.name === 'internal'
              ? _getInternalMessage()
              : _getExternalMessage()}
          </OxSoftAlert>
        </OxCard>
      </div>
    </main>
  ) : null;
};

export default NoAccess;
