import React from 'react';
import propTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import OxLoader from '../tokens/OxLoader';
import OxButton from './OxButton';
import { DOWNLOAD_MULTIPLE_DOCUMENTS } from '../../api/documents/query';
import { downloadFileFromUrl } from '../../helpers';

const MultiFileDownload = ({ docsToDownload, handleDownloadCompletion }) => {
  MultiFileDownload.propTypes = {
    docsToDownload: propTypes.arrayOf(
      propTypes.shape({
        containsManuals: propTypes.bool,
        containsThermoDriveDocuments: propTypes.bool,
        documentType: propTypes.string,
        fileType: propTypes.string,
        id: propTypes.string,
        language: propTypes.string,
        name: propTypes.string,
        orderNumber: propTypes.string,
        size: propTypes.string,
        url: propTypes.string,
        _typename: propTypes.string
      })
    ),
    handleDownloadCompletion: propTypes.func
  };

  const { loading, data } = useQuery(DOWNLOAD_MULTIPLE_DOCUMENTS, {
    variables: { where: docsToDownload },
    onCompleted: res => {
      if (handleDownloadCompletion) {
        handleDownloadCompletion(res);
      }

      downloadFileFromUrl(res.multiDocumentDownload.url);
    }
  });

  return (
    <div className="download-btn__modal-body">
      {loading && (
        <OxLoader
          fullContainer
          title="Please wait"
          subtitle="We are generating your documents"
        />
      )}
      {!loading &&
        data &&
        data.multiDocumentDownload &&
        data.multiDocumentDownload.url && (
          <div>
            <div className="units-row">
              <div className="unit-100 justify-center">
                <p>
                  Your download was generated successfully and your download has
                  started.
                </p>
              </div>
            </div>
            <div className="units-row justify-center">
              <div className="unit-100">
                <OxButton
                  download
                  helperClass="small modal-body__start-download-link"
                  element="a"
                  text="If your download does not automatically start, click here."
                  {...(data &&
                    data.multiDocumentDownload && {
                      href: data.multiDocumentDownload.url
                    })}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default MultiFileDownload;
