import React from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../../styles/components/_ox-button.scss';
import useTabbableClass from '../../hooks/useTabbableClass';

const OxButton = ({
  id,
  type,
  text,
  href,
  size,
  isOutlined,
  helperClass,
  isSquare,
  element,
  clickFunc,
  tabIndex,
  buttonType,
  isDisabled,
  giDataAttr,
  noScale,
  withIcon,
  loading,
  download
}) => {
  OxButton.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    download: PropTypes.bool,
    loading: PropTypes.bool,
    noScale: PropTypes.bool,
    buttonType: PropTypes.oneOf(['submit', 'reset', 'button', 'link', 'a']),
    href: PropTypes.string,
    element: PropTypes.oneOf(['a', 'link', 'input', 'button', 'span'])
      .isRequired,
    size: PropTypes.oneOf(['small', 'big', 'smaller', 'default']),
    type: PropTypes.oneOf([
      'blue',
      'yellow',
      'red',
      'green',
      'black',
      'white',
      'primary',
      'secondary',
      'success',
      'danger',
      'error',
      'warning',
      'info',
      'light',
      'dark'
    ]),
    isOutlined: PropTypes.bool,
    helperClass: PropTypes.string,
    isSquare: PropTypes.bool,
    clickFunc: PropTypes.func,
    giDataAttr: PropTypes.string.isRequired,
    withIcon: PropTypes.shape({
      icon: PropTypes.string.isRequired
    })
  };
  const tabbableClass = useTabbableClass(id);

  const getTextWithIcon = icon => (
    <div className="display--flex flex-no-wrap no-wrap">
      <span className="text-left width-100">{text}</span>
      <div className={`btn-with-icon ${loading ? 'icon-spinning' : ''}`}>
        <FeatherIcon className="btn-test" icon={icon} />
      </div>
    </div>
  );

  const getBtnContent = () => {
    if (loading) {
      return getTextWithIcon('loader');
    }
    return withIcon ? getTextWithIcon(withIcon.icon) : text;
  };

  const _getBtnType = () => {
    if (type) {
      switch (type) {
        case 'primary':
          return 'blue';
        case 'secondary':
          return 'secondary';
        case 'success':
          return 'green';
        case 'danger':
          return 'red';
        case 'error':
          return 'red';
        case 'warning':
          return 'yellow';
        case 'info':
          return 'secondary';
        case 'light':
          return 'white';
        case 'dark':
          return 'black';
        default:
          return type;
      }
    }
    return 'button';
  };

  const _getBtnClass = () => {
    return `btn 
      ${size && `btn-${size}`} 
      ${_getBtnType() && `btn-${_getBtnType()}`}
      ${isOutlined && 'btn-outline'} 
      ${helperClass && helperClass}
      ${isSquare && 'btn-straight'}
      ${noScale && 'btn--no-scale'}`;
  };

  const _getBtn = () => {
    if (element === 'a') {
      return (
        <a
          download={download}
          href={href}
          onClick={clickFunc}
          className={`${_getBtnClass()} ${tabbableClass}`}
          id={id}
          tabIndex={isDisabled ? -1 : 0}
          disabled={isDisabled || false}
          data-gi={giDataAttr}
        >
          {getBtnContent()}
        </a>
      );
    }
    if (element === 'link') {
      return (
        <Link
          to={href}
          className={`${_getBtnClass()} ${tabbableClass}`}
          id={id}
          tabIndex={isDisabled ? -1 : 0}
          disabled={isDisabled || false}
          data-gi={giDataAttr}
        >
          {getBtnContent()}
        </Link>
      );
    }
    if (element === 'button') {
      return (
        /* eslint-disable react/button-has-type */
        <button
          type={buttonType}
          className={`${_getBtnClass()} ${tabbableClass}`}
          id={id}
          onClick={event => {
            if (clickFunc) {
              clickFunc(event);
            }
          }}
          tabIndex={tabIndex}
          disabled={isDisabled || false}
          data-gi={giDataAttr}
        >
          {getBtnContent()}
        </button>
      );
    }
    if (element === 'input') {
      return (
        <input
          type={buttonType || 'submit'}
          className={_getBtnClass()}
          value={text}
          id={id}
          onClick={clickFunc}
          tabIndex={tabIndex}
          disabled={isDisabled || false}
          data-gi={giDataAttr}
        />
      );
    }
    return (
      <span
        className={_getBtnClass()}
        id={id}
        role="button"
        onClick={clickFunc}
        onKeyUp={clickFunc}
        tabIndex={tabIndex}
        disabled={isDisabled || false}
        data-gi={giDataAttr}
      >
        {getBtnContent()}
      </span>
    );
  };

  return _getBtn();
};

export default OxButton;
