import { gql } from '@apollo/client';

const ACCOUNTS_FOR_CUSTOMER_DASHBOARD = gql`
  query accounts($where: AccountsWhereInput) {
    accounts(where: $where) {
      headers: dashboardHeaders {
        _id
        key
        displayValue
      }
      data {
        _id {
          value
        }
        name {
          value
          url {
            value
            iconState
          }
        }
        telephone {
          value
        }
        industry {
          value
        }
        openOrders {
          value
          url {
            value
            iconState
          }
        }
        totalOrders {
          value
          url {
            value
            iconState
          }
        }
        lastContactDate {
          value
        }
      }
    }
  }
`;

const ACCOUNTS_FOR_CUSTOMER_PAGES = gql`
  query accounts($where: AccountsWhereInput) {
    accounts(where: $where) {
      headers: customerHeaders {
        _id
        key
        displayValue
      }
      data {
        _id {
          value
        }
        name {
          value
          url {
            value
            iconState
          }
        }
        industry {
          value
        }
        segment {
          value
        }
        pendingOrders {
          value
          url {
            value
            iconState
          }
        }
        processedOrders {
          value
          url {
            value
            iconState
          }
        }
        openOrders {
          value
          url {
            value
            iconState
          }
        }
        totalOrders {
          value
          url {
            value
            iconState
          }
        }
      }
    }
  }
`;

const ACCOUNTS_FOR_EXTERNAL_USERS = gql`
  query accounts($where: [AccountsWhereInput]) {
    accounts(where: $where) {
      headers: customerHeaders {
        _id
        key
        displayValue
      }
      data {
        _id {
          value
        }
        name {
          value
          url {
            value
            iconState
          }
        }
        industry {
          value
        }
        segment {
          value
        }
        openOrders {
          value
          url {
            value
            iconState
          }
        }
        totalOrders {
          value
          url {
            value
            iconState
          }
        }
      }
    }
  }
`;

export {
  ACCOUNTS_FOR_CUSTOMER_DASHBOARD,
  ACCOUNTS_FOR_CUSTOMER_PAGES,
  ACCOUNTS_FOR_EXTERNAL_USERS
};
