import Interior from '../../pages/Interior';
import MyCustomers from '../../pages/MyCustomers';
import CustomerSearchResults from '../../pages/CustomerSearchResults';
import { orders, accounts } from '../../helpers/authHelpers';
import { isExternalUser, isInternal, userIsAdmin } from '../../helpers';
import AllCustomers from '../../pages/AllCustomers';

// Home.js wil run these functions to determine if a user can access these routes.

/**
 * If a user has orders, they should be able to see accounts.
 * @param {*} user
 */
const userHasOrdersOrAccounts = user => {
  return (
    userIsAdmin(user) ||
    [orders, accounts].some(authFeature => {
      return (
        user.userInfo.allFeatures.findIndex(
          feature => feature.name === authFeature
        ) !== -1
      );
    })
  );
};

/**
 * External users can only see these routes if they have
 * access to multiple accounts.
 * @param {*} user
 */
const externalHasMultipleAccounts = async user => {
  const { accountIds } = user.accountId;
  return user.userInfo.userType.name === 'internal'
    ? true
    : accountIds && accountIds && accountIds.length > 1;
};

const myAccountsRoutes = {
  key: 'accounts',
  icon: 'user',
  title: 'My Accounts',
  path: '/accounts',
  giDataAttr: 'accountsLink',
  isPrivate: true,
  component: Interior,
  routes: [
    {
      key: 'my-accounts',
      title: 'My Accounts',
      path: '/accounts',
      exact: true,
      isPrivate: true,
      component: MyCustomers,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    },
    {
      key: 'account-search',
      title: 'Customer Search',
      path: '/accounts/email/:searchValue',
      exact: true,
      isPrivate: true,
      component: CustomerSearchResults,
      authFunctions: [userHasOrdersOrAccounts, isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      hideInInteriorNav: true,
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my accounts',
        labelName: 'My Accounts'
      }
    },
    {
      key: 'account-search',
      title: 'Customer Search',
      path: '/accounts/details/:searchValue',
      exact: true,
      isPrivate: true,
      component: CustomerSearchResults,
      authFunctions: [userHasOrdersOrAccounts, isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      hideInInteriorNav: true,
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my accounts',
        labelName: 'My Accounts'
      }
    },
    {
      key: 'all-accounts',
      title: 'All Accounts',
      path: '/accounts/all',
      exact: true,
      isPrivate: true,
      hideInInteriorNav: isExternalUser,
      component: AllCustomers,
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my accounts',
        labelName: 'My Accounts'
      }
    }
  ],
  authFunctions: [userHasOrdersOrAccounts, externalHasMultipleAccounts],
  shouldGoToNoMatchIfNoAccess: [isExternalUser]
};

export default myAccountsRoutes;
