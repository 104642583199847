import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import slugify from 'slugify';
import '../../styles/components/_ox-card.scss';
import { toTitleCase } from '../../helpers';
import OxCardInput from '../../propTypes/OxCardInput';
import { MAIN_CONTENT } from '../../helpers/constants';
import useTabbableClass from '../../hooks/useTabbableClass';

/**
 * Note: See OxCardInput prop type for more details on each input.
 */
const OxCard = ({
  collapsible,
  header,
  id,
  labels,
  children,
  contentSplit,
  helperClass,
  contentFlush,
  footer
}) => {
  OxCard.propTypes = OxCardInput;
  // Set default collapsed state if it was provided, defaults to false if not.
  const [isCollapsed, setIsCollapsed] = useState(
    collapsible && collapsible.defaultCollapsed
  );

  const _getCollapsibleButtonId = () => {
    return `content-control-${slugify(header.title)}`;
  };

  const tabbableClass = useTabbableClass(_getCollapsibleButtonId());

  /**
   * Determine collapsible state is set internally or externally.
   */
  const isCollapseStateManagedInternally = () =>
    !collapsible ||
    collapsible.isCollapsed === null ||
    collapsible.isCollapsed === undefined;

  const getIsCollapsed = () => {
    if (isCollapseStateManagedInternally()) {
      return isCollapsed;
    }
    return collapsible.isCollapsed;
  };

  const _handleCardCollapse = newIsCollapsedState => {
    if (collapsible && collapsible.handleCardToggleEvent) {
      collapsible.handleCardToggleEvent(newIsCollapsedState);
    }
    if (isCollapseStateManagedInternally()) {
      setIsCollapsed(newIsCollapsedState);
    }
  };

  const _collapsibleButton = () => {
    const icon = getIsCollapsed() ? 'plus-circle' : 'minus-circle';

    return (
      <button
        id={_getCollapsibleButtonId()}
        htmlFor={_getCollapsibleButtonId()}
        type="button"
        aria-pressed={getIsCollapsed()}
        onClick={() => _handleCardCollapse(!getIsCollapsed())}
        aria-label={`Toggle ${id}`}
        name={`Toggle ${id}`}
        className={`ox-card__toggle-button ${tabbableClass} btn-icon`}
        data-gi={`ox-card__toggle-button-${id}`}
      >
        <FeatherIcon icon={icon} />
      </button>
    );
  };

  const _emphasizedHeaderTitle = () => {
    const { emphasizedTitle } = header;
    if (header.emphasizedTitle) {
      return (
        <span className="ox-card__title--emphasized-title">
          {emphasizedTitle}
        </span>
      );
    }
    return null;
  };

  const _cardHeader = () => {
    if (collapsible || (header && header.buttons)) {
      return (
        <header className="ox-card__header">
          <h3
            className={`
              ox-card__title
              ${header.iconName ? 'with-icon' : ''}
              ${header.isSmall ? ' small' : ''}`}
            title={`${toTitleCase(header.title)} ${header.emphasizedTitle}`}
          >
            {header.iconName ? (
              <FeatherIcon className="ox-card__icon" icon={header.iconName} />
            ) : null}
            {header.title} {_emphasizedHeaderTitle()}
          </h3>
          <div className="ox-card__header-button-group">
            {header && header.buttons
              ? header.buttons.map(button => button())
              : null}
            {collapsible ? _collapsibleButton() : null}
          </div>
        </header>
      );
    }

    return (
      <header className="ox-card__header">
        <h3 className={`ox-card__title${header.isSmall ? ' small' : ''}`}>
          {header.title} {_emphasizedHeaderTitle()}
        </h3>
      </header>
    );
  };

  const _cardLabels = () => {
    if (labels) {
      return (
        <div className="ox-card__label-container">
          {labels.map((label, ii) => (
            <div className="label" key={label.id || `${label.title}-${ii}`}>
              {label.title}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const _getCardContentClass = () => {
    return `ox-card__content
      ${contentSplit && 'split'}
      ${getIsCollapsed() && 'hidden'}
      ${contentFlush && 'flush'}`;
  };

  return (
    <section
      tabIndex="-1"
      name={MAIN_CONTENT}
      id={MAIN_CONTENT}
      className={`ox-card ${helperClass}`}
      key={id}
    >
      {_cardHeader()}
      <div className={`${footer ? 'ox-card__with-footer' : ''}`}>
        <div>
          <div className={_getCardContentClass()}>{children}</div>
          {_cardLabels()}
        </div>
        {footer ? footer() : null}
      </div>
    </section>
  );
};

export default OxCard;
