import PropTypes from 'prop-types';

/**
 * Route Object
 *
 * key - Used to uniquely identify route. (Required)
 * component - Component to display when routed to. (Required)
 * path - Url for route. (Required)
 * giDataAttr - Used for analytics and automated testing. (Required)
 * title -Title to display. (Optional)
 * icon - Icon to display. (Optional)
 * requiredPermissions - List of permissions required to access to route. As long as the user has at least one of the required permissions they have access. (Optional)
 * isPrivate - Determines if user needs to be logged in to access the route. Defaults to false if not provided. (Optional)
 * shouldGoToNoMatchIfNoAccess - Array of functions that determine if user should be routed to NoMatch or NoAccess. If any function returns true user will see NoMatch. (Optional)
 * hideInSideNav - Determines whether or not to display route in side nav
 * hideInInteriorNav - Determines whether or not to display route in interior nav
 * queryParameters - Any query parameters should be shared with routes that also have the listed query parameters.
 * isActiveForRoutes - a list of routes in which this link should appear as active
 * authFunctions - function(s) that must return true for the user to access this app
 *
 * Note: When working with subroutes, the parent route cannot have the key exact: true.
 */
const AppRoute = {
  key: PropTypes.string,
  component: PropTypes.component,
  path: PropTypes.string.isRequired,
  giDataAttr: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  isPrivate: PropTypes.bool,
  shouldGoToNoMatchIfNoAccess: PropTypes.arrayOf(PropTypes.func),
  hideInSideNav: PropTypes.bool,
  hideInInteriorNav: PropTypes.any,
  queryParameters: PropTypes.arrayOf(PropTypes.string),
  isActiveForRoutes: PropTypes.arrayOf(PropTypes.any),
  authFunctions: PropTypes.arrayOf(PropTypes.func)
};

export default AppRoute;
