import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { handleKeyUpForSearch, isEmailFormatValid } from '../../helpers';
import * as segment from '../../helpers/segmentLogger';
import OxInput from '../tokens/forms/OxInput';

const OxSearch = ({
  searchType,
  searchPlaceholder,
  history,
  name,
  label,
  inputFunction
}) => {
  OxSearch.propTypes = {
    searchType: PropTypes.oneOf(['order', 'account', 'resources']),
    searchPlaceholder: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    inputFunction: PropTypes.func
  };
  const [userInput, setUserInput] = useState('');
  const { pathname } = useLocation();
  let path = '';
  path = pathname.includes('dashboard') ? 'Dashboard' : 'Not Dashboard';

  const _handleSearchClick = () => {
    let gaAction = '';
    const gaCategory = 'Search';
    let gaLabel = path;
    const logSegmentEvent = () => {
      segment.logEvent({
        actionName: gaAction,
        categoryName: gaCategory,
        labelName: gaLabel
      });
    };
    setUserInput(userInput.replace(/ /g, ''));
    if (searchType === 'account') {
      gaAction = 'Search for an Account';
      logSegmentEvent();
      history.push(
        `/accounts/${
          isEmailFormatValid(userInput) ? 'email' : 'details'
        }/${encodeURIComponent(userInput)}`
      );
    } else if (searchType === 'order') {
      gaAction = 'Search for an Order';
      logSegmentEvent();
      history.push(`/my-orders/details/${userInput}`);
    } else if (searchType === 'resources' && !!userInput) {
      gaAction = 'Searched for a Document';
      gaLabel = 'Resource Section';
      logSegmentEvent();

      history.push(`/resources/search?query=${userInput}`);
    }
  };

  return (
    <OxInput
      icon="search"
      handleKeyUp={event =>
        handleKeyUpForSearch(
          event,
          history,
          searchType,
          inputFunction
            ? inputFunction(event)
            : setUserInput(event.target.value),
          path
        )
      }
      floatLabel={false}
      name={name}
      placeholder={searchPlaceholder}
      label={label}
      inputType="text"
      handleIconClick={event =>
        inputFunction ? inputFunction(event) : _handleSearchClick()
      }
      className="ox_input__icon--right"
      giDataAttr={`searchInput-${searchType}Details__${userInput}`}
    />
  );
};

export default OxSearch;
