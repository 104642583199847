import React from 'react';

import OxCard from '../components/molecules/OxCard';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxButton from '../components/molecules/OxButton';

const THANKS_MESSAGE =
  " Your case was submitted to Salesforce successfully. We will work on prioritizing your case, and let you know when there's an update on your case.";

const CaseCreatorThanks = () => {
  return (
    <OxCard header={{ title: 'Case Creator' }} id="case-creator-thanks">
      <OxSoftAlert
        isOutlined
        alertType="success"
        icon="check"
        title="Your Case Was Submitted Successfully"
        id="case-creator-thanks-alert"
        giDataAttr="case-creator-thanks__alert"
        eventName="info"
      >
        <p>{THANKS_MESSAGE}</p>
        <OxButton
          id="case-creator-thanks__create-case-button"
          text="Create Another Case"
          buttonType="reset"
          element="link"
          type="green"
          href="/web-to-case/case-creator"
          giDataAttr="case-creator-thanks__create-case-button"
        />
      </OxSoftAlert>
    </OxCard>
  );
};

export default CaseCreatorThanks;
