import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { withRouter, Link } from 'react-router-dom';
import { GET_ORDERS_SEARCH, GET_KEWILL_ORDERS } from '../api/orders/query';
import {
  isGraphqlArrayEmptyForNested,
  IsValidInteger,
  isInternal,
  matchExternalAccountId,
  isShipmentOverdue
} from '../helpers/index';
import OxCard from '../components/molecules/OxCard';
import OxLoaderOverlay from '../components/tokens/OxLoaderOverlay';
import OxSearch from '../components/partials/OxSearch';
import {
  SEARCHING_TITLE,
  ERROR_TITLE,
  SEARCH_ORDER_PLACEHOLDER,
  SEARCH_ORDER_PLACEHOLDER_SHORT
} from '../helpers/constants';
import { UnableToRetrieveData } from '../helpers/placeholderHelpers';
import { GET_USER_INFO } from '../api/user/query';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import '../styles/pages/_interior.scss';
import ListOfOrders from '../components/partials/ListOfOrders';
import * as segment from '../helpers/segmentLogger';
import NoMatch from './NoMatch';

const DATA_GI_ATTR = 'order-search-results';

const OrderSearchResult = ({ match, history }) => {
  const searchValue = decodeURIComponent(match.params.orderId);

  const [
    combinedShipmentInformation,
    setCombinedShipmentInformation
  ] = useState({});

  const searchValueIsUndefined = () =>
    !searchValue || searchValue === 'undefined';

  const { data: userData } = useQuery(GET_USER_INFO);

  const { data, loading, error } = useQuery(GET_ORDERS_SEARCH, {
    variables: {
      offset: 0,
      rowsPerPage: 15,
      where: {
        customerPoNumber: searchValue,
        ...(IsValidInteger(searchValue) && {
          orderNumber: searchValue
        }),
        accountId: match.params.accountId
      }
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: kewillData,
    loading: kewillLoading,
    error: kewillError
  } = useQuery(GET_KEWILL_ORDERS, {
    variables: {
      offset: 0,
      rowsPerPage: 15,
      where: {
        customerPoNumber: searchValue,
        ...(IsValidInteger(searchValue) && {
          orderNumber: searchValue
        })
      }
    },
    fetchPolicy: 'network-only'
  });

  const _getAlert = () => {
    if (searchValueIsUndefined()) {
      return (
        <OxSoftAlert
          alertType="warning"
          eventName="noData"
          id="no-order-details"
          giDataAttr="no-order-details__alert-title"
          title="Requires an Order Number or PO Number"
          isOutlined
        >
          <p>
            You must first search for an order number or PO number, or find your
            order from&nbsp;
            {isInternal() ? (
              <Link
                className="interior-layout__internal-link"
                to="/orders/all-orders"
              >
                All Orders
              </Link>
            ) : (
              <Link className="interior-layout__internal-link" to="/my-orders">
                My Orders
              </Link>
            )}
            .
          </p>
          <span className="big">
            <OxSearch
              searchType="order"
              name="customer-search"
              searchPlaceholder={SEARCH_ORDER_PLACEHOLDER}
              label={SEARCH_ORDER_PLACEHOLDER}
              history={history}
            />
          </span>
        </OxSoftAlert>
      );
    }

    if (
      !loading &&
      !kewillLoading &&
      isGraphqlArrayEmptyForNested(data, 'orders')
    ) {
      return (
        <OxSoftAlert
          title="Could not find results for"
          emphasizedTitle={` '${searchValue}'`}
          alertType="warning"
          giDataAttr={`"${DATA_GI_ATTR}__no-results-alert"`}
          isOutlined
          eventName="noData"
        >
          <p>
            Please try another search. Note that we are unable to provide order
            details for orders older than 3 years old.
          </p>
          <span className="big">
            <OxSearch
              searchType="order"
              name="customer-search"
              searchPlaceholder={SEARCH_ORDER_PLACEHOLDER}
              label={SEARCH_ORDER_PLACEHOLDER}
              history={history}
            />
          </span>
        </OxSoftAlert>
      );
    }

    return (
      <OxSoftAlert
        title={ERROR_TITLE}
        alertType="warning"
        giDataAttr="orders__network-error-alert"
        isOutlined
        eventName="network"
      >
        {UnableToRetrieveData('orders')}
      </OxSoftAlert>
    );
  };

  const hasError = () =>
    error ||
    (isGraphqlArrayEmptyForNested(data, 'orders') &&
      !loading &&
      !kewillLoading) ||
    searchValueIsUndefined();
  useEffect(
    () => {
      if (
        !loading &&
        !kewillLoading &&
        !error &&
        !kewillError &&
        !isGraphqlArrayEmptyForNested(data, 'orders') &&
        kewillData
      ) {
        const kewillOrderMap = kewillData.kewillInfo.kewillOrders.reduce(
          (kewillMap, kewillOrder) => {
            kewillMap.set(kewillOrder.orderNumber.value, kewillOrder);
            return kewillMap;
          },
          new Map()
        );
        if (data.orders && data.orders.data) {
          // For each order in Oracle...
          const conjoinedData = data.orders.data.map(order => {
            // Check if kewill info exists for the oracle order
            const matchingKewillOrder = kewillOrderMap.get(
              order.orderNumber.value
            );
            // If so, use the shipping info from kewill
            if (
              matchingKewillOrder &&
              matchingKewillOrder.shipments &&
              matchingKewillOrder.shipments.length
            ) {
              return {
                ...order,
                shipments: matchingKewillOrder.shipments
              };
            }
            return order;
          });
          // Display the updated shipping information
          setCombinedShipmentInformation({ orders: { data: conjoinedData } });
        }
      }
    },
    // eslint-disable-next-line
    [loading, kewillLoading]
  );

  // Analytics
  useEffect(() => {
    if (
      !loading &&
      !error &&
      !isGraphqlArrayEmptyForNested(data, 'orders') &&
      combinedShipmentInformation
    ) {
      if (data.orders && data.orders.data) {
        data.orders.data.forEach(order => {
          // Log an event for orders with no shipments
          if (order.shipments && order.shipments.length < 1) {
            segment.logEvent({
              giDataAttr: 'orders__no-shipments-for-order',
              eventName: segment.eventTypes.orderShipment,
              eventData: { orderNumber: order.orderNumber.value }
            });
          }

          // Log an event for overdue shipments
          if (order.shipments && order.shipments.length) {
            order.shipments.forEach(shipment => {
              if (isShipmentOverdue(shipment)) {
                segment.logEvent({
                  categoryName: 'Order Shipment',
                  actionName: 'Shipment Past Due',
                  labelName: order.orderNumber.value
                });
              }
            });
          }
        });
      }
    }
  }, [combinedShipmentInformation, data, loading, error]);

  if (
    match.params.accountId &&
    !matchExternalAccountId(userData, { accountId: match.params.accountId }) &&
    !isInternal(userData)
  ) {
    return <NoMatch />;
  }

  return (
    <main className="orders height-100">
      <OxCard
        contentFlush={loading || !hasError()}
        header={
          searchValueIsUndefined()
            ? {
                title: 'Order Details'
              }
            : {
                title: 'Search Results for',
                emphasizedTitle: ` '${searchValue}'`,
                buttons: [
                  () => (
                    <div
                      className="ox_card__search-box"
                      key="order-search-result"
                    >
                      <OxSearch
                        searchType="order"
                        name="customer-search"
                        searchPlaceholder={SEARCH_ORDER_PLACEHOLDER_SHORT}
                        label={SEARCH_ORDER_PLACEHOLDER_SHORT}
                        history={history}
                      />
                    </div>
                  )
                ]
              }
        }
        id="orders"
        key={`orders-${Math.random()}`}
      >
        <OxLoaderOverlay
          isLoading={loading || kewillLoading}
          title={SEARCHING_TITLE}
          giDataAttr="orders__loading-overlay"
        >
          {hasError() &&
          !loading &&
          !kewillLoading &&
          combinedShipmentInformation ? (
            _getAlert()
          ) : (
            <ListOfOrders
              loading={loading || kewillLoading}
              data={combinedShipmentInformation}
            />
          )}
        </OxLoaderOverlay>
      </OxCard>
    </main>
  );
};

export default withRouter(OrderSearchResult);
