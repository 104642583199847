import { useQuery, useMutation } from '@apollo/client';
import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { GET_USER, GET_USERS } from '../api/user/query';
import PermissionMgmtPills from '../components/partials/PermissionMgmtPills';
import OxCard from '../components/molecules/OxCard';
import OxLoaderOverlay from '../components/tokens/OxLoaderOverlay';
import {
  LOADING_TITLE,
  LOADING_SUBTITLE,
  USER_ORDER_BY_INPUT
} from '../helpers/constants';
import OxInput from '../components/tokens/forms/OxInput';
import useDirtyFormModal from '../hooks/useDirtyFormModal';
import { UPDATE_USER } from '../api/user/mutation';
import OxButton from '../components/molecules/OxButton';
import OxToggle from '../components/molecules/OxToggle';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import { pipe, getRandomAlphanumericString } from '../helpers';
import { OxNotificationContext } from '../components/molecules/OxNotification';

const EditUser = () => {
  const [selectedUserType, _setSelectedUserType] = useState(new Map());
  const [selectedUserRole, _setSelectedUserRole] = useState(new Map());
  const [selectedFeatures, _setSelectedFeatures] = useState(new Map());
  const [originUrls, _setOriginUrls] = useState('');
  const [isUserActive, _setIsUserActive] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const [email, _setEmail] = useState('');
  const [name, _setName] = useState('');
  const [isFormDirty, _setIsFormDirty] = useState(false);
  const [showPasswordFields, _setShowPasswordFields] = useState(false);
  const [newPassword] = useState(getRandomAlphanumericString(24));
  const { showNotification, hideNotification } = useContext(
    OxNotificationContext
  );
  const [showPassword, setShowPassword] = useState(false);
  const [userTypeName, _setUserTypeName] = useState('');

  useEffect(() => {
    _setUserTypeName(Array.from(selectedUserType.values())[0]);
  }, [selectedUserType]);

  // Return an array of id's provided a map
  const getIds = mapOfItems => [...mapOfItems].map(val => val[0]);
  // Return first item provided an array
  const getFirstItemOfIds = listOfIds =>
    listOfIds && listOfIds.length ? listOfIds[0] : null;
  // Get the first item of a map
  const getFirstItemOfMap = pipe(getIds, getFirstItemOfIds);

  const _getEditUserPayload = () => ({
    variables: {
      data: {
        active: isUserActive,
        userType: getFirstItemOfMap(selectedUserType),
        userRole: getFirstItemOfMap(selectedUserRole),
        additionalFeatures: getIds(selectedFeatures),
        ...(originUrls.length && { originUrls }),
        ...(showPasswordFields && { password: newPassword })
      },
      where: {
        id
      }
    }
  });

  const _updateUsersInCache = (cache, { data: { updateUser } }) => {
    const usersData = cache.readQuery({
      query: GET_USERS,
      variables: {
        orderBy: USER_ORDER_BY_INPUT.updatedAt_DESC
      }
    });

    if (usersData) {
      cache.writeQuery({
        query: GET_USERS,
        variables: {
          orderBy: USER_ORDER_BY_INPUT.updatedAt_DESC
        },
        data: {
          // Add most recenlty updated user to top of users list
          users: [
            updateUser,
            ...[...usersData.users.filter(user => user.id !== updateUser.id)]
          ]
        }
      });
    }
  };

  const [_updateUser, { error, loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      update: _updateUsersInCache,
      onCompleted: () => {
        history.push(
          userTypeName === 'api'
            ? '/mgmt/api-users?edit-success=true'
            : '/mgmt/users?edit-success=true'
        );
      }
    }
  );

  const _handleToggleUser = () => _setIsUserActive(!isUserActive);

  const _handleFormButtonSubmit = e => {
    e.preventDefault();
    _updateUser(_getEditUserPayload());
  };

  useDirtyFormModal({ isFormDirty, handleSubmit: _handleFormButtonSubmit });

  const _handleFormInputEvent = handleEventFunc => {
    _setIsFormDirty(true);
    return handleEventFunc;
  };

  const { loading: getUserLoading } = useQuery(GET_USER, {
    variables: {
      where: {
        id
      }
    },
    onCompleted: data => {
      if (data && data.user) {
        const { user } = data;
        _setName(user.name);
        _setEmail(user.email);
        _setIsUserActive(user.active);
        _setSelectedUserType(
          new Map([user.userType].map(item => [item.id, item.name]))
        );
        _setSelectedFeatures(
          new Map(user.additionalFeatures.map(item => [item.id, item.name]))
        );
        if (user && user.userRole) {
          _setSelectedUserRole(
            new Map([user.userRole].map(item => [item.id, item.name]))
          );
        }
        _setOriginUrls(user.originUrls);
      }
    }
  });

  const _handleResetPasswordButton = () => {
    _setShowPasswordFields(!showPasswordFields);
  };

  return (
    <OxCard id="edit-user" header={{ title: 'Edit User' }}>
      <OxLoaderOverlay
        isLoading={getUserLoading || updateUserLoading}
        title={LOADING_TITLE}
        subtitle={LOADING_SUBTITLE}
        giDataAttr="edit-user__loading-overlay"
      >
        {error ? (
          <OxSoftAlert
            alertType="warning"
            id="edit-user__error-alert"
            giDataAttr="edit-user__error-alert"
            title="An Error Occurred Attempting to Edit User"
            isOutlined
          />
        ) : null}
        <form>
          <fieldset className="ox-fieldset">
            <div className="units-row">
              <div className="unit-33 margin-bottom">
                <OxInput
                  readOnly
                  required
                  noAnalytics
                  value={name}
                  floatLabel={false}
                  giDataAttr="Text"
                  name="text"
                  inputType="text"
                  label="Name"
                />
              </div>
              <div className="unit-33 margin-bottom">
                <OxInput
                  readOnly
                  required
                  noAnalytics
                  value={email}
                  floatLabel={false}
                  giDataAttr="Text"
                  name="text"
                  inputType="text"
                  label="Email"
                />
              </div>
              <div className="unit-33 margin-bottom">
                <OxToggle
                  clickFunc={_handleToggleUser}
                  title={isUserActive ? 'Active' : 'Inactive'}
                  checked={isUserActive}
                ></OxToggle>
              </div>
            </div>
            {userTypeName === 'api' && !showPasswordFields && (
              <div className="units-row">
                <div className="unit-50 margin-bottom">
                  <OxButton
                    element="span"
                    text="Reset Password"
                    clickFunc={_handleResetPasswordButton}
                    giDataAttr="admin__reset-password-btn"
                  />
                </div>
              </div>
            )}
            {userTypeName === 'api' && showPasswordFields && (
              <div className="units-row">
                <div className="unit-50">
                  <OxInput
                    readOnly
                    noAnalytics
                    value={newPassword}
                    floatLabel={false}
                    giDataAttr="Text"
                    name="text"
                    inputType={showPassword ? 'text' : 'password'}
                    label="Password"
                    tooltipContent={`
                      After saving this password, you will not be able to view it again.
                    `}
                  />
                </div>
                <div className="unit-50">
                  <div className="units-row">
                    <div className="unit-50 margin-top">
                      <OxButton
                        element="span"
                        text="Copy to Clipboard &nbsp;"
                        helperClass="margin-top"
                        clickFunc={() => {
                          navigator.clipboard
                            .writeText(newPassword)
                            .then(() => {
                              showNotification(
                                'Success',
                                'Successfully copied to clipboard',
                                'clipboard'
                              );
                              setTimeout(() => {
                                hideNotification();
                              }, 2000);
                            });
                        }}
                        withIcon={{ icon: 'copy' }}
                        giDataAttr="copy"
                      />
                    </div>
                    <div className="unit-50">
                      <OxToggle
                        clickFunc={() => {
                          setShowPassword(!showPassword);
                        }}
                        title="Show Password"
                        checked={showPassword}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userTypeName === 'api' && (
              <div>
                <div className="units-row">
                  <div className="unit-50">
                    <OxInput
                      noAnalytics
                      value={originUrls}
                      inputType="text"
                      name="originUrls"
                      label="Origin URLs (Seperated by Comma)"
                      handleKeyUp={e =>
                        _setOriginUrls(e.target.value.split(','))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </fieldset>
          <PermissionMgmtPills
            hasOnlyOneUserType
            hasOnlyOneUserRole
            selectedFeatures={selectedFeatures}
            selectedUserTypes={selectedUserType}
            selectedUserRoles={selectedUserRole}
            handleUserRoleChange={val =>
              _handleFormInputEvent(_setSelectedUserRole)(val)
            }
            handleUserTypeChange={val => {
              if (val && val.size) {
                _handleFormInputEvent(_setSelectedUserType)(val);
              }
            }}
            handleFeatureChange={val =>
              _handleFormInputEvent(_setSelectedFeatures)(val)
            }
          />
          <fieldset className="ox-fieldset">
            <div className="display--flex nowrap">
              <OxButton
                id="save-button"
                giDataAttr="ox-fieldset__edit-user-save-button"
                text="Save"
                buttonType="submit"
                element="button"
                type="blue"
                clickFunc={_handleFormButtonSubmit}
                helperClass="ox-fieldset__save-button"
              />
            </div>
          </fieldset>
        </form>
      </OxLoaderOverlay>
    </OxCard>
  );
};

export default EditUser;
