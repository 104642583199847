import { useEffect, useCallback } from 'react';

/**
 * Handle the event the user has opened a modal by 'tab trapping' them.
 *
 * What is tab trapping:
 *
 * If you are on the last side nav element and select 'tab', the first side nav elment will be selected.
 * If you are on the first side nav element and shift + tab you will be focused on the side nav element.
 */
const useTabTrap = (
  firstElementId,
  lastElementId,
  areaToListenToId,
  shouldListen
) => {
  const handleTabEvent = useCallback(
    e => {
      if (e.keyCode === 9) {
        // If the user has tabbed
        if (
          e.shiftKey &&
          document.activeElement === document.getElementById(firstElementId)
        ) {
          // Backwards
          e.preventDefault();
          document.getElementById(lastElementId).focus();
        } else if (
          document.activeElement === document.getElementById(lastElementId) &&
          !e.shiftKey
        ) {
          // Fowards
          e.preventDefault();
          document.getElementById(firstElementId).focus();
        }
      }
    },
    [firstElementId, lastElementId]
  );

  useEffect(() => {
    if (shouldListen) {
      if (document.getElementById(firstElementId)) {
        document
          .getElementById(areaToListenToId)
          .addEventListener('keydown', handleTabEvent);

        // Focus on first element
        document.getElementById(firstElementId).focus();
      }
    }
    return () => {
      if (
        shouldListen &&
        document.getElementById(areaToListenToId) &&
        document.getElementById(areaToListenToId).removeEventListener
      ) {
        document
          .getElementById(areaToListenToId)
          .removeEventListener('keydown', handleTabEvent);
      }
    };
    // eslint-disable-next-line
  }, [shouldListen, areaToListenToId, firstElementId]);
};

export default useTabTrap;
