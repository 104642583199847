import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import '../../styles/components/_ox-global-header.scss';

import IntraloxBadge from '../tokens/IntraloxBadge';
import UserInfo from './UserInfo';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import useTabTrap from '../../hooks/useTabTrap';
import useTabbableClass from '../../hooks/useTabbableClass';
import { OxNotificationContext } from '../molecules/OxNotification';
import { MAIN_CONTENT } from '../../helpers/constants';

const OPEN_RESPONSIVE_NAV_CLASS = 'show-nav';

const CALL_US_ID = 'navbar__call-us';
const MAIN_MENU_ID = 'navbar--main';
const MAIN_MENU_TOGGLE_ID = 'navbar__toggle';
const MAIN_MENU_LAST_ITEM_ID = 'navbar__last-list-item';

const GlobalHeader = ({ title, callOutLinks, links }) => {
  GlobalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string
      })
    ),
    callOutLinks: PropTypes.bool
  };
  const { showNotification, hideNotification } = useContext(
    OxNotificationContext
  );

  const SKIP_HEADER_ID = 'skip-header';
  const [navClass, _setNavClass] = useState('');
  const navRef = useRef(null);
  const hasClickedOutsideOfNav = useDetectOutsideClick(navRef);
  const mainMenuTabbableClass = useTabbableClass(MAIN_MENU_TOGGLE_ID);
  const callUsTabbableClass = useTabbableClass(CALL_US_ID);
  const firstTabbableElement = document.getElementById(SKIP_HEADER_ID);

  useTabTrap(
    MAIN_MENU_TOGGLE_ID,
    MAIN_MENU_LAST_ITEM_ID,
    MAIN_MENU_ID,
    navClass === OPEN_RESPONSIVE_NAV_CLASS
  );

  /**
   * Lifecycle hook responsible for closing the responsive sidenav when the user clicks out of focus as well
   * as listening to events for the first link (skip header content).
   */
  useEffect(() => {
    // If the user has clicked outside of the menu or the menu toggle close, set the nav class.
    if (hasClickedOutsideOfNav && navClass === OPEN_RESPONSIVE_NAV_CLASS) {
      _setNavClass('');
    }

    const skipHeaderElement = document.getElementById(SKIP_HEADER_ID);
    /**
     * Handle the event the user has focused on the first element in the header.
     */
    const handleFocusOnFirstElement = e => {
      // e.which is undefined if user is tabbing from bookmarks in browser into web app on internet explorer
      if (
        (e.which === 9 || e.which === 0 || !e.which) &&
        e.target.id === SKIP_HEADER_ID
      ) {
        showNotification(
          'Skip Header Content',
          'Jump straight to the main content',
          'navigation',
          'global-header__skip-header-notification'
        );
      }
    };

    /**
     * Handle the event the user has selected 'Enter' on the first element in the header.
     * This is only needed for Firefox. Chrome and IE do not need this method.
     *
     * @param {*} e
     */
    const handleKeyDownOnFirstElement = e => {
      if (e.which === 13 && e.target.id === SKIP_HEADER_ID) {
        document.getElementById(MAIN_CONTENT).focus();
        document.getElementById(MAIN_CONTENT).classList.add('link-to-anchor');
      }
    };

    document.addEventListener('focusin', handleFocusOnFirstElement); // Used for if the user is tabbing from their bookmarks in the browser
    document.addEventListener('keyup', handleFocusOnFirstElement);
    skipHeaderElement.addEventListener('blur', hideNotification);
    skipHeaderElement.addEventListener('keydown', handleKeyDownOnFirstElement);

    // Unsubscribe to all listeners
    return () => {
      document.removeEventListener('focusin', handleFocusOnFirstElement);
      document.removeEventListener('keyup', handleFocusOnFirstElement);
      skipHeaderElement.removeEventListener('blur', hideNotification);
      skipHeaderElement.removeEventListener(
        'keydown',
        handleKeyDownOnFirstElement
      );
    };
  }, [
    hasClickedOutsideOfNav,
    navClass,
    firstTabbableElement,
    hideNotification,
    showNotification
  ]);

  const _getCallOutLinks = () => {
    return (
      <li className="display--flex">
        <ul>
          <li className="navbar__callout height-100 lighter">
            <a
              href="https://www.intralox.com/support/phone-numbers/united-states"
              id={CALL_US_ID}
              className={`${callUsTabbableClass} tabbable--negative-ol-offset tabbable--white-ol`}
            >
              <FeatherIcon icon="phone" className="navbar-icon" />
              Call Us
            </a>
          </li>
        </ul>
      </li>
    );
  };

  /**
   * If the user has selected the menu button open or close the nav.
   */
  const _toggleNav = () => {
    if (navClass === OPEN_RESPONSIVE_NAV_CLASS) {
      _setNavClass('');
    }
    if (navClass === '') {
      _setNavClass(OPEN_RESPONSIVE_NAV_CLASS);
    }
  };

  const _mainNav = type => {
    if (type === 'productSpecific') {
      return (
        <div className="display--flex">
          <div className="display--flex align-center">
            <h1 className="ox-global-header__headline">{title}</h1>
          </div>
        </div>
      );
    }

    return (
      <nav
        className={`navbar navbar--main ${navClass}`}
        ref={navRef}
        id={MAIN_MENU_ID}
      >
        <button
          className={`navbar__toggle ${navClass} ${mainMenuTabbableClass} tabbable--negative-ol-offset tabbable--white-ol btn-icon`}
          id={MAIN_MENU_TOGGLE_ID}
          aria-label="Navigation toggle for global header"
          type="button"
          aria-pressed="false"
          onClick={_toggleNav}
        >
          <FeatherIcon icon="menu" />
        </button>
        <ul className="navbar__list">
          {links.map(link => (
            <li className="navbar__list-item" key={link.title}>
              <a
                {...(links[links.length - 1] === link && {
                  id: MAIN_MENU_LAST_ITEM_ID
                })}
                className="navbar__link"
                {...link}
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <header>
      <div role="menu">
        {/* Note: This is not within ox-global-header because of how it looks on ie11 */}
        <a
          className="skip-header"
          role="menuitem"
          aria-label="select enter to skip header content"
          id={SKIP_HEADER_ID}
          href="#content-anchor"
        ></a>
        <div className="ox-global-header">
          {_mainNav('global')}
          <nav className="navbar">
            <ul className="navbar__list">
              {callOutLinks ? _getCallOutLinks() : null}
              <li>
                <UserInfo />
              </li>
              <li className="intralox-logo">
                <IntraloxBadge title={title} />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default GlobalHeader;
