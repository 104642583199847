import React from 'react';
import propTypes from 'prop-types';
import OxLoader from './OxLoader';
import '../../styles/components/_ox-loader-overlay.scss';

const OxLoaderOverlay = ({
  isLoading,
  title,
  subtitle,
  backgroundColor,
  giDataAttr,
  children
}) => {
  OxLoaderOverlay.propTypes = {
    isLoading: propTypes.bool,
    title: propTypes.string,
    subtitle: propTypes.string,
    backgroundColor: propTypes.string, // Provide a background color to remove opacity
    giDataAttr: propTypes.string
  };

  return (
    <div className="loading-overlay-container flex--column-grow">
      {isLoading ? (
        <div
          className="loading-overlay-container__loader"
          data-gi={giDataAttr}
          style={{
            backgroundColor: isLoading ? `${backgroundColor}` : 'inherit'
          }}
        >
          {isLoading ? <OxLoader title={title} subtitle={subtitle} /> : null}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default OxLoaderOverlay;
