import React, { useState, useContext, useEffect, useRef } from 'react';
import FeatherIcon from 'feather-icons-react';
import '../../styles/components/_ox-modal.scss';
import OxCard from './OxCard';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import { OX_MODAL_ID, OX_MODAL_FIRST_ELEMENT } from '../../helpers/constants';
import useTabTrap from '../../hooks/useTabTrap';
import { isEmbedded } from '../../helpers';

export const OxModalContext = React.createContext({
  /* eslint-disable  no-unused-vars */
  showModal: ({
    childElement,
    header,
    icon,
    lastElementId,
    giDataAttr,
    firstElementId
  }) => {},
  hideModal: () => {}
});
/* eslint-enable */
export const OxModalProvider = OxModalContext.Provider;
export const OxModalConsumer = OxModalContext.Consumer;

const OxModal = ({ children }) => {
  const [isVisibile, _setIsVisible] = useState(false);
  const [modalHeader, _setHeader] = useState('');
  const [modalIcon, _setIcon] = useState('');
  const modalRef = useRef(null);
  const hasClickedOutsideOfModal = useDetectOutsideClick(modalRef);
  const [Child, _setChild] = useState(null);
  const [lastElement, _setLastElement] = useState('');
  const [firstElement, _setFirstElement] = useState('');

  const _showModal = ({
    childElement,
    header,
    icon,
    lastElementId,
    firstElementId
  }) => {
    // Delay opening of modal.
    // This is done because if the modal is opened with the keyboard, that event
    // is also applied to the first modal button.
    // That happens beacuse the first modal button is focused on using tab trap for accessibility.
    setTimeout(() => {
      _setHeader(header);
      _setIcon(icon);
      _setIsVisible(true);
      _setChild(childElement);
      _setLastElement(lastElementId);
      _setFirstElement(firstElementId);
    }, 100);
  };
  useTabTrap(firstElement, lastElement, OX_MODAL_ID, isVisibile);

  useEffect(() => {
    const listenerFunc = e => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        _setIsVisible(false);
      }
    };

    document.addEventListener('keyup', listenerFunc);
    return () => {
      document.removeEventListener('keyup', listenerFunc);
    };
  });

  const _hideModal = () => _setIsVisible(false);

  useEffect(() => {
    if (hasClickedOutsideOfModal) {
      _hideModal();
    }
  }, [hasClickedOutsideOfModal, modalRef]);

  useEffect(() => {
    if (!isVisibile) {
      // Allows time for the modal to fade out before hiding the display
      setTimeout(() => {
        document.getElementById(OX_MODAL_ID).classList.add('display--none');
      }, 250);
    }
  }, [isVisibile]);

  return (
    <OxModalProvider
      value={{
        showModal: _showModal,
        hideModal: _hideModal
      }}
    >
      <section
        id={OX_MODAL_ID}
        role="dialog"
        arial-labelledby={`${modalHeader} modal`}
        className={`ox-modal ${isVisibile ? 'show' : 'hidden'} ${
          isEmbedded() ? 'iframe-modal' : ''
        }`}
      >
        <div ref={modalRef} className="ox-modal__content">
          <OxCard
            helperClass="ox-modal__card"
            header={{
              title: modalHeader,
              iconName: modalIcon,
              buttons: [
                () => (
                  <button
                    id={OX_MODAL_FIRST_ELEMENT}
                    key={Math.random()}
                    className="ox-modal__btn btn-icon"
                    type="button"
                    onClick={_hideModal}
                  >
                    <FeatherIcon icon="x" />
                  </button>
                )
              ]
            }}
          >
            {Child}
          </OxCard>
        </div>
      </section>
      {children}
    </OxModalProvider>
  );
};

export { OxModal };
export const useModal = () => useContext(OxModalContext);
