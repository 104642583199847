import React from 'react';
import '../../styles/components/_ox-toggle.scss';
import useTabbableClass from '../../hooks/useTabbableClass';

const OxToggle = ({ title, clickFunc, checked }) => {
  const TOGGLE_ID = 'ox-toggle';
  const tabbable = useTabbableClass(TOGGLE_ID);
  return (
    <>
      <label className="ox-toggle-label">{title}</label>
      <span
        id={TOGGLE_ID}
        className={`ox-toggle-switch ${tabbable}`}
        tabIndex="0"
        role="button"
        onClick={clickFunc}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            clickFunc();
          }
        }}
      >
        <input
          tabIndex="-1"
          type="checkbox"
          checked={checked}
          onChange={() => {}}
        />
        <span className="ox-toggle-slider round"></span>
      </span>
    </>
  );
};

export default OxToggle;
