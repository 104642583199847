import FeatherIcon from 'feather-icons-react';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import OxButton from '../components/molecules/OxButton';
import OxCard from '../components/molecules/OxCard';
import OxTable from '../components/molecules/OxTable';
import { getTableBodyPlaceholder } from '../helpers/placeholderHelpers';
import { toTitleCase, getAnnouncementMutationSuccessAlert } from '../helpers';
import { GET_ANNOUNCEMENTS } from '../api/announcements/query';
import OxLoaderOverlay from '../components/tokens/OxLoaderOverlay';
import { LOADING_TITLE, LOADING_SUBTITLE } from '../helpers/constants';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import '../styles/components/_admin.scss';
import { DELETE_ANNOUNCEMENT } from '../api/announcements/mutation';
import AdminDeleteButton from '../components/partials/AdminDeleteButton';

const annoucementsHeaderPlaceholder = [
  {
    displayValue: 'Text',
    key: 'text'
  },
  {
    displayValue: 'Date Created',
    key: 'dateCreated'
  },
  {
    displayValue: 'Expiration Date',
    key: 'expDate'
  },
  {
    displayValue: 'Type',
    key: 'type'
  },
  {
    displayValue: 'Active',
    key: 'active'
  },
  {
    displayValue: 'Edit',
    key: 'edit'
  },
  {
    displayValue: 'Delete',
    key: 'delete'
  }
];

const annoucementsBodyPlaceholder = {
  text: {
    value: '---------'
  },
  dateCreated: {
    value: '----'
  },
  expDate: {
    value: '----'
  },
  type: {
    value: '----'
  },
  active: {
    value: '----'
  },
  edit: {
    value: <FeatherIcon icon="edit" />
  },
  delete: {
    value: <FeatherIcon icon="trash-2" />
  }
};

const Announcements = ({ route }) => {
  const { loading, data } = useQuery(GET_ANNOUNCEMENTS);
  const queryParams = new URLSearchParams(useLocation().search);
  const [hasDeletionSuccess, setHasDeletionSuccess] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const _updateAnnouncementsInCache = (
    cache,
    { data: { deleteAnnouncement } }
  ) => {
    const announcementData = cache.readQuery({
      query: GET_ANNOUNCEMENTS
    });

    if (announcementData) {
      cache.writeQuery({
        query: GET_ANNOUNCEMENTS,
        data: {
          announcements: [
            ...announcementData.announcements.filter(
              announcement => announcement.id !== deleteAnnouncement.id
            )
          ]
        }
      });
    }
  };

  const [_deleteAnnouncement, { error }] = useMutation(DELETE_ANNOUNCEMENT, {
    onCompleted: () => {
      history.push(pathname); // Clear query parameters
      setHasDeletionSuccess(true);
    },
    update: _updateAnnouncementsInCache
  });

  const getAlert = () => {
    if (hasDeletionSuccess) {
      return (
        <OxSoftAlert
          title="Your Announcement Was Successfully Deleted"
          icon="check"
          alertType="success"
          id="delete-announcement__success"
          giDataAttr="delete-announcement__success"
          isOutlined
        />
      );
    }
    if (error) {
      return (
        <OxSoftAlert
          alertType="warning"
          id="delete-announcement__error"
          giDataAttr="delete-announcement__error"
          title="An Error Occurred Attempting to Delete an Announcement"
          isOutlined
        />
      );
    }
    return getAnnouncementMutationSuccessAlert(queryParams);
  };

  const _handleDeleteAnnouncement = id => () => {
    _deleteAnnouncement({
      variables: {
        where: {
          id
        }
      }
    });
  };

  const transformAnnouncmentsForTable = announcements => {
    return announcements.map(a => ({
      _id: a.id,
      text: { value: a.body },
      dateCreated: { value: new Date(a.createdAt).toDateString() },
      expDate: { value: new Date(a.expireAt).toDateString() },
      type: {
        value: toTitleCase(a.type.replace('_', ' '))
      },
      active: { value: new Date() < new Date(a.expireAt) ? 'Yes' : 'No' },
      edit: {
        url: {
          value: `/mgmt/announcements/${a.id}/edit`
        },
        iconName: {
          value: 'edit'
        }
      },
      delete: {
        func: announcement => (
          <AdminDeleteButton
            entityName="Announcement"
            id={announcement._id}
            handleDeleteEntity={_handleDeleteAnnouncement(announcement._id)}
          />
        )
      }
    }));
  };

  return (
    <OxCard
      header={{
        title: route.title,
        buttons: [
          () => (
            <div key="add-new-announcement">
              <OxButton
                text="Add New Announcement"
                href="/mgmt/announcements/new"
                element="link"
                id="add-new-announcement"
                size="default"
                type="blue"
                helperClass="width-100"
                giDataAttr="add-new-announcement__button"
              />
            </div>
          )
        ]
      }}
      id="annoucements"
    >
      {getAlert()}
      {loading || (data && data.announcements && data.announcements.length) ? (
        <OxLoaderOverlay
          isLoading={loading}
          title={LOADING_TITLE}
          subtitle={LOADING_SUBTITLE}
          giDataAttr="announcement__loading-overlay"
        >
          <OxTable
            noAnalytics
            giDataAttr="announcement__table"
            data={{
              headers: annoucementsHeaderPlaceholder,
              data: loading
                ? getTableBodyPlaceholder(10, annoucementsBodyPlaceholder)
                : transformAnnouncmentsForTable(data.announcements)
            }}
          />
        </OxLoaderOverlay>
      ) : (
        <OxSoftAlert
          alertType="info"
          icon="none"
          giDataAttr="announcement__no-annoucement-alert"
          eventName="noData"
        >
          <p>There are no announcements at the moment.</p>
        </OxSoftAlert>
      )}
    </OxCard>
  );
};

export default Announcements;
