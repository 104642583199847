import { gql } from '@apollo/client';

const GET_USER_INFO = gql`
  query getUserInfo {
    userInfo {
      id
      email
      userType {
        id
        name
      }
      allFeatures {
        id
        name
      }
      userRole {
        id
        name
      }
      additionalFeatures {
        id
        name
      }
      dsAppStatus
      name
      jobTitle
    }
    accountId {
      accountIds
    }
  }
`;

const IS_LOGGED_IN = gql`
  query getLoggedInState {
    getLoggedInState
  }
`;

const GET_SESSION = gql`
  query getUserSession {
    getUserSession {
      expireAt
      createdAt
    }
  }
`;

const GET_USERS = gql`
  query users($orderBy: UserOrderByInput) {
    users(orderBy: $orderBy) {
      id
      name
      email
      active
      userType {
        id
        name
      }
    }
  }
`;

const GET_USER = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      email
      name
      active
      userType {
        id
        name
      }
      allFeatures {
        id
        name
      }
      userRole {
        id
        name
      }
      additionalFeatures {
        id
        name
      }
      originUrls
    }
  }
`;

export { GET_USER_INFO, IS_LOGGED_IN, GET_SESSION, GET_USERS, GET_USER };
