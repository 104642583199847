import { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useConfirmationModal from './useConfirmationModal';

const isRoutingAfterMuationSuccess = search =>
  search === '?edit-success=true' || search === '?create-success=true';

/**
 * Responsible for letting the user know they are attempting to leave when
 * they've made changes to their form (dirty form).
 *
 * Allows the user to save work or exit without saving.
 *
 * @param {*} param0
 */
const useDirtyFormModal = ({ isFormDirty, handleSubmit }) => {
  const [callbackPath, _setCallbackPath] = useState('');
  const unblockHandle = useRef();
  const history = useHistory();
  const { showConfirmation, hideConfirmation } = useConfirmationModal();

  /**
   * If a callback path has been set, route to callback path.
   */
  useEffect(() => {
    if (callbackPath) {
      history.push(callbackPath);
    }
  }, [callbackPath, history]);

  /**
   * Handle modal button events.
   */
  const _handleModalButtonPress = useCallback(
    submitFunc => {
      return () => {
        hideConfirmation();
        if (submitFunc) {
          submitFunc();
        }
      };
    },
    [hideConfirmation]
  );

  /**
   * Check routes that are outside this form, if the form is dirty and a callback
   * path does not exist.
   *
   * Otherwise, do not block the route.
   */
  useEffect(() => {
    const _openModal = newCallbackPath => {
      showConfirmation({
        message:
          'If you leave now, your changes will be lost. Would  you like to save before you leave?',
        header: 'Unsaved Changes',
        icon: 'alert-triangle',
        giDataAttr: 'ox-model__edit-annoucement',
        buttonProps: [
          {
            key: 'ox-modal__btn-primary',
            id: 'ox-modal__btn-primary',
            text: 'Yes, Save My Changes',
            giDataAttr: 'ox-modal__btn-primary',
            type: 'primary',
            element: 'button',
            clickFunc: _handleModalButtonPress(() => {
              handleSubmit();
            })
          },
          {
            key: 'ox-modal__btn-secondary',
            id: 'ox-modal__btn-secondary',
            giDataAttr: 'ox-modal__btn-secondary',
            text: 'Exit Without Saving',
            type: 'secondary',
            element: 'button',
            clickFunc: _handleModalButtonPress(() =>
              _setCallbackPath(newCallbackPath)
            )
          }
        ]
      });
    };

    // Block any attempt to route elsewhere
    unblockHandle.current = history.block(e => {
      // If form is dirty, there's no callback to route to and we're not routing to a success state
      if (
        isFormDirty &&
        !callbackPath &&
        e.pathname !== '/login' &&
        !isRoutingAfterMuationSuccess(e.search)
      ) {
        _openModal(e.pathname);
        // Do not route
        return false;
      }
      // Route
      return true;
    });
    return () => unblockHandle.current && unblockHandle.current();
  }, [
    isFormDirty,
    callbackPath,
    history,
    showConfirmation,
    _handleModalButtonPress,
    handleSubmit
  ]);
};

export default useDirtyFormModal;
