import React from 'react';
import FeatherIcon from 'feather-icons-react';
import * as segment from '../../helpers/segmentLogger';
import OxSoftAlert from '../molecules/OxSoftAlert';
import { OxTooltip } from '../molecules/OxTooltip';
import OxTable from '../molecules/OxTable';
import {
  getTableBodyPlaceholder,
  ordersByCustomerHeaderPlaceholder,
  ordersByCustomerBodyPlaceholder
} from '../../helpers/placeholderHelpers';
import { isShipmentOverdue } from '../../helpers';

const OrderShipments = ({ loading, shipmentInformation }) => {
  /**
   * Helper method to determine if order has shipments
   * @param {*} order
   * @returns {Boolean}
   */
  const _orderHasShipments = order =>
    order && order.shipments && order.shipments.length > 0;

  /**
   * Returns the main content of this component
   * @param {*} order
   * @returns {React.ReactElement} Either an alert, or list of shipments
   */
  const _getOrderContent = order => {
    if (!_orderHasShipments(order)) {
      return (
        <OxSoftAlert
          title="We Are Putting Your Order Together"
          helperClass="width-100"
          alertType="warning"
          icon="none"
          isOutlined
          giDataAttr="orders__no-shipments-for-order"
          eventName="ignore"
        >
          <p className="big">
            Your order is currently being assembled. We expect your order to be
            shipped on&nbsp;
            <b>{order.expectedShipmentDate}</b>. Please note, lead times may
            vary.
          </p>
        </OxSoftAlert>
      );
    }

    const mappedShipments =
      order &&
      order.shipments &&
      order.shipments.map(shipment => {
        const {
          trackingNumber,
          carrier,
          shipmentDate,
          ...newShipment
        } = shipment;

        const verifiedShipmentSource = shipment.isCurrentTracking
          ? 'Verified'
          : 'Non-Verified';

        const trackingNumberGiDataAttr = 'ox-table__link--trackingNumber';

        const _logTrackingNumberClick = () => {
          segment.logEvent({
            giDataAttr: trackingNumberGiDataAttr,
            eventName: segment.eventTypes.clicked,
            eventData: {
              giDataAttr: trackingNumberGiDataAttr
            },
            actionName: 'Clicked Hyperlinked Tracking Number',
            categoryName: 'Shipment & Tracking Number',
            labelName: `${carrier.value}_${verifiedShipmentSource}`
          });
        };

        if (
          shipment &&
          shipment.carrier &&
          shipment.carrier.value &&
          shipment.carrier.value === 'DHL' &&
          trackingNumber.value.length < 10
        ) {
          return {
            trackingNumber: {
              func: () => (
                <div className="display--flex ox-table__dhl-tracking">
                  <span className="ox-table__link-text padding-right">
                    {shipment.trackingNumber.value}
                  </span>
                  <a
                    className="margin-left"
                    href={shipment.trackingNumber.url.value}
                    onClick={_logTrackingNumberClick}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="DHL Global Forwarding"
                  >
                    <FeatherIcon icon="globe" className="tooltip-icon" />
                  </a>
                  <a
                    className="margin-left"
                    href="https://dhli.dhl.com/dhli-client/publicTracking?0"
                    onClick={_logTrackingNumberClick}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="DHLi"
                  >
                    <FeatherIcon icon="package" className="tooltip-icon" />
                  </a>
                  <a
                    className="margin-left"
                    href={`https://mydhl.express.dhl/us/en/tracking.html#/results?id=${shipment.trackingNumber.url.value}`}
                    onClick={_logTrackingNumberClick}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="DHL Express"
                  >
                    <FeatherIcon icon="truck" className="tooltip-icon" />
                  </a>
                </div>
              )
            },
            ...newShipment
          };
        }

        if (shipment.isCurrentTracking) {
          newShipment.shipmentDate = shipment.shipmentDate;
          newShipment.carrier = shipment.carrier;
          return {
            trackingNumber: {
              func: () => (
                <a
                  className="ox-table__link ox-table__link--reverse ox-table__link--external-link "
                  href={shipment.trackingNumber.url.value}
                  onClick={_logTrackingNumberClick}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="ox-table__link-text padding-right">
                    {shipment.trackingNumber.value}
                  </span>
                  <OxTooltip content="Data Gathered From Carrier">
                    <FeatherIcon icon="truck" className="tooltip-icon" />
                  </OxTooltip>
                </a>
              )
            },
            ...newShipment
          };
        }

        if (shipment.trackingNumber && shipment.trackingNumber.url) {
          shipment.trackingNumber.url.onClick = _logTrackingNumberClick; //eslint-disable-line
          shipment.trackingNumber.url.giDataAttr = trackingNumberGiDataAttr; //eslint-disable-line
        }

        if (isShipmentOverdue(shipment)) {
          // eslint-disable-next-line no-param-reassign
          shipment.expectedDeliveryDate.func = () => (
            <div className="units-row flex-no-wrap">
              <div className="unit-70">
                <OxTooltip
                  helperClass="text-left"
                  tooltipType="warning"
                  content="There is a delay in the shipment, please see the carrier website for details."
                >
                  <FeatherIcon icon="alert-circle" className="tooltip-icon" />
                </OxTooltip>
              </div>
              <div className="unit-auto no-wrap">
                {shipment.expectedDeliveryDate.value}
              </div>
            </div>
          );
        }

        return shipment;
      });

    return (
      <OxTable
        helperClass="width-100"
        data={{
          headers: ordersByCustomerHeaderPlaceholder,
          data: loading
            ? getTableBodyPlaceholder(1, ordersByCustomerBodyPlaceholder)
            : mappedShipments
        }}
      />
    );
  };

  /**
   *
   * @param {*} multiOrders
   * @returns {React.ReactElement}
   */
  const _renderShipments = multiOrders => {
    if (!multiOrders.length) {
      return <></>;
    }
    const order = multiOrders[0];
    return (
      <div>
        <div className="units-row orders__table-container">
          <div className="unit-100 padding-bottom margin-bottom--extra border-bottom">
            {_getOrderContent(order)}
          </div>
        </div>
      </div>
    );
  };

  return <div>{_renderShipments(shipmentInformation)}</div>;
};

export default OrderShipments;
