import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import AppRoute from '../propTypes/AppRoute';
import { isInternal } from '../helpers';
import AllOrdersByCustomer from './AllOrdersByCustomer';
import PrimaryOrderView from './PrimaryOrderView';
import { GET_USER_INFO } from '../api/user/query';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxSearch from '../components/partials/OxSearch';
import OxCard from '../components/molecules/OxCard';
import { SEARCH_ORDER_PLACEHOLDER } from '../helpers/constants';

const TotalOrders = ({ route, history }) => {
  TotalOrders.propTypes = {
    props: PropTypes.shape({
      route: PropTypes.shape(AppRoute)
    })
  };
  const { data: userData } = useQuery(GET_USER_INFO);
  const params = useParams();
  const getAccountId = () => params.accountId;

  if (getAccountId()) {
    return <AllOrdersByCustomer route={route} />;
  }

  if (isInternal(userData)) {
    return (
      <OxCard
        header={{ title: 'Total Orders' }}
        id="orders"
        key={`orders-${Math.random()}`}
      >
        <OxSoftAlert
          alertType="warning"
          eventName="noData"
          id="no-order-details"
          giDataAttr="no-order-details__alert-title"
          title="Requires an Order Number or PO Number"
          isOutlined
        >
          <p>
            You must first search for an order number or PO number, or find your
            order from&nbsp;
            {isInternal() ? (
              <Link
                className="interior-layout__internal-link"
                to="/orders/all-orders"
              >
                All Orders
              </Link>
            ) : (
              <Link className="interior-layout__internal-link" to="/my-orders">
                My Orders
              </Link>
            )}
            .
          </p>
          <span className="big">
            <OxSearch
              searchType="order"
              name="customer-search"
              searchPlaceholder={SEARCH_ORDER_PLACEHOLDER}
              label={SEARCH_ORDER_PLACEHOLDER}
              history={history}
            />
          </span>
        </OxSoftAlert>
      </OxCard>
    );
  }
  if (!isInternal(userData) && userData.accountId.accountIds.length > 1) {
    return <PrimaryOrderView route={route} history={history} />;
  }
  return <AllOrdersByCustomer route={route} />;
};

export default TotalOrders;
