import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import OxCard from '../components/molecules/OxCard';
import OxTable from '../components/molecules/OxTable';
import OxLoaderOverlay from '../components/tokens/OxLoaderOverlay';
import {
  customerHeaderPlaceholder,
  getTableBodyPlaceholder,
  customerBodyPlaceholder,
  UnableToRetrieveData
} from '../helpers/placeholderHelpers';
import {
  ERROR_TITLE,
  SEARCH_CUSTOMER_PLACEHOLDER,
  ACCOUNT_TYPE_OPTIONS,
  SEARCHING_TITLE,
  SEARCH_ACCOUNT_PLACEHOLDER
} from '../helpers/constants';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxSearch from '../components/partials/OxSearch';
import { ACCOUNTS_FOR_CUSTOMER_PAGES } from '../api/account/query';
import { isGraphqlArrayEmptyForNested, isEmailFormatValid } from '../helpers';

const DATA_GI_ATTR = 'customer-search-results';

const CustomerSearchResults = withRouter(({ path, history }) => {
  CustomerSearchResults.propTypes = {
    path: PropTypes.string
  };

  const _getSearchParam = asString => {
    /**
     * If asString is passed in, return params.searchValue formatted as string.
     * parameter asString was added so that we can use this function in two ways:
     * 1. to test if the searchParamId is a number or string -> which requires value as is.
     * 2. use it for displaying the searchParam in OxSoftAlert -> which requires value formatted with quotes.
     */
    const { params } = matchPath(history.location.pathname, {
      path,
      exact: true,
      strict: false
    });
    return asString
      ? `'${decodeURIComponent(params.searchValue)}'` // Add quotes since thats how it is displayed in the UI
      : params.searchValue;
  };

  const _isSearchParamId = () => /^\d+$/.test(_getSearchParam());

  const _needsRedirect = () =>
    (_isSearchParamId() && path.indexOf('email') !== -1) ||
    (isEmailFormatValid(decodeURIComponent(_getSearchParam())) &&
      path.indexOf('details') !== -1);

  const { loading, error, data } = useQuery(ACCOUNTS_FOR_CUSTOMER_PAGES, {
    variables: {
      where: {
        accountType: ACCOUNT_TYPE_OPTIONS.ALL,
        ...(_isSearchParamId() && {
          accountId: _getSearchParam()
        }),
        ...(!_isSearchParamId() && {
          email: _getSearchParam()
        })
      }
    }
  });

  const _getAlert = () => {
    if (data && isGraphqlArrayEmptyForNested(data, 'accounts')) {
      return (
        <OxSoftAlert
          title="Could not find results for"
          emphasizedTitle={_getSearchParam(true)}
          alertType="warning"
          giDataAttr={`"${DATA_GI_ATTR}__no-results-alert"`}
          isOutlined
          eventName="noData"
        >
          <p>
            Please try another search.
          </p>
          <span className="big">
            <OxSearch
              searchType="account"
              name="customer-search"
              searchPlaceholder={SEARCH_CUSTOMER_PLACEHOLDER}
              label={SEARCH_CUSTOMER_PLACEHOLDER}
              history={history}
            />
          </span>
        </OxSoftAlert>
      );
    }
    return (
      <OxSoftAlert
        title={ERROR_TITLE}
        alertType="warning"
        giDataAttr={`"${DATA_GI_ATTR}__network-error-alert"`}
        isOutlined
      >
        {UnableToRetrieveData('customers')}
      </OxSoftAlert>
    );
  };

  return (
    <OxCard
      header={{
        title: `Account Details For`,
        emphasizedTitle: _getSearchParam(true),
        buttons: [
          () => (
            <div className="ox_card__search-box" key="order-search-result">
              <OxSearch
                searchType="account"
                name="customer-search"
                searchPlaceholder={SEARCH_ACCOUNT_PLACEHOLDER}
                label={SEARCH_ACCOUNT_PLACEHOLDER}
                history={history}
              />
            </div>
          )
        ]
      }}
      id={DATA_GI_ATTR}
      key={`customer-search-${Math.random()}`}
    >
      {_needsRedirect() && _isSearchParamId() && (
        <Redirect
          exact
          from="/accounts/email/:searchValue"
          to={`/accounts/details/${_getSearchParam()}`}
        />
      )}
      {_needsRedirect() && !_isSearchParamId() && (
        <Redirect
          exact
          from="/accounts/details/:searchValue"
          to={`/accounts/email/${encodeURIComponent(_getSearchParam())}`}
        />
      )}
      {error || (isGraphqlArrayEmptyForNested(data, 'accounts') && !loading) ? (
        _getAlert()
      ) : (
        <OxLoaderOverlay
          isLoading={loading}
          title={SEARCHING_TITLE}
          giDataAttr={`${DATA_GI_ATTR}__loading-overlay`}
        >
          <OxTable
            noAnalytics
            giDataAttr={DATA_GI_ATTR}
            data={{
              headers: customerHeaderPlaceholder,
              data:
                data &&
                data.accounts &&
                data.accounts.data &&
                !isGraphqlArrayEmptyForNested(data, 'accounts')
                  ? data.accounts.data
                  : getTableBodyPlaceholder(2, customerBodyPlaceholder)
            }}
          />
        </OxLoaderOverlay>
      )}
    </OxCard>
  );
});

export default CustomerSearchResults;
