import { gql } from '@apollo/client';

const GET_ORDER_ITEMS_BY_ORDER_NUMBER = gql`
  query($orders: [OrderItemsByOrderNumberInput]) {
    orderItemsByOrderNumber(orders: $orders) {
      data {
        items {
          orderedItem
          description
        }
        orderNumber
      }
    }
  }
`;

export default GET_ORDER_ITEMS_BY_ORDER_NUMBER;
