import { useQuery } from '@apollo/client';
import { IS_LOGGED_IN } from '../api/user/query';

/**
 * permissionsToCheck - list of permissions to check against user permissions
 *
 * Returns if the user is logged in with their time remaining
 */
const UseIsLoggedIn = () => {
  const { data, loading, refetch, error } = useQuery(IS_LOGGED_IN);
  return { isLoggedIn: data && data.getLoggedInState, loading, refetch, error };
};

export default UseIsLoggedIn;
