import { useEffect, useRef, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { OxNotificationContext } from '../components/molecules/OxNotification';
import { GET_SESSION } from '../api/user/query';
import { LOGOUT, RENEW_SESSION } from '../api/user/mutation';
import useDetectActivity from './useDetectActivity';

/**
 * Retrieved from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {*} callback
 * @param {*} delay
 */
const _useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return null;
  }, [delay]);
};

const _addMinutes = (time, minutes) =>
  new Date(time.getTime() + minutes * 60 * 1000);

const _getMinutesBetween = (laterDate, beforeDate) =>
  ((laterDate.getTime() - beforeDate.getTime()) / (1000 * 60)).toFixed(0);

const _isNMinToDate = (now, dateToCheck, minutes) =>
  _addMinutes(now, minutes) >= dateToCheck;

const useSessionTimer = () => {
  const { isActive, resetDetectActivity } = useDetectActivity();
  const { hideNotification, showNotification, isVisible } = useContext(
    OxNotificationContext
  );

  /**
   * Updates userSession in cache
   *
   * @param {*} cache
   * @param {*} param1
   */
  const _updateUserSessionInCache = (cache, { data: { renewUserSession } }) => {
    cache.writeQuery({
      query: GET_SESSION,
      data: {
        getUserSession: {
          expireAt: renewUserSession.expireAt,
          createdAt: renewUserSession.createdAt,
          __typename: 'Session'
        }
      }
    });
  };

  const [_renewSession] = useMutation(RENEW_SESSION, {
    update: _updateUserSessionInCache,
    onCompleted: () => {
      hideNotification();
      resetDetectActivity();
    }
  });

  const { data, loading: sessionInfoLoading } = useQuery(GET_SESSION, {
    /**
     * When this query first runs, renew user session.
     *
     * Regardless of whether or not the session is about to expire,
     * if the user reloaded the page (login, refresh, etc), then they are active.
     *
     * Since sessions are updated/checked every hour, this is to cover users that briefly log in.
     */
    onCompleted: response => {
      if (new Date() < response && new Date(response.getUserSession.expireAt)) {
        _renewSession();
      }
    }
  });

  const expireAt = () => data && new Date(data.getUserSession.expireAt);

  const [_logout, { client }] = useMutation(LOGOUT, {
    onCompleted: () => {
      hideNotification();
      client.resetStore();
    }
  });

  const _displayWarningToEndSession = now => {
    const minutes = _getMinutesBetween(expireAt(), now);
    showNotification(
      'Are you still there?',
      `You've been inactive for a while. For your security, we'll sign you out in ${minutes} minutes. Click below to let us know you're still here.`,
      'alert-circle',
      'global-header__session-expire-notification',
      [
        {
          text: "Yes, I'm Here",
          className: 'ox-notification__button btn-as-link',
          onClick: _renewSession,
          id: 'ox-notification__button-renew'
        },
        {
          text: 'No, Sign Me Out',
          className: 'ox-notification__button btn-as-link',
          onClick: _logout,
          id: 'ox-notification__button-logout'
        }
      ]
    );
  };

  const _checkActivity = () => {
    if (!sessionInfoLoading && data && data.getUserSession) {
      if (isActive) {
        _renewSession();
      }
    }
  };

  const _checkExpiration = () => {
    if (!sessionInfoLoading && data && data.getUserSession) {
      const now = new Date();
      // If session expires in 5 min and the user is active
      if (_isNMinToDate(now, expireAt(), 5) && isActive) {
        _renewSession();
        // If the warning should be displayed
      } else if (_isNMinToDate(now, expireAt(), 5) && !isVisible && !isActive) {
        _displayWarningToEndSession(now);
        // If the session has expired
      } else if (now >= expireAt()) {
        _logout();
      }
    }
  };

  // Check to see if user is active every 2 hours.
  // Note: This is so infrequent because the user session is renewed when
  // the user logs in or refreshes the app.
  _useInterval(_checkActivity, 1000 * 60 * 120);
  // Check if session is going to expire or has expired every second
  _useInterval(_checkExpiration, 1000);

  return null;
};

export default useSessionTimer;
