import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import OxNavItem from '../tokens/OxNavItem';
import '../../styles/components/_ox-side-nav.scss';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import AppRoute from '../../propTypes/AppRoute';
import useTabTrap from '../../hooks/useTabTrap';
import useTabbableClass from '../../hooks/useTabbableClass';

// Enums
const OPEN_RESPONSIVE_SIDE_NAV_CLASS = 'isSideNavOpened';

// Id's for closing side nav when focus is lost
const TOGGLE_BUTTON_ID = 'absolute-side-nav-header-content__button';
const SVG_ID = 'absolute-side-nav-header-content__svg';

// Id's for tab trapping
const LAST_SIDE_NAV_OPTION_ID = 'ox-side-nav__last-option';
const SIDE_NAV_ID = 'side-nav-layout';

const INTRALOX_LINK_ID = 'product-header__intralox-link';
const RESPONSIVE_INTRALOX_LINK_ID = 'product-header__responsive-intralox-link';

const SideNav = ({ sideNavOptions, children }) => {
  SideNav.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({ ...AppRoute })),
    children: PropTypes.shape({})
  };

  const [sideNavClass, _setSideNavClass] = useState('');
  const sideNavRef = useRef(null);
  const hasClickedOutsideOfNav = useDetectOutsideClick(sideNavRef);
  useTabTrap(
    TOGGLE_BUTTON_ID,
    LAST_SIDE_NAV_OPTION_ID,
    SIDE_NAV_ID,
    sideNavClass === OPEN_RESPONSIVE_SIDE_NAV_CLASS
  );
  const tabbableClassForToggleButton = useTabbableClass(TOGGLE_BUTTON_ID);
  const tabbableClassForIntraloxLink = useTabbableClass(INTRALOX_LINK_ID);
  const tabbableClassForResponsiveIntraloxLink = useTabbableClass(
    RESPONSIVE_INTRALOX_LINK_ID
  );

  /**
   * Lifecycle method that executes whenever hasClickedOutsideOfNav or sideNavClass changes.
   */
  useEffect(() => {
    // If the user has clicked out side of the side nav and did not click on the toggle
    if (
      hasClickedOutsideOfNav &&
      hasClickedOutsideOfNav.id !== SVG_ID &&
      hasClickedOutsideOfNav.id !== TOGGLE_BUTTON_ID
    ) {
      _setSideNavClass('');
    }
  }, [hasClickedOutsideOfNav]);

  /**
   * Handles click events for side nav header when positioned absolutely.
   */
  const toggleSideNav = () => {
    if (sideNavClass === OPEN_RESPONSIVE_SIDE_NAV_CLASS) {
      _setSideNavClass('');
    } else {
      _setSideNavClass(OPEN_RESPONSIVE_SIDE_NAV_CLASS);
    }
  };

  /**
   * Returns side nav options to display;
   */
  const _sideNavFilteredOptions = () => {
    return sideNavOptions.map(sideNavOption => (
      <OxNavItem
        {...sideNavOption}
        {...(sideNavOptions[sideNavOptions.length - 1] === sideNavOption && {
          id: LAST_SIDE_NAV_OPTION_ID
        })}
        handleClickEvent={() => _setSideNavClass('')}
      />
    ));
  };

  /**
   * Returns side nav.
   */
  const _renderSideNav = () => {
    return (
      <section>
        <div className="product-header">
          <h1 className="product-header__h1">
            <Link
              className={tabbableClassForIntraloxLink}
              id={INTRALOX_LINK_ID}
              to="/"
            >
              Intralox Self-Serve
            </Link>
          </h1>
        </div>
        <nav className="ox-side-nav__nav">
          <ul>{_sideNavFilteredOptions()}</ul>
        </nav>
      </section>
    );
  };

  /**
   * Conditionally displays side nav header provided screen width via
   * media query.
   */
  const _renderResponsiveSideNavHeader = () => {
    return (
      <div className="absolute-side-nav-header">
        <div className="product-header">
          <button
            id={TOGGLE_BUTTON_ID}
            className={`absolute-side-nav-header-content__hamburger ${tabbableClassForToggleButton} tabbable--negative-ol-offset`}
            type="button"
            aria-pressed="false"
            onClick={toggleSideNav}
          >
            <FeatherIcon
              icon="more-vertical"
              id="absolute-side-nav-header-content__svg"
            />
          </button>
          <h1 className="product-header__h1">
            <Link
              className={tabbableClassForResponsiveIntraloxLink}
              to="/"
              id={RESPONSIVE_INTRALOX_LINK_ID}
            >
              Intralox Self-Serve
            </Link>
          </h1>
        </div>
      </div>
    );
  };

  /**
   * Renders side nav and children.
   */
  return (
    <div className="side-nav-layout" id={SIDE_NAV_ID}>
      {_renderResponsiveSideNavHeader()}
      <div className="ox-side-nav-with-content">
        <div ref={sideNavRef} className={`ox-side-nav ${sideNavClass}`}>
          {_renderSideNav()}
        </div>
        <div className={`ox-side-nav-with-content__overlay ${sideNavClass}`} />
        {children}
      </div>
    </div>
  );
};

export default SideNav;
