import React from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import '../../../styles/components/_user-input.scss';
import OxRequiredIndicator from './OxRequiredIndicator';
import { OxTooltip } from '../../molecules/OxTooltip';
import { pipe } from '../../../helpers';
import { ACCEPTED_UPLOAD_TYPES } from '../../../helpers/constants';

const OxInput = ({
  name,
  minDate,
  value,
  placeholder,
  handleKeyUp,
  label,
  floatLabel,
  icon,
  giDataAttr,
  inputType,
  noAnalytics,
  required,
  tooltipContent,
  tooltipScrollable,
  maxLength,
  hasError,
  pattern,
  title,
  readOnly,
  helperClass,
  handleIconClick
}) => {
  OxInput.propTypes = {
    inputType: PropTypes.oneOf([
      'text',
      'email',
      'password',
      'date',
      'time',
      'number',
      'url',
      'file'
    ]).isRequired,
    name: PropTypes.string.isRequired,
    minDate: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    handleKeyUp: PropTypes.func,
    handleIconClick: PropTypes.func,
    label: PropTypes.string,
    floatLabel: PropTypes.bool,
    icon: PropTypes.string,
    giDataAttr: PropTypes.string,
    noAnalytics: PropTypes.bool,
    required: PropTypes.bool,
    tooltipContent: PropTypes.string,
    tooltipScrollable: PropTypes.bool,
    maxLength: PropTypes.number,
    hasError: PropTypes.bool,
    pattern: PropTypes.string,
    title: PropTypes.string,
    helperClass: PropTypes.string
  };

  const _getLabelTypeClass = () => {
    return floatLabel ? 'float-label-group' : 'input-label-group';
  };

  const _getIconClass = currentLabel => {
    if (icon) {
      return `icon ${currentLabel} with-icon--right`;
    }
    return currentLabel;
  };

  const _getErrorClass = currentClass => {
    return hasError ? `${currentClass} error` : currentClass;
  };

  /**
   * Has the possiblilty of returning 3 classes:
   * 1. float-label-group or input-label-group
   * 2. with-icon--right or with-icon
   * 3. error
   */
  const _getContainerClass = pipe(
    _getLabelTypeClass,
    _getIconClass,
    _getErrorClass
  );

  const _getReadOnlyClass = labelType => {
    return readOnly ? `${labelType}--readOnly` : labelType;
  };

  const _getLabelTextClass = pipe(
    _getLabelTypeClass,
    _getIconClass,
    response => `${response}__label-text`,
    _getReadOnlyClass
  );

  const _getLabelClass = pipe(
    _getLabelTypeClass,
    _getIconClass,
    response => `${response}__label`
  );

  const _getAcceptType = () => {
    return inputType === 'file'
      ? [
          ...new Set(ACCEPTED_UPLOAD_TYPES.map(fileType => fileType.mimeType)) // filters out duplicates
        ].join(', ')
      : null;
  };

  return (
    <div className={`${_getContainerClass()} ${helperClass}`}>
      {icon ? (
        <span
          onClick={handleIconClick || null}
          tabIndex={0}
          role="button"
          className="ox-input__icon ox_input__icon--right"
          onKeyUp={handleKeyUp}
        >
          <FeatherIcon icon={icon} />
        </span>
      ) : null}
      <input
        readOnly={readOnly}
        data-lpignore={inputType !== 'password'} // Conditionally use lastpass
        type={inputType || 'text'}
        pattern={pattern || null}
        name={name}
        min={minDate}
        id={name}
        value={value}
        placeholder={placeholder}
        onKeyUp={handleKeyUp}
        onChange={handleKeyUp}
        data-gi={giDataAttr}
        title={title || ''}
        heap-ignore={noAnalytics ? 'true' : null}
        data-hj-suppress={noAnalytics ? 'true' : null}
        required={required ? true : null}
        maxLength={maxLength || null}
        accept={_getAcceptType()}
      />
      <label htmlFor={name} className={_getLabelClass()}>
        <div className={`${_getLabelTextClass()} width-100`}>
          <OxRequiredIndicator required={!!required}>
            {label}
          </OxRequiredIndicator>
        </div>
        {tooltipContent ? (
          <OxTooltip
            content={tooltipContent}
            tooltipScrollable={tooltipScrollable}
          >
            <FeatherIcon icon="info" className="tooltip-icon" />
          </OxTooltip>
        ) : null}
      </label>
    </div>
  );
};

export default OxInput;
