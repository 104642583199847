import { useQuery } from '@apollo/client';
import FeatherIcon from "feather-icons-react";
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { GET_APPROVED_EMAILS } from '../api/approvedEmails/query';
import { GET_USER_INFO } from '../api/user/query';
import OxCard from '../components/molecules/OxCard';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxLoader from '../components/tokens/OxLoader';
import { userIsAdmin } from '../helpers';
import "../styles/components/_approvedEmails.scss"

const ApprovedEmails = () => {
    // Logged in user's info
    const { data: userData } = useQuery(GET_USER_INFO)

    // List of approved emails with their role
    const { loading, data } = useQuery(GET_APPROVED_EMAILS, {});

    // Track the search term
    const [searchTerm, setSearchTerm] = useState('');

    // filters the list of emails based on the search term (case-insensitive)
    const filteredItems = data?.approvedEmails.filter(item => item.email.toLowerCase().includes(searchTerm.toLowerCase()));

    // Used to programatically navigate
    const history = useHistory();

    // Check for add user success
    const searchParams = new URLSearchParams(useLocation().search);
    const isUserAddSuccessful = searchParams.get('success') === 'true';

    // Search bar to display at the top of the OxCard
    const searchBar = () => (
        <div>
            {
                userIsAdmin(userData) && (
                    <div className='add-approved-email-btn' 
                        onClick={() => { history.push('/approvedEmails/add') }}>
                        <FeatherIcon icon="plus" />
                        Add Email
                    </div>
                )
            }
        </div>
    );

    return (
        <OxCard header={{
            title: 'Approved Emails',
            buttons: [searchBar]
        }}>
            {isUserAddSuccessful && (
                <OxSoftAlert
                    isOutlined
                    alertType='success'
                    title='User added successfully'
                />
            )}
            {loading && (<OxLoader />)}
            {!loading && (
                <div className='approved-email-list'>
                    <input
                        className='approved-email-list__search'
                        placeholder='Search for a user'
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                    />
                    <table>
                        <tr>
                            <th>Email</th>
                            <th>Role</th>
                        </tr>
                        {filteredItems.map(item => (
                            <tr>
                                <td>{item.email}</td>
                                <td>{item.role}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            )}
        </OxCard>
    );
};

export default ApprovedEmails; 