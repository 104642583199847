import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter, Switch, Route } from 'react-router-dom';
import GlobalHeader from './components/partials/GlobalHeader';
import GlobalFooter from './components/partials/GlobalFooter';
import Home from './components/partials/Home';
import { OxNotification } from './components/molecules/OxNotification';
import { OxModal } from './components/molecules/OxModal';
import UseIsLoggedIn from './hooks/useIsLoggedIn';
import ErrorPage from './pages/ErrorPage';

const MAINNAVLINKS = [];

const App = ({ history }) => {
  const { isLoggedIn, loading: loggedInLoading } = UseIsLoggedIn();

  /**
   * Redirect user to Okta login page
   */
  const _redirectToLogin = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/login?returnTo=${window.location.href}`;
  };

  /**
   * Store the current URL before the user switches to another page,
   * used for back button render logic
   */
  history.listen(() => {
    sessionStorage.setItem('lastVisitedUrl', window.location.pathname);
  });

  // Retrieved from https://stackoverflow.com/questions/10964966/detect-ie-version-prior-to-v9-in-javascript
  // Returns ie version if less than 11, otherwise returns false
  const isIeTenOrLess = () => {
    const myNav = navigator.userAgent.toLowerCase();
    return myNav.indexOf('msie') !== -1
      ? parseInt(myNav.split('msie')[1], 10)
      : false;
  };

  return isIeTenOrLess() || loggedInLoading ? null : (
    <div className="flex--full-column height-100 inner-scroll">
      {!loggedInLoading && !isLoggedIn ? (
        <Switch>
          <Route path="/error/:errorType" component={ErrorPage} />
          <Route render={_redirectToLogin} />
        </Switch>
      ) : (
        <OxModal>
          <OxNotification>
            <GlobalHeader
              title="Intralox - A world leading conveyer belt company."
              links={MAINNAVLINKS}
              callOutLinks
            />
            <Switch>
              <Route component={Home} />
            </Switch>
            <GlobalFooter />
          </OxNotification>
        </OxModal>
      )}
      {/* Prevents mobile from auto zooming
       * window.orientation is a field often used in mobile
       * we are assuming that if it is defined, they are using a mobile device
       */}
      {typeof window.orientation !== 'undefined' ? (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1, minimum-scale=1"
        />
      ) : null}
    </div>
  );
};

// Apply context (client) to App
const AppWithData = withApollo(withRouter(App));

export default AppWithData;
