import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/_intralox-badge.scss';
import useTabbableClass from '../../hooks/useTabbableClass';

const IntraloxBadge = ({ title }) => {
  IntraloxBadge.propTypes = {
    title: PropTypes.string
  };

  const LINK_ID = 'intralox-badge__link';
  const tabbableClass = useTabbableClass(LINK_ID);

  const _getWidth = () => {
    return 124.4;
  };

  const _getHeight = () => {
    return 75.7;
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${_getWidth()} ${_getHeight()}`}
      className="intralox-badge__svg"
    >
      <a
        href="http://intralox.com/"
        title={title}
        id={LINK_ID}
        className={`${tabbableClass} tabbable--negative-ol-offset tabbable--white-ol`}
        aria-label="Intralox Website"
      >
        <title>{title}</title>
        <rect className="a" width={_getWidth()} height={_getHeight()} />
        <path
          className="b"
          d="M104.31,30.15A1.85,1.85,0,1,1,106.16,32,1.82,1.82,0,0,1,104.31,30.15Zm1.85,1.52a1.52,1.52,0,1,0-1.48-1.52A1.47,1.47,0,0,0,106.16,31.67Zm-0.38-.47h-0.32V29.1h0.8a0.59,0.59,0,0,1,.74.6,0.55,0.55,0,0,1-.54.58l0.6,0.92H106.7l-0.55-.91h-0.37V31.2ZM106.16,30c0.27,0,.51,0,0.51-0.34s-0.24-.31-0.46-0.31h-0.43V30h0.38Z"
        />
        <path
          className="c"
          d="M43.4,40.5a4.12,4.12,0,0,0,.8,2.67,2.45,2.45,0,0,0,1.88.86h4.14V41.18H48.65a0.44,0.44,0,0,1-.47-0.5V31.19h2.17V28.09H48.18V23.91H43.41V28.1H41.24v3.12H43.4v9.3Z"
        />
        <path
          className="c"
          d="M51.14,33.51V44h4.65V33.85a2.22,2.22,0,0,1,.27-1.29,2.11,2.11,0,0,1,2.09-.7,5.18,5.18,0,0,1,.85.19l0.11,0V32s0-3.33,0-3.65v0h0a5.13,5.13,0,0,0-3.61,1.11c0-.26-0.13-0.85-0.14-1.05h0V28.28H50.93v0.07A36,36,0,0,1,51.14,33.51Z"
        />
        <rect className="c" x="22.35" y="22.19" width="5.16" height="5" />
        <rect className="c" x="22.35" y="28.09" width="5.16" height="15.89" />
        <path
          className="c"
          d="M29.33,31.76l0,12.22V44h4.78V44c0-.53,0-12.94,0-13.1a0.36,0.36,0,0,1,.09-0.28A1.23,1.23,0,0,1,35,30.36c0.63,0,.89.11,0.91,0.5,0,1.5,0,12.93,0,13V44h5.2V43.9a14.89,14.89,0,0,1-.17-1.66c0-.21,0-0.45,0-0.71s0-2,0-3.93c0-3.43,0-7.7-.15-8.11a2,2,0,0,0-1-1.14,6.76,6.76,0,0,0-4.08-.49,3.53,3.53,0,0,0-1.75.86c0-.07,0-0.13,0-0.18a1.05,1.05,0,0,0-.13-0.41l0,0-4.63,0V28.2A17.28,17.28,0,0,1,29.33,31.76Z"
        />
        <path
          className="c"
          d="M60.23,43.51a3,3,0,0,0,2.33.75h0.79a3.91,3.91,0,0,0,2.77-.92c0,0.09,0,.18,0,0.27a2.27,2.27,0,0,0,.08.4v0h5V44c0-.19-0.06-0.38-0.09-0.58a12.6,12.6,0,0,1-.2-1.8l0-3.64c0-.25.05-6.18,0-6.8a3.16,3.16,0,0,0-1.55-2.26A9.73,9.73,0,0,0,65,28.08H64.86c-1.65.12-4.13,0.3-5,2.38a7.83,7.83,0,0,0-.22,2.62c0,0.2,0,.41,0,0.6v0.06l4.79,0V33.64s0-2,.06-3a0.6,0.6,0,0,1,.34-0.39,1.23,1.23,0,0,1,1,.09,0.62,0.62,0,0,1,.22.38v3a1,1,0,0,1-1,.9,13.3,13.3,0,0,1-1.53.06,6.39,6.39,0,0,0-2.71.37,2.54,2.54,0,0,0-1.32,2.24v4.22A2.85,2.85,0,0,0,60.23,43.51Zm4.08-6.23V37.23a0.44,0.44,0,0,1,.28-0.39,2.54,2.54,0,0,1,1.3-.17h0.17v4.46a0.51,0.51,0,0,1-.43.6l-0.44.07a0.9,0.9,0,0,1-.63,0,0.78,0.78,0,0,1-.21-0.53c0-.26,0-0.55,0-0.84A19.65,19.65,0,0,1,64.31,37.28Z"
        />
        <rect className="c" x="72.34" y="22.1" width="5.14" height="21.9" />
        <polygon
          className="c"
          points="100.95 35.55 103.82 28.33 103.85 28.25 98.52 28.24 98.47 28.24 97.03 33.36 95.41 28.24 95.4 28.2 90.29 28.21 90.2 28.21 93.27 36.08 90.15 43.89 90.12 43.97 95.39 43.95 95.44 43.95 97.03 38.24 98.81 43.91 98.82 43.95 103.96 43.97 104.05 43.97 100.95 35.55"
        />
        <path
          className="c"
          d="M98.88,45.07a4.13,4.13,0,0,0-3.95,2.87l-4.17.67-0.23-1.38a0.65,0.65,0,0,0-.26-0.44,0.68,0.68,0,0,0-.52-0.13H89.66a0.7,0.7,0,0,0-.56.78l0.23,1.37-5.94,1-0.23-1.37a0.67,0.67,0,0,0-.78-0.55H82.29a0.69,0.69,0,0,0-.56.78L81.95,50l-4,.64a4.15,4.15,0,0,0-6.44-.14l-4.2-.7,0.23-1.38A0.68,0.68,0,0,0,67,47.66H66.9a0.69,0.69,0,0,0-.78.56l-0.23,1.37-5.94-1,0.23-1.37a0.69,0.69,0,0,0-.56-0.78H59.54a0.66,0.66,0,0,0-.5.11,0.69,0.69,0,0,0-.29.45l-0.22,1.36-4-.67a4.12,4.12,0,0,0-3.83-2.57,4.17,4.17,0,0,0-3.88,2.67l-4.05.65L42.55,47a0.69,0.69,0,0,0-.79-0.56l-0.07,0a0.68,0.68,0,0,0-.56.78l0.23,1.37-5.94,1L35.2,48.24a0.68,0.68,0,0,0-.78-0.55H34.33a0.68,0.68,0,0,0-.55.78L34,49.84l-4,.64a4.14,4.14,0,1,0,.62,3.82l4-.65L34.86,55a0.69,0.69,0,0,0,.79.56l0.09,0a0.68,0.68,0,0,0,.56-0.78l-0.22-1.36,5.94-1,0.23,1.37a0.69,0.69,0,0,0,.78.56H43.1a0.69,0.69,0,0,0,.56-0.78l-0.22-1.36,3.9-.62a4.15,4.15,0,0,0,6.86-.08l3.75,0.63-0.23,1.38a0.69,0.69,0,0,0,.57.78h0.09a0.69,0.69,0,0,0,.78-0.56l0.23-1.38,5.94,1-0.23,1.37a0.69,0.69,0,0,0,.56.78h0.08A0.69,0.69,0,0,0,66.51,55l0.22-1.36,4,0.67a4.14,4.14,0,0,0,7.93.17l3.94-.63,0.23,1.39a0.68,0.68,0,0,0,.78.55H83.7A0.69,0.69,0,0,0,84.26,55L84,53.63l5.95-1L90.2,54a0.68,0.68,0,0,0,.78.55h0.08a0.68,0.68,0,0,0,.44-0.28,0.66,0.66,0,0,0,.11-0.5l-0.22-1.36,4.21-.68a4.1,4.1,0,0,0,3.28,1.6A4.15,4.15,0,0,0,98.88,45.07ZM100,49.22a1.16,1.16,0,1,1-1.16-1.16A1.16,1.16,0,0,1,100,49.22Zm-24.16,4A1.16,1.16,0,1,1,74.72,52,1.16,1.16,0,0,1,75.88,53.2Zm-24-4a1.18,1.18,0,0,1-1.17,1.16A1.16,1.16,0,1,1,51.91,49.24ZM27.85,53a1.12,1.12,0,0,1-.33.81A1.16,1.16,0,1,1,27.85,53Z"
        />
        <path
          className="c"
          d="M82.92,44.17q0.92,0.07,1.73.07a10.54,10.54,0,0,0,4-.67,2.54,2.54,0,0,0,1.59-2.35l0-9.9a2.49,2.49,0,0,0-1.36-2.53A9.75,9.75,0,0,0,85.13,28a8.44,8.44,0,0,0-5,1,2.73,2.73,0,0,0-1.17,2.49v9.77a2.6,2.6,0,0,0,.68,1.69A5.92,5.92,0,0,0,82.92,44.17Zm2.6-2.64a0.44,0.44,0,0,1-.19.4,1.49,1.49,0,0,1-1.29,0,0.51,0.51,0,0,1-.19-0.38V30.8h0V30.66A0.55,0.55,0,0,1,84,30.28a1.51,1.51,0,0,1,1.29-.05,0.47,0.47,0,0,1,.21.42V41.53Z"
        />
      </a>
    </svg>
  );
};

export default IntraloxBadge;
