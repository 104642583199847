import { gql } from '@apollo/client';

const LOGOUT = gql`
  mutation logoutMutation {
    logout {
      status
    }
  }
`;

const RENEW_SESSION = gql`
  mutation renewUserSession {
    renewUserSession {
      expireAt
      createdAt
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(where: $where, data: $data) {
      id
      active
      name
      email
      userType {
        id
        name
      }
      allFeatures {
        id
        name
      }
      userRole {
        id
        name
      }
      additionalFeatures {
        id
        name
      }
      originUrls
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      active
      name
      email
      userType {
        id
        name
      }
      allFeatures {
        id
        name
      }
      userRole {
        id
        name
      }
      additionalFeatures {
        id
        name
      }
      originUrls
    }
  }
`;

export { LOGOUT, RENEW_SESSION, UPDATE_USER, CREATE_USER };
