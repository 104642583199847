import PropTypes from 'prop-types';
import { StringValue } from './Value';
import Url from './Url';

const Order = {
  loading: PropTypes.bool,
  parentCardTitle: PropTypes.string,
  path: PropTypes.string,
  error: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      accountName: PropTypes.string,
      id: PropTypes.string,
      orderNumber: PropTypes.string,
      orderPlacedDate: PropTypes.string,
      purchaseOrderId: PropTypes.string,
      shipments: PropTypes.arrayOf(
        PropTypes.shape({
          carrier: PropTypes.shape(StringValue),
          deliveryStatus: PropTypes.shape(StringValue),
          expectedDeliveryDate: PropTypes.shape(StringValue),
          orderStatus: PropTypes.shape(StringValue),
          shipmentDate: PropTypes.shape(StringValue),
          id: PropTypes.shape(StringValue),
          trackingNumber: PropTypes.shape({
            url: PropTypes.shape(Url),
            value: PropTypes.string
          }),
          packingSlip: PropTypes.shape({
            func: PropTypes.func,
            value: PropTypes.string
          }),
          invoice: PropTypes.arrayOf(PropTypes.string)
        })
      )
    })
  )
};

export default Order;
