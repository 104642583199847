import React from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import '../../../styles/components/_user-input.scss';
import OxRequiredIndicator from './OxRequiredIndicator';
import { OxTooltip } from '../../molecules/OxTooltip';

const OxTextarea = ({
  name,
  value,
  placeholder,
  handleKeyUp,
  label,
  floatLabel,
  icon,
  giDataAttr,
  noAnalytics,
  rows,
  required,
  tooltipContent,
  tooltipScrollable
}) => {
  OxTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    handleKeyUp: PropTypes.func,
    label: PropTypes.string,
    floatLabel: PropTypes.bool,
    icon: PropTypes.string,
    giDataAttr: PropTypes.string,
    noAnalytics: PropTypes.bool,
    rows: PropTypes.number,
    required: PropTypes.bool.isRequired,
    tooltipContent: PropTypes.string,
    tooltipScrollable: PropTypes.bool
  };

  const _getLabelClass = () => {
    if (icon) {
      return ` 
        ${
          floatLabel
            ? 'float-label-group with-icon'
            : 'input-label-group with-icon'
        }`;
    }
    return `${floatLabel ? 'float-label-group' : 'input-label-group'}`;
  };

  return (
    <div className={_getLabelClass()}>
      {icon ? (
        <span className="icon">
          <FeatherIcon icon={icon} />
        </span>
      ) : null}
      <textarea
        name={name}
        className={`${_getLabelClass()}__textarea`}
        id={name}
        rows={rows || 5}
        value={value}
        placeholder={placeholder}
        onChange={handleKeyUp}
        data-gi={giDataAttr}
        heap-ignore={noAnalytics ? 'true' : null}
        data-hj-suppress={noAnalytics ? 'true' : null}
        required={required ? true : null}
      />
      <label htmlFor={name} className={`${_getLabelClass()}__label`}>
        <div>
          <OxRequiredIndicator required={!!required}>
            {label}
          </OxRequiredIndicator>
        </div>
        {tooltipContent ? (
          <OxTooltip
            content={tooltipContent}
            tooltipScrollable={tooltipScrollable}
          >
            <FeatherIcon icon="info" className="tooltip-icon" />
          </OxTooltip>
        ) : null}
      </label>
    </div>
  );
};

export default OxTextarea;
