import { gql } from '@apollo/client';

const GET_GENERAL_DOCUMENTS = gql`
  query generalDocuments($where: GeneralDocumentWhereInput!) {
    generalDocuments(where: $where) {
      id
      name
      language
      documentType
      fileType
      size
      url
    }
  }
`;

const GET_PRODUCT_DOCUMENTS = gql`
  query($where: [ProductDocumentWhereInput!]!) {
    productDocuments(where: $where) {
      id
      name
      language
      documentType
      fileType
      size
      url
      orderNumber
      containsThermoDriveDocuments
      containsMpbManuals
    }
  }
`;

const DOWNLOAD_MULTIPLE_DOCUMENTS = gql`
  query($where: [MultiDocumentDownloadInput]!) {
    multiDocumentDownload(where: $where) {
      fileType
      url
      size
    }
  }
`;

export {
  GET_GENERAL_DOCUMENTS,
  GET_PRODUCT_DOCUMENTS,
  DOWNLOAD_MULTIPLE_DOCUMENTS
};
