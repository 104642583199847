import { gql } from '@apollo/client';

const GET_USER_TYPES = gql`
  query getUserTypes {
    userTypes {
      id
      name
    }
  }
`;

const GET_USER_ROLES = gql`
  query getUserRoles {
    userRoles {
      id
      name
    }
  }
`;

const GET_FEATURES = gql`
  query getFeatures {
    features {
      id
      name
    }
  }
`;

export { GET_USER_TYPES, GET_FEATURES, GET_USER_ROLES };
