import myDashboardRoutes from './dashboard';
import myAccountsRoutes from './accounts';
import myOrdersRoutes from './orders';
import caseCreatorRoutes from './case-creator';
import adminRoutes from './admin';
import approvedEmailsRoutes from './approvedEmails';

const routes = [
  myDashboardRoutes,
  myAccountsRoutes,
  myOrdersRoutes,
  caseCreatorRoutes,
  adminRoutes,
  approvedEmailsRoutes
];

export default routes;
