import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  matchPath,
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter
} from 'react-router-dom';
import { TO_PREVIOUS_PAGE } from '../helpers/constants';
import AppRoute from '../propTypes/AppRoute';
import RouteWithSubRoutes from '../routes/RoutesWithSubRoutes';
import '../styles/pages/_interior.scss';
import NoMatch from './NoMatch';
import getRequiredPaginationParameters from '../helpers/routes';
import { GET_USER_INFO } from '../api/user/query';
import { logEvent, logPageView } from '../helpers/segmentLogger';

const InteriorHeader = withRouter(({ routes }) => {
  const { data: userData } = useQuery(GET_USER_INFO);
  const { pathname } = useLocation();
  const history = useHistory();
  const [currentDynamicRouteParams, setCurrentDynamicRouteParams] = useState(
    {}
  );

  /**
   * If a route has the 'isActiveForRoutes' property, determine if the current route
   * should show as active
   * @param {AppRoute} route
   * @returns {Boolean}
   */
  const isRouteActiveForPath = route => {
    return route && route.isActiveForRoutes
      ? route.isActiveForRoutes.some(path => {
          if (typeof path === 'string') {
            return pathname.includes(path);
          }
          if (path instanceof RegExp) {
            return path.test(pathname);
          }
          return false;
        })
      : false;
  };

  /**
   * Determine if a link in the interior header is active or not.
   * This will vary depending on if a link is dynamic or static.
   */
  const isInteriorLinkActive = route => {
    return (
      route.path === pathname ||
      matchPath(pathname, { path: route.path, exact: true, strict: false }) ||
      isRouteActiveForPath(route)
    );
  };

  /**
   * Lifecycle method that executes when the current route or list of provided routes changes.
   *
   * It then determines, out of the provided routes, which one is active. If the active route is
   * a dynamic path it retrieves the route params and sets currentDynamicRouteParams.
   */
  useEffect(() => {
    const currentDyanmicRoute = routes.find(route =>
      matchPath(pathname, {
        path: route.path,
        exact: true,
        strict: false
      })
    );

    if (currentDyanmicRoute) {
      setCurrentDynamicRouteParams(
        matchPath(pathname, {
          path: currentDyanmicRoute.path,
          exact: true,
          strict: false
        }).params
      );
    }

    // Log a page view to analytics when path changes
    logPageView();
  }, [history.location.pathname, pathname, routes]);

  /**
   * checkRoute looks for parent level route in our routes constants that is passed in as a prop. The function also has one exception for a dynamic route coming from
   * documents to customer orders back to search results. Since the URL uses a search number, we have to account for that case specifically. Any dynamic route with variables will need to be added here.
   */
  const checkRoute = location => {
    const locationUrlDetails = location.split('/');
    let backRoute = (routes && routes[0] && routes[0].path) || null;
    let backButtonTitle = TO_PREVIOUS_PAGE;
    let backButtonEvent = null;
    routes.forEach(route => {
      if (
        route.path &&
        matchPath(pathname, { path: route.path, exact: true, strict: false })
      ) {
        backRoute = route.backButtonPath
          ? route.backButtonPath(locationUrlDetails)
          : backRoute;
        backButtonTitle = route.backButtonTitle || backButtonTitle;
        backButtonEvent = route.backButtonEvent || backButtonEvent;
      }
    });
    return {
      backRoute,
      backButtonTitle,
      backButtonEvent
    };
  };

  const isAtLeastThirdLevelRoute = () =>
    routes[0].path !== history.location.pathname;

  /**
   * This function checks the route and dynamically renders the back button based on if the user has previously
   * visited a page within the application
   */
  const hasBackButton = () => {
    return sessionStorage.getItem('lastVisitedUrl');
  };

  const getBackButton = () => {
    if (hasBackButton()) {
      const backRouteDetails = checkRoute(window.location.pathname);
      const logBackEvent = () => {
        return backRouteDetails.backButtonEvent
          ? logEvent(backRouteDetails.backButtonEvent)
          : null;
      };

      return (
        <span
          role="button"
          tabIndex="0"
          key={`header-buttons-${Math.random()}`}
          className="product-header__back-button"
          data-gi="product-header__back-button"
          // eslint-disable-next-line consistent-return
          onClick={() => {
            logBackEvent();
            history.push(backRouteDetails.backRoute);
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              logBackEvent();
              history.push(backRouteDetails.backRoute);
            }
            return null;
          }}
          title={backRouteDetails.backButtonTitle}
        >
          <FeatherIcon icon="arrow-left" />
          <div className="product-header__back-button__text">
            {backRouteDetails.backButtonTitle}
          </div>
        </span>
      );
    }
    return null;
  };

  /**
   * Determines the next path when the user selects a route in the interior header.
   */
  const getNextPath = route => {
    getNextPath.propTypes = {
      route: PropTypes.shape(AppRoute)
    };

    // Determine if provided route is dynamic
    let dynamicPath = matchPath(route.path, {
      path: route.path,
      exact: true,
      strict: false
    });

    // If the route is dynamic, replace the route params with the previouse route parameters if they exist.
    // i.e. If the user is on /orders/1184578/open-orders, then the route for customer orders should be: /orders/1184578/customer-orders.
    if (dynamicPath && Object.keys(currentDynamicRouteParams).length) {
      dynamicPath = {
        ...dynamicPath,
        url: dynamicPath.url
          .split('/')
          .map(s =>
            s.startsWith(':') ? currentDynamicRouteParams[s.substr(1)] : s
          )
          .join('/')
      };
    }

    return dynamicPath ? dynamicPath.url : route.path;
  };

  const queryParams = new URLSearchParams(useLocation().search);

  const hideRouteInInteriorNav = route => {
    let result = false;

    if (route.hideInInteriorNav) {
      switch (typeof route.hideInInteriorNav) {
        case 'function':
          result = route.hideInInteriorNav(userData);
          break;
        case 'boolean':
          result = route.hideInInteriorNav;
          break;
        default:
          result = false;
          break;
      }
    }

    return result;
  };

  return (
    <div className="product-header">
      <nav className="navbar">
        <ul className="navbar__list">
          {routes.reduce((navLinks, route) => {
            if (!hideRouteInInteriorNav(route)) {
              navLinks.push(
                <li className="navbar__list-item" key={route.key}>
                  <NavLink
                    className="navbar__link"
                    isActive={() => isInteriorLinkActive(route, history)}
                    to={{
                      pathname: getNextPath(route),
                      search: getRequiredPaginationParameters(
                        route,
                        queryParams
                      )
                    }}
                    activeClassName="selected"
                    data-gi={`navbar__${route.key}`}
                  >
                    {route.title}
                  </NavLink>
                </li>
              );
            }
            return navLinks;
          }, [])}
        </ul>
      </nav>
      {isAtLeastThirdLevelRoute() ? getBackButton() : null}
    </div>
  );
});

/**
 * Component used for rendering interior views.
 *
 * @param {*} param
 */

const Interior = ({ routes }) => {
  Interior.propTypes = {
    path: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.shape({ ...AppRoute }))
  };

  return (
    <main className="interior-layout">
      <InteriorHeader routes={routes} />
      <div className="interior-layout__content">
        <Switch>
          {routes.map(route => (
            <RouteWithSubRoutes key={route.id} {...route} />
          ))}
          <Route component={NoMatch} />
        </Switch>
      </div>
    </main>
  );
};

export default Interior;
