import React from 'react';
import { useQuery } from '@apollo/client';
import { Switch, Route, Redirect } from 'react-router-dom';
import SideNav from './SideNav';
import RouteWithSubRoutes from '../../routes/RoutesWithSubRoutes';
import { GET_USER_INFO } from '../../api/user/query';
import NoMatch from '../../pages/NoMatch';
import { hasPermission } from '../../hooks/useHasPermission';
import routes from '../../routes/routes';
import useSessionTimer from '../../hooks/useSessionTimer';
import { isInternal } from '../../helpers';
import { LEGACY_ROUTE_REDIRECTS } from '../../helpers/constants';
import RedirectWithQueryParams from '../../routes/RedirectWithQueryParams';
import * as segment from '../../helpers/segmentLogger';
import OxLoader from '../tokens/OxLoader';

const Home = () => {
  const { data, loading } = useQuery(GET_USER_INFO, {
    onCompleted: res => {
      segment.identify(res.userInfo);
    }
  });

  /**
   * Returns a list of routes that the user can access.
   * Filters out any routes where hideInSideNav is true, user doesn't have permissions to access,
   * or auth function does not pass.
   *
   * @returns {AppRoute[]}
   */
  const _getFilteredRoutes = () => {
    let userRoutes = [];

    if (data && !loading) {
      userRoutes = routes.filter(route => {
        return (
          !route.hideInSideNav &&
          (!route.requiredPermissions ||
            hasPermission(data, route.requiredPermissions)) &&
          (!route.authFunctions ||
            route.authFunctions.every(func => func(data)))
        );
      });
      if (
        data.accountId.accountIds.length <= 1 &&
        data.userInfo.userType.name === 'external'
      ) {
        userRoutes = userRoutes.reduce((seed, route) => {
          if (route.key === 'accounts') {
            return seed;
          }
          seed.push(route);
          return seed;
        }, []);
      }
    }
    return userRoutes;
  };

  /**
   * If user is internal, we want to return all routes.  This way, all routes
   * will be rendered in the <Switch> and we can inform the user if they try to access
   * a route they do not have access to.  If user is external, do not render out
   * <Routes> for any route they don't have access to.
   *
   * @returns {AppRoute[]}
   */
  const _getRoutes = () => {
    return isInternal(data) ? routes : _getFilteredRoutes();
  };

  /**
   * Use these booleans to hide the NoMatch route until the normal
   * routes and redirect routes have been rendered.  This stops the 404 screen
   * from appearing before these routes get rendered.
   */
  let routesRendered = false;
  let redirectsRendered = false;

  useSessionTimer();

  return (
    <SideNav sideNavOptions={_getFilteredRoutes()}>
      <Switch>
        {loading && (
          <div className="width-100 units-row">
            <div className="unit-100 margin-top">
              <OxLoader />
            </div>
          </div>
        )}

        {_getRoutes().map((route, index) => {
          if (index === _getRoutes().length - 1) {
            routesRendered = true;
          }

          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}

        {LEGACY_ROUTE_REDIRECTS.map((redirect, index) => {
          if (index === LEGACY_ROUTE_REDIRECTS.length - 1) {
            redirectsRendered = true;
          }

          return redirect.queryParams ? (
            <RedirectWithQueryParams
              key={redirect.key}
              from={redirect.from}
              to={redirect.to}
              queryParams={redirect.queryParams}
            />
          ) : (
            <Redirect
              exact
              key={redirect.key}
              from={redirect.from}
              to={redirect.to}
            />
          );
        })}

        {routesRendered && redirectsRendered && (
          <Route
            component={() => (
              <main className="interior-layout">
                <div className="interior-layout__content">
                  <NoMatch />
                </div>
              </main>
            )}
          />
        )}
      </Switch>
    </SideNav>
  );
};

export default Home;
