import React from 'react';

// Customer table
const customerHeaderPlaceholder = [
  {
    displayValue: 'Account Name',
    key: 'name'
  },
  {
    displayValue: 'Industry',
    key: 'industry'
  },
  {
    displayValue: 'Segment',
    key: 'segment'
  },
  {
    displayValue: 'Pending Orders',
    key: 'pendingOrders'
  },
  {
    displayValue: 'Processed Orders',
    key: 'processedOrders'
  }
];

const customerBodyPlaceholder = {
  name: {
    value: '------------------'
  },
  industry: {
    value: '-----'
  },
  segment: {
    value: '-----------'
  }
};

// All orders table
const allOrdersHeaderPlaceholder = [
  {
    displayValue: 'Account Name',
    key: 'accountName'
  },
  {
    displayValue: 'Order Number',
    key: 'orderNumber'
  },
  {
    displayValue: 'Order Date',
    key: 'orderPlacedDate'
  },
  {
    displayValue: 'Order Status',
    key: 'orderStatus'
  },
  {
    displayValue: 'Shipments In Order',
    key: 'shipmentCount'
  }
];

const allOrdersBodyPlaceholder = {
  accountName: {
    value: '--------------------'
  },
  orderNumber: {
    value: '---------'
  },
  orderDate: {
    value: '---------'
  },
  orderStatus: {
    value: '---------'
  },
  shipmentsInOrder: {
    value: '---'
  }
};

// Orders by customer
const ordersByCustomerHeaderPlaceholder = [
  {
    displayValue: 'Shipment Date',
    key: 'shipmentDate'
  },
  {
    displayValue: 'Tracking Number',
    key: 'trackingNumber'
  },
  {
    displayValue: 'Delivery Status',
    key: 'deliveryStatus'
  },
  {
    displayValue: 'Carrier',
    key: 'carrier'
  },
  {
    displayValue: 'Expected Delivery Date',
    key: 'expectedDeliveryDate'
  }
];

const ordersByCustomerBodyPlaceholder = {
  shipmentDate: {
    value: '---------'
  },
  expectedDeliveryDate: {
    value: '---------'
  },
  deliveryStatus: {
    value: '---------'
  },
  trackingNumber: {
    value: '---------'
  }
};

/**
 * Returns an array of the provided bodyPlaceholder.
 *
 * @param numberOfRows
 * @param bodyPlaceholder
 */
const getTableBodyPlaceholder = (numberOfRows, bodyPlaceholder) =>
  Array(numberOfRows)
    .fill()
    .map(() => ({ ...bodyPlaceholder, _id: { value: Math.random() } }));

const UnableToRetrieveData = val => (
  <p>
    We could not load {val} at this time. Please try again in a few minutes.
  </p>
);

const orderDocumentsHeaderPlaceholder = [
  {
    key: 'multiselect'
  },
  {
    displayValue: 'Document Name',
    key: 'name',
    sortable: true
  },
  {
    key: 'shareButton'
  },
  {
    displayValue: 'Language',
    key: 'language',
    sortable: true
  },
  {
    displayValue: 'Document Type',
    key: 'documentType',
    sortable: true
  },
  {
    displayValue: 'File Type',
    key: 'fileType'
  },
  {
    displayValue: 'File Size',
    key: 'size',
    sortable: true
  }
];

const orderDocumentsBodyPlaceholder = {
  name: {
    value: '---------'
  },
  documentType: {
    value: '----'
  },
  fileType: {
    value: '----'
  },
  size: {
    value: '----'
  }
};

const generateUUIDV4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 || 0;
    const v = c === 'x' ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
};

export {
  getTableBodyPlaceholder,
  customerHeaderPlaceholder,
  customerBodyPlaceholder,
  allOrdersBodyPlaceholder,
  allOrdersHeaderPlaceholder,
  ordersByCustomerHeaderPlaceholder,
  ordersByCustomerBodyPlaceholder,
  UnableToRetrieveData,
  orderDocumentsHeaderPlaceholder,
  orderDocumentsBodyPlaceholder,
  generateUUIDV4
};
