/**
 * Gets the query parameters that a route requires from currentQueryParams
 * if they exist.
 *
 * @param {*} route
 */
const getRequiredPaginationParameters = (route, currentQueryParams) => {
  if (route && route.queryParameters) {
    return route.queryParameters.reduce((params, paramKey) => {
      if (currentQueryParams.get(paramKey)) {
        return params.concat(`${paramKey}=${currentQueryParams.get(paramKey)}`);
      }
      return params;
    }, '');
  }
  return null;
};

export default getRequiredPaginationParameters;
