import MyDashboard from '../../pages/MyDashboard';

const myDashboardRoutes = {
  key: 'dashboard',
  icon: 'grid',
  title: 'My Dashboard',
  path: '/dashboard',
  exact: true,
  giDataAttr: 'dashboardLink',
  component: MyDashboard,
  isPrivate: true
};

export default myDashboardRoutes;
