import { useState, useEffect } from 'react';

const useTabbableClass = id => {
  const [tabbableClass, setTabbableClass] = useState('');

  /**
   * Handles click events for tabbable buttons.
   *
   * If an tabbable button was clicked with a mouse, remove tabbable class.
   */
  const handleClickEventsForTabbableButtons = event => {
    if (event.detail !== 0) {
      setTabbableClass(false);
    }
  };

  /**
   * Handles tab events for tabbable buttons.
   *
   * If an tabbable button was selected using the keyboard, add tabbable class.
   */
  const handleKeyUpEventsForTabbableButtons = event => {
    if (event.keyCode === 9 || event.keyCode === 16 || event.keyCode === 13) {
      setTabbableClass('tabbable');
    } else {
      setTabbableClass('');
    }
  };

  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      // Bind the event listener
      element.addEventListener(
        'mousedown',
        handleClickEventsForTabbableButtons
      );
      element.addEventListener('keyup', handleKeyUpEventsForTabbableButtons);
    }

    return () => {
      if (element) {
        // Unbind the event listener on clean up
        element.removeEventListener(
          'mousedown',
          handleClickEventsForTabbableButtons
        );
        element.removeEventListener(
          'keyup',
          handleKeyUpEventsForTabbableButtons
        );
      }
    };
  });

  return tabbableClass;
};

export default useTabbableClass;
