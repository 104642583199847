import React, { useState } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { withRouter, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import OxCard from '../molecules/OxCard';
import OxSelect from '../tokens/forms/OxSelect';
import { isExternalUrl } from '../../helpers';
import OxInputGroup from '../tokens/forms/OxInputGroup';
import DashboardLink from '../molecules/DashboardLink';
import OxSearch from './OxSearch';
import '../../styles/pages/_dashboard.scss';
import OxCardInput from '../../propTypes/OxCardInput';
import { GET_ANNOUNCEMENTS_BY_USER } from '../../api/announcements/query';
import { ANNOUNCEMENT_MAP } from '../../helpers/constants';
import { logEvent } from '../../helpers/segmentLogger';

const Dashboard = ({
  history,
  searchOptions,
  links,
  cardInputPropsForLinks,
  children
}) => {
  Dashboard.propTypes = {
    // Note: First option is the default option
    searchOptions: PropTypes.arrayOf(
      PropTypes.shape({
        optionText: PropTypes.string,
        optionValue: PropTypes.string,
        placeholder: PropTypes.string.isRequired
      })
    ),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string
      })
    ),
    cardInputPropsForLinks: PropTypes.shape(OxCardInput)
  };

  const [searchType, setSearchType] = useState(
    searchOptions && searchOptions.length ? searchOptions[0].optionValue : null
  );

  const { loading, data } = useQuery(GET_ANNOUNCEMENTS_BY_USER);

  const hasAnnouncements = () => {
    return (
      !loading &&
      data &&
      data.announcementsByUser &&
      data.announcementsByUser.length > 0
    );
  };
  /**
   * Future funtionality to render announcments.
   * Add any announcement types and details to the ANNOUNCEMENT_MAP constant below to guarantee the type and title will display
   */
  const _renderAnnouncements = () => {
    if (hasAnnouncements()) {
      return (
        <section className="cx-home__announcements">
          <OxCard header={{ title: 'Announcements' }} contentSplit>
            {data.announcementsByUser.map(announcement => {
              const announcementTitle = ANNOUNCEMENT_MAP.get(announcement.type)
                ? ANNOUNCEMENT_MAP.get(announcement.type).title
                : '';

              let announcementHtml = (
                <div
                  key={announcement.id}
                  className="border-bottom announcement-card"
                >
                  <div className="condensed-font announcement-card__header">
                    <div className="announcement-card__header--heavy">
                      {announcementTitle}
                    </div>
                    <div>
                      {new Date(announcement.createdAt).toLocaleDateString()}
                    </div>
                  </div>
                  <p className="announcement-card__body">
                    {announcement.body}
                    <br />
                    {announcement.actionText ? (
                      <span className="announcement-card__action-text tabbable">
                        {announcement.actionText}
                      </span>
                    ) : null}
                  </p>
                </div>
              );

              if (announcement.action) {
                announcementHtml = isExternalUrl(announcement.action) ? (
                  <a
                    className="announcement-anchor"
                    href={announcement.action}
                    {...(announcement.newTab && { target: '_blank' })}
                    {...(announcement.downloadAction && {
                      download: true
                    })}
                  >
                    {announcementHtml}
                  </a>
                ) : (
                  <Link
                    className="announcement-anchor"
                    to={announcement.action}
                    {...(announcement.newTab && { target: '_blank' })}
                    {...(announcement.downloadAction && {
                      download: true
                    })}
                  >
                    {announcementHtml}
                  </Link>
                );
              }

              return <aside key={announcement.id}>{announcementHtml}</aside>;
            })}
          </OxCard>
        </section>
      );
    }
    return null;
  };

  const _getSearchInput = () => {
    return (
      <OxSearch
        searchType={searchType}
        searchPlaceholder={
          searchOptions.find(option => option.optionValue === searchType)
            .placeholder
        }
        history={history}
        name="customer-search"
        label="Search for a customer"
      />
    );
  };

  /**
   * Renders search bar if searchOptions were provided.
   */
  const _getSearchContainer = () => {
    return searchOptions ? (
      <div className="dashboard-search-container">
        {searchOptions.length > 1 ? (
          <OxInputGroup>
            <div className="ox-input-group__cell">
              <OxSelect
                ariaLabel="Search type select"
                name="searchType"
                options={searchOptions}
                required={false}
                floatLabel={false}
                handleKeyUp={event => setSearchType(event.target.value)}
                value={searchType}
              />
            </div>
            <div className="ox-input-group__cell--remainder">
              {_getSearchInput()}
            </div>
          </OxInputGroup>
        ) : (
          _getSearchInput()
        )}
      </div>
    ) : null;
  };

  const _renderLinks = () => {
    return (
      <OxCard
        {...(cardInputPropsForLinks && { ...cardInputPropsForLinks })}
        header={{ title: 'My Links' }}
        id="my-links"
      >
        {links.map((link, id) => (
          <DashboardLink
            id={slugify(`${id}-${link.title}`)}
            key={slugify(`${id}-${link.title}`)}
            isInternalLink={link.isInternalLink}
            icon={link.icon}
            localIcon={link.localIcon}
            headline={link.title}
            tagline={link.text}
            url={link.url}
            target={link.target}
            giDataAttr={`links-dashboard__${link.title}`}
            onClick={() => {
              logEvent({
                categoryName: 'Clicked Link',
                actionName: 'User clicked link',
                labelName: link.title
              });
            }}
          />
        ))}
      </OxCard>
    );
  };

  const _getCustomerCardClass = () => {
    return hasAnnouncements()
      ? 'cx-home__customers'
      : 'cx-home__customers--no-announcements';
  };

  return (
    <main className="cx-home">
      <div className="cx-home__container">
        <div className={_getCustomerCardClass()}>
          <OxCard
            header={{ title: 'My Dashboard' }}
            id="my-dashboard"
            contentSplit
          >
            {_getSearchContainer()}
            {children}
            {_renderLinks()}
          </OxCard>
        </div>
        {_renderAnnouncements()}
      </div>
    </main>
  );
};

export default withRouter(Dashboard);
