import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/components/_ox-required-indicator.scss';

class OxRequiredIndicator extends React.PureComponent {
  static propTypes = {
    required: PropTypes.bool
  };

  render() {
    const { required, children } = this.props;
    return required ? (
      <>
        {children}
        <span className="required-indicator"> *</span>
      </>
    ) : (
      children || null
    ); // Returns null when children is undefined.
  }
}

export default OxRequiredIndicator;
