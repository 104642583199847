import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import OxPill from '../molecules/OxPill';
import {
  GET_USER_TYPES,
  GET_FEATURES,
  GET_USER_ROLES
} from '../../api/constants/query';

const PermissionMgmtPills = ({
  selectedFeatures,
  selectedUserRoles,
  selectedUserTypes,
  hasOnlyOneUserType,
  hasOnlyOneUserRole,
  handleUserRoleChange,
  handleUserTypeChange,
  handleFeatureChange
}) => {
  PermissionMgmtPills.propTypes = {
    selectedFeatures: PropTypes.shape(new Map()),
    selectedUserRoles: PropTypes.shape(new Map()),
    selectedUserTypes: PropTypes.shape(new Map()),
    handleUserRoleChange: PropTypes.func,
    handleUserTypeChange: PropTypes.func,
    handleFeatureChange: PropTypes.func
  };

  const { data: dataTypes, loading: typesLoading } = useQuery(GET_USER_TYPES);
  const { data: dataFeatures, loading: featuresLoading } = useQuery(
    GET_FEATURES
  );
  const { data: dataRoles, loading: rolesLoading } = useQuery(GET_USER_ROLES);

  const _addOrRemoveItem = (item, selectedItemsMap) => {
    if (selectedItemsMap.has(item.id)) {
      selectedItemsMap.delete(item.id);
    } else {
      selectedItemsMap.set(item.id, item.name);
    }
    return selectedItemsMap;
  };

  const _getNextMap = (item, currentMap, canOnlyHaveOneItem) => {
    if (canOnlyHaveOneItem && currentMap.has(item.id)) {
      return new Map();
    } if (canOnlyHaveOneItem && !currentMap.has(item.id)) {
      return new Map(_addOrRemoveItem(item, new Map()));
    }
    return new Map(_addOrRemoveItem(item, currentMap));
  };

  const _onCheckbox = item => {
    if (item.__typename === 'UserType') {
      handleUserTypeChange(
        _getNextMap(item, selectedUserTypes, hasOnlyOneUserType)
      );
    } else if (item.__typename === 'UserRole') {
      handleUserRoleChange(
        _getNextMap(item, selectedUserRoles, hasOnlyOneUserRole)
      );
    } else {
      handleFeatureChange(_getNextMap(item, selectedFeatures, false));
    }
  };

  const _isPillSelected = item => {
    return !!(
      selectedFeatures.get(item.id) ||
      selectedUserTypes.get(item.id) ||
      selectedUserRoles.get(item.id)
    );
  };

  const _getCheckboxItem = item => (
    <div className="badge" key={item.id}>
      <OxPill
        id={`oxPill-${item.id}`}
        text={item.name}
        clickFunc={() => _onCheckbox(item)}
        isSelected={_isPillSelected(item)}
        withIcon={{
          icon: _isPillSelected(item) ? 'x' : 'plus'
        }}
      />
    </div>
  );

  const _getPills = (options, label, selectedOptionsMap) => {
    return (
      <div className="units-row">
        <label className="unit-100">{label}</label>
        {options
          ? options
              .filter(x => selectedOptionsMap.get(x.id))
              .map(item => _getCheckboxItem(item))
          : null}
        {options
          ? options
              .filter(x => !selectedOptionsMap.get(x.id))
              .map(item => _getCheckboxItem(item))
          : null}
      </div>
    );
  };

  return !typesLoading && !rolesLoading && !featuresLoading ? (
    <fieldset className="ox-fieldset">
      {dataTypes && dataTypes.userTypes
        ? _getPills(dataTypes.userTypes, 'User Type', selectedUserTypes)
        : null}
      {dataRoles && dataRoles.userRoles
        ? _getPills(dataRoles.userRoles, 'User Roles', selectedUserRoles)
        : null}
      {dataFeatures && dataFeatures.features
        ? _getPills(
            dataFeatures.features,
            'Additional Features',
            selectedFeatures
          )
        : null}
    </fieldset>
  ) : null;
};

export default PermissionMgmtPills;
