import PropTypes from 'prop-types';

const StringValue = {
  value: PropTypes.string
};

const NumberValue = {
  value: PropTypes.number
};

export { StringValue, NumberValue };
