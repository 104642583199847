import React from 'react';
import '../../styles/components/_ox-nav-item.scss';
import useTabbableClass from '../../hooks/useTabbableClass';

const OxTabbable = ({ children }) => {
  const ID = `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
  const tabbable = useTabbableClass(ID);
  const tabbableClass = () => tabbable || '';
  return React.cloneElement(children, {
    className: `${children.props.className} ${tabbableClass()}`,
    id: `${ID}`
  });
};
export default OxTabbable;
