import { useEffect } from 'react';
import { useParams } from 'react-router';
import { logEvent } from '../helpers/segmentLogger';

const getErrorMessage = dataType => `An error occurred fetching ${dataType}`;
const getNotFoundMessage = dataType => `No ${dataType} were found`;

const getSegmentParameter = (giDataAttr, urlParams) => ({
  giDataAttr,
  ...(urlParams && {
    eventData: urlParams
  })
});

const useSegment = props => {
  const { error, data, dataType, giDataAttr } = props;
  const urlParams = useParams();

  useEffect(() => {
    if (error) {
      logEvent({
        ...getSegmentParameter(giDataAttr, urlParams),
        eventName: getErrorMessage(dataType)
      });
    } else if (data && data.length === 0) {
      logEvent({
        ...getSegmentParameter(giDataAttr, urlParams),
        eventName: getNotFoundMessage(dataType)
      });
    }
  }, [data, dataType, error, giDataAttr, urlParams]);
};

export default useSegment;
