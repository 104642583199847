import Interior from '../../pages/Interior';
import CaseCreator from '../../pages/CaseCreator';
import CaseCreatorThanks from '../../pages/CaseCreatorThanks';
import { caseCreator } from '../../helpers/authHelpers';
import { isExternalUser, isInternal } from '../../helpers';

const caseCreatorRoutes = {
  key: 'web-to-case',
  icon: 'briefcase',
  title: 'Case Creator',
  path: '/case-creator',
  giDataAttr: 'webToCase',
  isPrivate: true,
  component: Interior,
  routes: [
    {
      exact: true,
      title: 'Case Creator',
      key: 'case-creator',
      path: '/case-creator',
      component: CaseCreator,
      isPrivate: true,
      requiredPermission: [caseCreator],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    },
    {
      exact: true,
      key: 'case-creator-thanks',
      path: '/case-creator/thanks',
      component: CaseCreatorThanks,
      hideInInteriorNav: true,
      isPrivate: true,
      requiredPermissions: [caseCreator],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    }
  ],
  requiredPermissions: [caseCreator],
  authFunctions: [isInternal],
  shouldGoToNoMatchIfNoAccess: [isExternalUser]
};

export default caseCreatorRoutes;
