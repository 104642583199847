import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import OxCard from '../components/molecules/OxCard';
import OxTable from '../components/molecules/OxTable';
import { getTableBodyPlaceholder } from '../helpers/placeholderHelpers';
import { GET_USERS } from '../api/user/query';
import {
  LOADING_TITLE,
  LOADING_SUBTITLE,
  USER_ORDER_BY_INPUT
} from '../helpers/constants';
import OxLoaderOverlay from '../components/tokens/OxLoaderOverlay';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import '../styles/components/_admin.scss';
import OxButton from '../components/molecules/OxButton';

const usersHeaderPlaceholder = [
  {
    displayValue: 'Name',
    key: 'name'
  },
  {
    displayValue: 'Email',
    key: 'email'
  },
  {
    displayValue: 'Active',
    key: 'active'
  },
  {
    displayValue: 'Edit',
    key: 'edit'
  }
];

const usersBodyPlaceholder = {
  name: {
    value: '---------'
  },
  email: {
    value: '----'
  },
  expDate: {
    value: '----'
  },
  active: {
    value: '----'
  },
  edit: {
    value: <FeatherIcon icon="edit" />
  }
};

const Users = ({ route }) => {
  const queryParams = new URLSearchParams(useLocation().search);

  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const { loading, data } = useQuery(GET_USERS, {
    variables: {
      orderBy: USER_ORDER_BY_INPUT.updatedAt_DESC
    }
  });

  const getAlert = () => {
    if (
      queryParams.get('create-success') === 'true' ||
      queryParams.get('edit-success') === 'true'
    ) {
      const title =
        queryParams.get('create-success') === 'true' ? 'Created' : 'Edited';
      return (
        <OxSoftAlert
          title={`A User Was Successfully ${title}`}
          icon="check"
          alertType="success"
          giDataAttr={`${title.toLowerCase()}-user__success`}
          isOutlined
        />
      );
    }

    return null;
  };

  const transformUsersForTable = users => {
    return users.map(a => ({
      _id: a.id,
      name: { value: a.name },
      email: { value: a.email },
      active: { value: a.active ? 'Active' : 'Inactive' },
      edit: {
        url: {
          value: `/mgmt/user/${a.id}/edit`
        },
        iconName: {
          value: 'edit'
        }
      }
    }));
  };

  useEffect(() => {
    // Set whenever page changes
    const isApiUsersPage = route.path.includes('api-users');

    // Filter users based on type
    if (!loading && data && data.users && data.users.length) {
      const filteredUsers = data.users.filter(u => {
        return isApiUsersPage
          ? u.userType.name === 'api'
          : u.userType.name !== 'api';
      });
      setUsersToDisplay(filteredUsers);
    }
  }, [route.path, data, loading]);

  return (
    <OxCard
      header={{
        title: route.title,
        buttons: [
          () => (
            <OxButton
              isSquare
              size="default"
              type="primary"
              element="a"
              href="/mgmt/user/create"
              text="Add User"
            />
          )
        ]
      }}
      id="users"
    >
      {getAlert()}
      {loading || (data && usersToDisplay && usersToDisplay.length) ? (
        <OxLoaderOverlay
          isLoading={loading}
          title={LOADING_TITLE}
          subtitle={LOADING_SUBTITLE}
          giDataAttr="users__loading-overlay"
        >
          <OxTable
            noAnalytics
            giDataAttr="users__table"
            data={{
              headers: usersHeaderPlaceholder,
              data: loading
                ? getTableBodyPlaceholder(10, usersBodyPlaceholder)
                : transformUsersForTable(usersToDisplay)
            }}
          />
        </OxLoaderOverlay>
      ) : (
        <OxSoftAlert
          alertType="info"
          icon="none"
          giDataAttr="user__no-user-alert"
          eventName="noData"
        >
          <p>There are no users at the moment.</p>
        </OxSoftAlert>
      )}
    </OxCard>
  );
};

export default Users;
