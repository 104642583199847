import { isExternalUser, isInternal } from '../../helpers';
import AddApprovedEmail from '../../pages/AddApprovedEmail';
import ApprovedEmails from '../../pages/ApprovedEmails';
import Interior from '../../pages/Interior';

const approvedEmailsRoutes = {
  key: 'approved-emails-interior',
  icon: 'briefcase',
  title: 'Approved Emails',
  path: '/approvedEmails',
  isPrivate: true,
  component: Interior,
  hideInSideNav: true,
  routes: [
    {
      exact: true,
      title: 'Approved Users',
      key: 'approved-users',
      path: '/approvedEmails',
      component: ApprovedEmails,
      isPrivate: true,
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
    },
    {
      exact: true,
      title: "Add Approved User",
      key: "add-approved-user",
      path: "/approvedEmails/add",
      component: AddApprovedEmail,
      isPrivate: true,
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      hideInInteriorNav: true,
    }
  ],
  authFunctions: [isInternal],
  shouldGoToNoMatchIfNoAccess: [isExternalUser]
};

export default approvedEmailsRoutes;
