import React from 'react';
import FeatherIcon from 'feather-icons-react';
import useConfirmationModal from '../../hooks/useConfirmationModal';
import useTabbableClass from '../../hooks/useTabbableClass';

const AdminDeleteButton = ({ id, handleDeleteEntity, entityName }) => {
  const { showConfirmation, hideConfirmation } = useConfirmationModal();
  const LOWER_CASE_ENTITY_NAME = entityName.toLowerCase();
  const BUTTON_ID = `ox-table__delete-${LOWER_CASE_ENTITY_NAME}`;
  // Prevent button from outlining when user selects button with mous
  const tabbableClass = useTabbableClass(BUTTON_ID);

  const _openModal = onConfirmationFn => {
    showConfirmation({
      header: `Delete ${entityName}`,
      message: `Are you sure you want to delete this ${LOWER_CASE_ENTITY_NAME}? This action cannot be undone.`,
      icon: 'alert-triangle',
      giDataAttr: `ox-model__delete-${LOWER_CASE_ENTITY_NAME}`,
      buttonProps: [
        {
          key: 'ox-modal__btn-primary',
          id: 'ox-modal__btn-primary',
          text: 'Yes, Delete',
          giDataAttr: 'ox-modal__btn-primary',
          type: 'primary',
          element: 'button',
          clickFunc: () => {
            onConfirmationFn();
            hideConfirmation();
          }
        },
        {
          key: 'ox-modal__btn-secondary',
          id: 'ox-modal__btn-secondary',
          giDataAttr: 'ox-modal__btn-secondary',
          text: 'Exit Without Deleting',
          type: 'secondary',
          element: 'button',
          clickFunc: hideConfirmation
        }
      ]
    });
  };

  return (
    <button
      id={BUTTON_ID}
      className={`btn-icon ${tabbableClass} ox-table__delete-${LOWER_CASE_ENTITY_NAME}`}
      type="button"
      onClick={() => _openModal(() => handleDeleteEntity(id))}
      data-gi={`ox-table__delete-${LOWER_CASE_ENTITY_NAME}`}
      aria-label={`Delete ${LOWER_CASE_ENTITY_NAME}`}
    >
      <FeatherIcon icon="trash-2" />
    </button>
  );
};

export default AdminDeleteButton;
