import React from 'react';
import { useQuery } from '@apollo/client';
import '../styles/pages/_interior.scss';
import { ACCOUNTS_FOR_CUSTOMER_PAGES } from '../api/account/query';
import { ACCOUNT_TYPE_OPTIONS } from '../helpers/constants';
import Customers from '../components/partials/Customers';
import OxSoftAlert from '../components/molecules/OxSoftAlert';

const AllCustomers = ({ history }) => {
  const { loading, error, data } = useQuery(ACCOUNTS_FOR_CUSTOMER_PAGES, {
    variables: { where: { accountType: ACCOUNT_TYPE_OPTIONS.ALL } },
    errorPolicy: 'all'
  });

  return (
    <Customers
      loading={loading}
      title="All Accounts"
      hasNoCustomers={() => {
        return (
          <OxSoftAlert
            alertType="info"
            icon="none"
            giDataAttr="all-customers__no-data"
            eventName="noData"
          >
            <p>We could not find any customers at this time.</p>
          </OxSoftAlert>
        );
      }}
      showSearch
      error={error}
      data={data}
      history={history}
      giDataAttr="all-customers"
    />
  );
};

export default AllCustomers;
