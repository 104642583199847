import React from 'react';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import '../../../styles/components/_user-input.scss';
import OxRequiredIndicator from './OxRequiredIndicator';
import { OxTooltip } from '../../molecules/OxTooltip';

const OxSelect = ({
  name,
  options,
  value,
  placeholder,
  handleKeyUp,
  label,
  floatLabel,
  giDataAttr,
  noAnalytics,
  required,
  tooltipContent,
  tooltipScrollable,
  helperClass
}) => {
  OxSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        optionText: PropTypes.string.isRequired,
        optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired
      })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    handleKeyUp: PropTypes.func,
    label: PropTypes.string,
    floatLabel: PropTypes.bool,
    giDataAttr: PropTypes.string,
    noAnalytics: PropTypes.bool,
    required: PropTypes.bool,
    tooltipContent: PropTypes.string,
    tooltipScrollable: PropTypes.bool,
    helperClass: PropTypes.string
  };

  const _getLabelClass = () => {
    return floatLabel ? 'float-label-group' : 'input-label-group';
  };

  return (
    <div className={_getLabelClass()}>
      <select
        className={helperClass}
        name={name}
        id={name}
        value={value}
        onChange={handleKeyUp}
        onKeyUp={event => {
          if (event === 'Enter') {
            handleKeyUp(event);
          }
        }}
        data-gi={giDataAttr}
        heap-ignore={noAnalytics ? 'true' : null}
        data-hj-suppress={noAnalytics ? 'true' : null}
        required={required ? true : null}
        defaultValue={placeholder}
      >
        {placeholder ? <option value="">{placeholder}</option> : null}
        {options.map(option => (
          <option value={option.optionValue} key={option.optionValue}>
            {option.optionText}
          </option>
        ))}
      </select>
      <label htmlFor={name} className={`${_getLabelClass()}__label`}>
        <div className="width-100">
          <OxRequiredIndicator required={!!required}>
            {label}
          </OxRequiredIndicator>
        </div>
        {tooltipContent ? (
          <OxTooltip
            content={tooltipContent}
            tooltipScrollable={tooltipScrollable}
          >
            <FeatherIcon icon="info" className="tooltip-icon" />
          </OxTooltip>
        ) : null}
      </label>
    </div>
  );
};

export default OxSelect;
