const salesOrderPermission = 'Sales Order';
const resources = 'Resources';
const accounts = 'Accounts';
const caseCreator = 'Case Creator';
const salesLinksPermission = 'Sales Links';
const admin = 'Admin';
const orders = 'Orders';

export {
  resources,
  accounts,
  salesOrderPermission,
  salesLinksPermission,
  caseCreator,
  admin,
  orders
};
