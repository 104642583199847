import React from 'react';
import { Link } from 'react-router-dom';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxCard from '../components/molecules/OxCard';

const NoMatch = () => {
  return (
    <OxCard header={{ title: 'Page Not Found' }} id="no-match_oxcard">
      <OxSoftAlert
        alertType="info"
        eventName="noMatch"
        id="no-match-alert"
        giDataAttr="no-match__alert-title"
        title="404 - We Couldn't Find A Page Here"
      >
        <p>
          Please check the url for mistakes, or&nbsp;
          <Link className="interior-layout__internal-link" to="/">
            go back to the dashboard
          </Link>
          .
        </p>
      </OxSoftAlert>
    </OxCard>
  );
};

export default NoMatch;
