import React from 'react';
import { useQuery } from '@apollo/client';
import {
  ACCOUNT_TYPE_OPTIONS,
  NO_MY_CUSTOMERS_ALERT_TITLE,
  NO_MY_CUSTOMERS_ALERT_MESSAGE
} from '../helpers/constants';
import '../styles/pages/_interior.scss';
import { ACCOUNTS_FOR_CUSTOMER_PAGES } from '../api/account/query';
import Customers from '../components/partials/Customers';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import { isInternal } from '../helpers';
import { GET_USER_INFO } from '../api/user/query';

const MyCustomers = ({ history }) => {
  const { loading, error, data } = useQuery(ACCOUNTS_FOR_CUSTOMER_PAGES, {
    variables: {
      where: { accountType: ACCOUNT_TYPE_OPTIONS.CURRENT_USER }
    },
    errorPolicy: 'all'
  });

  const { data: userData } = useQuery(GET_USER_INFO);

  return (
    <Customers
      loading={loading}
      title="My Accounts"
      hasNoCustomers={() => {
        return (
          <OxSoftAlert
            alertType="info"
            icon="alert-circle"
            title={NO_MY_CUSTOMERS_ALERT_TITLE}
            isOutlined
            giDataAttr="my-customers__no-data"
            eventName="noData"
          >
            {NO_MY_CUSTOMERS_ALERT_MESSAGE}
          </OxSoftAlert>
        );
      }}
      showSearch={isInternal(userData)}
      error={error}
      data={data}
      history={history}
      giDataAttr="my-customers"
    />
  );
};

export default MyCustomers;
