import React from 'react';
import FeatherIcon from 'feather-icons-react';
import '../../styles/components/_ox-icon.scss';

const OxIcon = ({ icon, badgeText, helperClass, clickFunc, tabIndex }) => {
  return (
    <div
      className={`ox-icon ${helperClass || ''}`}
      onClick={() => clickFunc()}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          clickFunc();
        }
      }}
      role="button"
      tabIndex={tabIndex}
    >
      <FeatherIcon icon={icon} />
      {badgeText && <span className="badge">{badgeText}</span>}
    </div>
  );
};

export default OxIcon;
