import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AppRoute from '../propTypes/AppRoute';

/**
 * Always ability to create nested routes.
 * Example retrieved from documentation:
 * https://reacttraining.com/react-router/web/example/route-config
 */
const RouteWithSubRoutes = route => {
  RouteWithSubRoutes.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({ ...AppRoute }))
  };

  return route.isPrivate ? (
    <PrivateRoute
      route={route}
      exact={route.exact}
      path={route.path}
      component={route.component}
      routes={route.routes}
      requiredPermissions={route.requiredPermissions}
    />
  ) : (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
};

export default RouteWithSubRoutes;
