import React from 'react';
import { Link } from 'react-router-dom';
import { RiBuildingLine } from 'react-icons/ri';

const LOADING_TITLE = 'Loading...';
const LOADING_SUBTITLE = 'Please wait while your data is loading';
const SEARCHING_TITLE = 'Gathering your results...';
const ERROR_TITLE = 'Sorry, Something Went Wrong';
const SEARCH_CUSTOMER_PLACEHOLDER = 'Search by Email or Account ID';
const SEARCH_ACCOUNT_PLACEHOLDER = 'Search For An Account';
const SEARCH_ORDER_PLACEHOLDER = 'Search by Order ID or PO Number';
const SEARCH_ORDER_PLACEHOLDER_SHORT = 'Search For An Order';
const ACCOUNT_TYPE_OPTIONS = {
  ALL: 'ALL',
  CURRENT_USER: 'CURRENT_USER'
};

const OX_MODAL_ID = 'ox-modal-id';
const MAIN_CONTENT = 'main-content';
const OX_MODAL_FIRST_ELEMENT = 'ox-modal__close-btn';

const NO_MY_CUSTOMERS_ALERT_TITLE =
  'We could not find any accounts assigned to you';

const NO_MY_CUSTOMERS_ALERT_MESSAGE = (
  <p>
    This is likely due to you having no assigned accounts in Dynamics.&nbsp;
    <Link className="tabbable" to="/customers/all-customers">
      Click here
    </Link>
    &nbsp;to view all customers or search for an account above.
  </p>
);
const TO_PREVIOUS_PAGE = 'Back To Previous';

const DASHBOARD_LINKS = new Map([
  [
    'CalcLab',
    {
      icon: 'cpu',
      title: 'CalcLab',
      text: 'Collaborate seamlessly and effectively.',
      url: 'https://calclab.intralox.com/',
      target: '_blank'
    }
  ],
  [
    'Dynamics',
    {
      icon: 'external-link',
      title: 'Dynamics',
      text: 'Jump to Microsoft Dynamics',
      url: 'https://intraloxllc0.crm.dynamics.com/',
      target: '_blank'
    }
  ],
  [
    'CaseCreator',
    {
      icon: 'briefcase',
      title: 'Case Creator',
      text: 'Create a Salesforce case.',
      url: '/web-to-case/case-creator',
      isInternalLink: true
    }
  ],
  [
    'LaitramIntranet',
    {
      icon: 'external-link',
      title: 'Laitram Intranet',
      text: 'Access Laitram resource links.',
      url: 'http://enterprise-portal',
      target: '_blank'
    }
  ],
  [
    'IntraloxIntranet',
    {
      icon: 'external-link',
      title: 'Intralox Intranet',
      text: 'Access Intralox resource links.',
      url: 'http://intralox-portal',
      target: '_blank'
    }
  ],
  [
    'MicrosoftOffice',
    {
      icon: 'paperclip',
      title: 'Microsoft Office',
      text: 'Microsoft products all in one place.',
      url: 'https://www.office.com/',
      target: '_blank'
    }
  ],
  [
    'Support',
    {
      icon: 'life-buoy',
      title: 'Support',
      text: 'Contact us for help.',
      url: '/support',
      isInternalLink: true
    }
  ],
  [
    'MyOrders',
    {
      icon: 'list',
      title: 'My Orders ',
      text: 'View all my customer orders.',
      url: '/orders',
      isInternalLink: true
    }
  ],
  [
    'IntraloxWebsite',
    {
      icon: 'list',
      title: 'Intralox',
      text: 'Visit the Intralox web site.',
      url: 'https://www.intralox.com/',
      target: '_blank'
    }
  ],
  [
    'IntraloxLearn',
    {
      icon: 'book',
      title: 'Intralox Learn',
      text: 'Visit the Intralox Learn web portals.',
      url: 'https://learn.intralox.com/',
      target: '_blank'
    }
  ],
  [
    'Resources',
    {
      icon: 'book',
      title: 'Resources',
      text: 'General Intralox engineering documents and a way to contact us.',
      url: '/resources/documents'
    }
  ]
]);

const ORDER_PAGINATION_OPTIONS = [
  { optionText: '5', optionValue: 5 },
  { optionText: '10', optionValue: 10 },
  { optionText: '25', optionValue: 25 },
  { optionText: '50', optionValue: 50 }
];

const ANNOUNCEMENT_MAP = new Map([
  [
    'DOCUMENT_INFORMATION',
    {
      title: 'DOCUMENT INFORMATION',
      colorClass: 'blue' // adding for future for color coding
    }
  ],
  [
    'ORDER_INFORMATION',
    {
      title: 'ORDER INFORMATION',
      colorClass: 'orange'
    }
  ],
  [
    'RELEASE_UPDATE',
    {
      title: 'RELEASE UPDATES',
      colorClass: 'red'
    }
  ],
  [
    'PRODUCT_ANNOUNCEMENT',
    {
      title: 'PRODUCT ANNOUNCEMENT',
      colorClass: 'navy'
    }
  ],
  [
    'USER_ROLE',
    {
      title: 'USER ROLE',
      colorClass: 'blue'
    }
  ]
]);

const USER_ORDER_BY_INPUT = {
  updatedAt_ASC: 'updatedAt_ASC',
  updatedAt_DESC: 'updatedAt_DESC'
};

const MAX_FILE_UPLOAD_SIZE = 10e6; // 10000000 bytes, or 10mb

const ACCEPTED_UPLOAD_TYPES = [
  {
    extension: 'jpg',
    mimeType: 'image/jpeg'
  },
  {
    extension: 'jpeg',
    mimeType: 'image/jpeg'
  },
  {
    extension: 'png',
    mimeType: 'image/png'
  },
  {
    extension: 'pdf',
    mimeType: 'application/pdf'
  }
];

const EMAIL_SHARE_LANGUAGE_OPTIONS = [
  {
    optionText: 'Dansk',
    optionValue: 'da',
    optionLongName: 'DANISH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Vedhæftede de ønskede dokumenter for Intralox Salgsordre ${salesOrderNum} (Indkøbsordre ${purchaseOrderId}). Skulle der være yderligere spørgsmål er I velkommen til at svare på denne e-mail, som så vil bliver videresendt til rette Intralox afdeling.`
        : 'Vedhæftet finder du de ønskede dokumenter.  Skulle der være yderligere spørgsmål er I velkommen til at svare på denne e-mail, som så vil bliver videresendt til rette Intralox afdeling.';
    }
  },
  {
    optionText: 'Nederlands',
    optionValue: 'nl',
    optionLongName: 'DUTCH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `In de bijlage vindt u de gevraagde documenten voor Intralox verkooporder ${salesOrderNum} (inkooporder ${purchaseOrderId}). Voor vragen kunt u antwoorden op deze email en zal deze worden doorgestuurd naar het juiste Intralox team.`
        : 'Bijgevoegd vindt u uw gevraagde documenten. Voor vragen kunt u antwoorden op deze email en zal deze worden doorgestuurd naar het juiste Intralox team.';
    }
  },
  {
    optionText: 'American English',
    optionValue: 'en',
    optionLongName: 'ENGLISH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Attached you'll find your requested documents for Intralox Sales Order ${salesOrderNum} (Purchase Order ${purchaseOrderId}). If you have questions, you may reply to this email and it will be forwarded to the proper Intralox team.`
        : "Attached you'll find your requested documents.  If you have questions, you may reply to this email and it will be forwarded to the proper Intralox team.";
    }
  },
  {
    optionText: 'Français',
    optionValue: 'fr',
    optionLongName: 'FRENCH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Vous trouverez ci-joint les documents demandés pour le bon de commande Intralox ${salesOrderNum} (bon de commande ${purchaseOrderId}). En cas de questions veuillez répondre à cet émail. Il sera transmis à l’équipe Intralox appropriée.`
        : 'Vous trouverez ci-joint vos documents demandés. En cas de questions veuillez répondre à cet émail. Il sera transmis à l’équipe Intralox appropriée.';
    }
  },
  {
    optionText: 'Deutsch',
    optionValue: 'de',
    optionLongName: 'GERMAN',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Anbei sind Ihre angeforderten Dokumente für den Intralox-Auftrag ${salesOrderNum} (Bestellung ${purchaseOrderId}). Sollten Sie Fragen haben, können Sie gerne auf diese Email antworten.`
        : 'Anbei finden Sie die angefragten Dokumente. Sollten Sie Fragen haben, können Sie gerne auf diese Email antworten.';
    }
  },
  {
    optionText: 'Italiano',
    optionValue: 'it',
    optionLongName: 'ITALIAN',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `In allegato troverai i documenti richiesti per l'ordine di vendita Intralox ${salesOrderNum} (ordine di acquisto ${purchaseOrderId}). Per qualsiasi domanda, può rispondere a questa e-mail che verrà inoltrata al Dipartimento Intralox appropriato`
        : 'In allegato troverai i documenti richiesti. Per qualsiasi domanda, può rispondere a questa e-mail che verrà inoltrata al Dipartimento Intralox appropriato.';
    }
  },
  {
    optionText: 'Polski',
    optionValue: 'pl',
    optionLongName: 'POLISH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `W załączeniu znajdują się wymagane dokumenty dotyczące zamówienia sprzedaży firmy Intralox ${salesOrderNum} (zamówienie ${purchaseOrderId}). W razie jakichkolwiek pytań prosimy o e-maila na ten adres. Zostanie on przesłany do odpowiedniego działu Intralox.`
        : 'W załączeniu znajdziesz wymagane dokumenty. W razie jakichkolwiek pytań prosimy o e-maila na ten adres. Zostanie on przesłany do odpowiedniego działu Intralox.';
    }
  },
  {
    optionText: 'Español',
    optionValue: 'es',
    optionLongName: 'SPANISH AMERICAS',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Adjunto encontrará los documentos solicitados para el pedido de venta ${salesOrderNum} de Intralox (pedido de compra ${purchaseOrderId}). Si tiene alguna pregunta, puede responder a este correo electrónico que se reenviará al Departamento de Intralox que corresponda.`
        : 'Adjunto encontrará los documentos solicitados. Si tiene alguna pregunta, puede responder a este correo electrónico que se reenviará al Departamento de Intralox que corresponda.';
    }
  },
  {
    optionText: 'Svenska',
    optionValue: 'sv',
    optionLongName: 'SWEDISH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Bifogat hittar du dina begärda dokument för Intralox försäljningsorder ${salesOrderNum} (inköpsorder ${purchaseOrderId}). Om du har några frågor vänligen svara på detta mail så skickas det till Intralox Kundservice.`
        : 'Bifogat hittar du dina begärda dokument. Om du har några frågor vänligen svara på detta mail så skickas det till Intralox Kundservice.';
    }
  },
  {
    optionText: 'Türkçe',
    optionValue: 'tr',
    optionLongName: 'TURKISH',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Ekte Intralox Satış Siparişi ${salesOrderNum} (Satın Alma Siparişi ${purchaseOrderId}) için talep ettiğiniz belgeleri bulacaksınız. Herhangi bir sorunuz olduğunda bu maili yanıtlayabilirsiniz. İlgili departmanımıza yanıtınız gönderilecektir.`
        : 'Ekte, talep ettiğiniz belgeleri bulacaksınız. Herhangi bir sorunuz olduğunda bu maili yanıtlayabilirsiniz. İlgili departmanımıza yanıtınız gönderilecektir.';
    }
  },
  {
    optionText: 'Portuguese',
    optionValue: 'pt',
    optionLongName: 'PORTUGUESE',
    getMessageText: (purchaseOrderId, salesOrderNum) => {
      return purchaseOrderId && salesOrderNum
        ? `Em anexo, você encontrará os documentos solicitados para o pedido de venda ${salesOrderNum} da Intralox (pedido de compra ${purchaseOrderId}). Se você tiver dúvidas, responda a este e-mail e ele será encaminhado para a equipe adequada da Intralox.`
        : 'Em anexo encontrará os documentos solicitados. Se você tiver dúvidas, responda a este e-mail e ele será encaminhado para a equipe adequada da Intralox.';
    }
  }
];

const LANGUAGE_CODE_ARRAY = [
  ['DANISH', 'da'],
  ['DUTCH', 'nl'],
  ['ENGLISH', 'en'],
  ['BRITISH ENGLISH', 'en'],
  ['FRENCH', 'fr'],
  ['GERMAN', 'de'],
  ['ITALIAN', 'it'],
  ['POLISH', 'pl'],
  ['SPANISH AMERICAS', 'es'],
  ['SPANISH EUROPE', 'es'],
  ['SWEDISH', 'sv'],
  ['TURKISH', 'tr'],
  ['CHINESE', 'zh'],
  ['PORTUGUESE', 'pt'],
  ['THAI', 'th'],
  ['JAPANESE', 'ja']
];

const LONG_LANGUAGE_CODE_MAP = new Map(LANGUAGE_CODE_ARRAY);

/**
 * Used to redirect users to the new routes for old links, in the event that they
 * have old links saved, or sent to them
 */
const LEGACY_ROUTE_REDIRECTS = [
  {
    key: 'dashboard',
    from: '/',
    to: '/dashboard'
  },
  {
    key: 'accounts',
    from: '/customers/my-customers',
    to: '/accounts'
  },
  {
    key: 'accounts-all',
    from: '/customers/all-customers',
    to: '/accounts/all'
  },
  {
    key: 'accounts-search',
    from: '/customers/:searchValue/search-results',
    to: '/accounts/details/:searchValue'
  },
  {
    key: 'case-creator',
    from: '/web-to-case/case-creator',
    to: '/case-creator'
  },
  {
    key: 'case-creator-thanks',
    from: '/web-to-case/thanks',
    to: '/case-creator/thanks'
  },
  {
    key: 'orders',
    from: '/orders/all-orders',
    to: '/my-orders'
  },
  {
    key: 'customer-orders',
    from: '/orders/all-orders',
    to: '/my-orders'
  },
  {
    key: 'open-orders',
    from: '/orders/open-orders',
    to: '/my-orders'
  },
  {
    key: 'orders-search',
    from: '/orders/:searchValue/search-results',
    to: '/my-orders/details/:searchValue'
  },
  {
    key: 'orders-by-customer',
    queryParams: ['id'],
    from: '/orders/customer-orders',
    to: '/my-orders/account/:id'
  },
  {
    key: 'order-documents',
    from: '/orders/:accountId/:orderNumber/documents',
    to: '/my-orders/details/documents/:orderNumber/:accountId'
  },
  {
    key: 'login',
    from: '/login',
    to: '/dashboard'
  }
];

/**
 * If an order contains items with one of these material codes,
 * then we should attach a REACH compliance letter to it
 */
const MATERIAL_CODES_WITH_REACH_LETTER = [
  '31002',
  '31040',
  '31070',
  '31100',
  '31200',
  '31300',
  '31850',
  '31920',
  '31960',
  '32310',
  '32500',
  '33103',
  '33480',
  '33490',
  '33600',
  '33810',
  '33820',
  '33850',
  '33870',
  '33890',
  '33910',
  '33920',
  '33950',
  '33970',
  '33980',
  '34100',
  '34200',
  '34400',
  '34410',
  '34525',
  '34540',
  '34580',
  '34595',
  '34600',
  '34700',
  '34750',
  '34800',
  '35000',
  '35011',
  '35020',
  '35030',
  '35040',
  '35080',
  '35100',
  '35120',
  '35130',
  '35140',
  '35170',
  '35180',
  '35190',
  '35201',
  '35218',
  '35221',
  '35222',
  '35260',
  '35270',
  '35280',
  '35290',
  '35300',
  '35460',
  '35470',
  '35490',
  '35495',
  '35540',
  '35560',
  '35570',
  '35580',
  '35585',
  '35595',
  '35600',
  '35605',
  '35610',
  '35630',
  '35670',
  '35680',
  '35690',
  '35695',
  '35720',
  '35730',
  '35740',
  '35750',
  '35810',
  '35820',
  '35845',
  '35880',
  '35890',
  '35900',
  '35930',
  '35940',
  '35950',
  '35960',
  '35970',
  '35980',
  '35990',
  '35995',
  '36500',
  '36520',
  '36550',
  '36560',
  '36570',
  '37000',
  '37060',
  '37070',
  '37090',
  '37110',
  '37120',
  '37170',
  '37180',
  '37190',
  '37200',
  '37210',
  '37230',
  '37430',
  '37440',
  '37450',
  '37460',
  '37530',
  '38100',
  '38300',
  '38400',
  '39100',
  '39300'
];

const REGRIND_MATERIAL_CODES = [
  '10090',
  '10190',
  '10690',
  '30090',
  '32190',
  '32290',
  '32300',
  '32400',
  '40390'
];

const LANGUAGE_CODE_MAP = {
  EN_US: 'English',
  EN_GB: 'English',
  DD_DE: 'Deutsch (German)',
  ES_ES: 'Español (Spanish)',
  FR_FR: 'Français (French)',
  IT_IT: 'Italiano (Italian)',
  JA_JP: '日本 (Japanese)',
  PL_PL: 'Polski (Polish)',
  PT_BR: 'Português (Portuguese)',
  TR_TR: 'Türkçe (Turkish)',
  NL_NL: 'Nederlands (Netherlands)',
  ZH_CN: '中文 (Chinese)',
  DANISH: 'Danska (Danish)',
  SWEDISH: 'Svenska (Swedish)'
};

const REVERSE_LANGUAGE_CODE_MAP = {
  'spanish americas': 'ES_ES',
  'spanish europe': 'ES_ES',
  english: 'EN_US',
  'british english': 'EN_US',
  german: 'DD_DE',
  spanish: 'ES_ES',
  french: 'FR_FR',
  italian: 'IT_IT',
  japanese: 'JA_JP',
  polish: 'PL_PL',
  portuguese: 'PT_BR',
  turkish: 'TR_TR',
  netherlands: 'EN_US',
  dutch: 'EN_US',
  chinese: 'ZH_CN',
  danish: 'EN_US',
  swedish: 'EN_US'
};

const FOOD_SAFE_SERIES = [
  '560',
  '800',
  '850',
  '900',
  '1100',
  '1500',
  '1600',
  '1650',
  '1800',
  '2400'
];

const SHARE_EMAIL_SUBJECTS = {
  resources: {
    es: 'Intralox ha compartido algunos archivos con usted',
    en: 'Intralox has shared some files with you',
    da: 'Intralox har delt nogle filer med dig',
    de: 'Intralox hat Dateien mit Ihnen geteilt',
    fr: 'Intralox a partagé des fichiers avec vous.',
    it: 'Intralox ha condiviso dei documenti con Lei.',
    pl: 'Intralox udostępnił Ci niektóre pliki.',
    pt: 'Intralox compartilhou alguns arquivos com você.',
    tr: 'Intralox sizinle bazı dosyalar paylaştı',
    nl: 'Intralox heeft bestanden met u gedeeld',
    sv: 'Intralox har delat filer med dig'
  },
  orderDocuments: {
    es: {
      singleDocument: salesOrderNum =>
        `Documento de Intralox | Órdenes de venta ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Documentos de Intralox | Órdenes de venta ${salesOrderNum}`
    },
    en: {
      singleDocument: salesOrderNum =>
        `Intralox document | Sales Order ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Intralox documents | Sales Order ${salesOrderNum}`
    },
    da: {
      singleDocument: salesOrderNum =>
        `Intralox dokument | Salgsordre ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Intralox-dokumenter | Salgsordre ${salesOrderNum}`
    },
    de: {
      singleDocument: salesOrderNum =>
        `Intralox-Dokument | Verkaufsauftrag ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Intralox-Dokumente | Verkaufsauftrag ${salesOrderNum}`
    },
    fr: {
      singleDocument: salesOrderNum =>
        `Document Intralox | Bon de commande ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Documents d'Intralox | Bon de commande ${salesOrderNum}`
    },
    it: {
      singleDocument: salesOrderNum =>
        `Documento Intralox | Ordine di vendita ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Documenti Intralox | Ordine di vendita ${salesOrderNum}`
    },
    pl: {
      singleDocument: salesOrderNum =>
        `Dokument Intralox | Zamówienie ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Dokumenty firmy Intralox | Zamówienie ${salesOrderNum}`
    },
    pt: {
      singleDocument: salesOrderNum =>
        `Documento da Intralox | Pedido de venda ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Documentos da Intralox | Pedido de venda ${salesOrderNum}`
    },
    tr: {
      singleDocument: salesOrderNum =>
        `Intralox Belgesi | satış siparişi ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Intralox Belgeleri | satış siparişi ${salesOrderNum}`
    },
    nl: {
      singleDocument: salesOrderNum =>
        `Intralox-document | Verkooporder ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Intralox-documenten | Verkooporder ${salesOrderNum}`
    },
    sv: {
      singleDocument: salesOrderNum =>
        `Intralox dokument | Kundorder ${salesOrderNum}`,
      multipleDocuments: salesOrderNum =>
        `Intralox-dokument | Kundorder ${salesOrderNum}`
    }
  }
};

export {
  LOADING_TITLE,
  MAIN_CONTENT,
  ERROR_TITLE,
  LOADING_SUBTITLE,
  SEARCHING_TITLE,
  SEARCH_CUSTOMER_PLACEHOLDER,
  SEARCH_ACCOUNT_PLACEHOLDER,
  SEARCH_ORDER_PLACEHOLDER,
  NO_MY_CUSTOMERS_ALERT_TITLE,
  NO_MY_CUSTOMERS_ALERT_MESSAGE,
  SEARCH_ORDER_PLACEHOLDER_SHORT,
  ACCOUNT_TYPE_OPTIONS,
  TO_PREVIOUS_PAGE,
  DASHBOARD_LINKS,
  ANNOUNCEMENT_MAP,
  ORDER_PAGINATION_OPTIONS,
  OX_MODAL_ID,
  OX_MODAL_FIRST_ELEMENT,
  USER_ORDER_BY_INPUT,
  MAX_FILE_UPLOAD_SIZE,
  ACCEPTED_UPLOAD_TYPES,
  EMAIL_SHARE_LANGUAGE_OPTIONS,
  LEGACY_ROUTE_REDIRECTS,
  MATERIAL_CODES_WITH_REACH_LETTER,
  REGRIND_MATERIAL_CODES,
  LANGUAGE_CODE_MAP,
  FOOD_SAFE_SERIES,
  LONG_LANGUAGE_CODE_MAP,
  REVERSE_LANGUAGE_CODE_MAP,
  SHARE_EMAIL_SUBJECTS
};
