import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-input-polyfill-react';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as serviceWorker from './serviceWorker';
import './styles/styles.scss';
import App from './App';
import { IS_LOGGED_IN } from './api/user/query';
import { getCurrentEnv, isEmbedded } from './helpers';
import embedRoutes from './routes/embed';
import RouteWithSubRoutes from './routes/RoutesWithSubRoutes';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include'
});

const env = getCurrentEnv();

const authenticationError = 'UNAUTHENTICATED';

/**
 * We need to tell Apollo cache how to decide what to consider unique.
 * Signature of accounts data is not in the form Apollo Cache expects
 * so we need to tell it what value to look at via config.
 */
const cacheConfig = {
  dataIdFromObject: object => {
    if (object.__typename === 'Account') {
      return object._id.value;
    }
    if (object.__typename === 'Shipment') {
      return object._id.value;
    }
    if (object.__typename === 'Order') {
      return object._id.value;
    }
    return defaultDataIdFromObject(object); // fall back to default handling
  }
};
const cache = new InMemoryCache(cacheConfig);

const _updateLoginStateInCache = () => {
  cache.writeQuery({
    query: IS_LOGGED_IN,
    data: {
      getLoggedInState: false
    }
  });
};

/**
 * Returns a boolean value depending on if a user info query has errored.
 *
 * Graphql errors are used when prisma is down and network errors are used if the graphql server is down.
 */
const hasUserInfoQueryErrored = interceptedError => {
  const { networkError, graphQLErrors, operation } = interceptedError;
  return !!(
    (graphQLErrors &&
      graphQLErrors.some(
        error => error.path && error.path.includes('userInfo')
      )) ||
    (networkError && operation.operationName === 'getUserInfo')
  );
};

/**
 * Returns a boolean value depending on if an authentication error has occurred.
 */
const hasAuthErrorOccured = interceptedError => {
  const { graphQLErrors } = interceptedError;
  if (graphQLErrors && graphQLErrors.length > 0) {
    if (graphQLErrors[0].message === 'Could not verify session.') {
      _updateLoginStateInCache();
    }
    return !!graphQLErrors.map(item => item.name).includes(authenticationError);
  }
  return false;
};

// Reference: https://www.apollographql.com/docs/react/advanced/network-layer/#afterware
const interceptAuthErrors = onError(error => {
  if (error && (hasAuthErrorOccured(error) || hasUserInfoQueryErrored(error))) {
    localStorage.removeItem('token');
  }
});

// Inserts an authorization header if the param is present
const authLink = new ApolloLink((operation, forward) => {
  const token = new URLSearchParams(window.location.search).get('token');

  if (token) {
    operation.setContext(({ headers }) => ({
      headers: {
        authorization: `Bearer ${token}`,
        ...headers
      }
    }));
  }

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authLink, interceptAuthErrors, httpLink]),
  cache
});

ReactDOM.render(
  <>
    <ApolloProvider client={client}>
      <BrowserRouter>
        {/* If in iframe, return widget routes, otherwise, show app as normal */}
        {isEmbedded() ? (
          <Switch>
            {embedRoutes.routes.map(route => (
              <RouteWithSubRoutes {...route} />
            ))}
          </Switch>
        ) : (
          <App />
        )}
      </BrowserRouter>
    </ApolloProvider>
  </>,
  document.getElementById('root')
);

if (env === "prod") {
  const scriptTag = document.createElement("script");
  scriptTag.setAttribute("defer", "defer");
  scriptTag.setAttribute("data-domain", "cxp.intralox.com");
  scriptTag.setAttribute("src", "https://plausible.io/js/plausible.js");
  document.documentElement.append(scriptTag);
}
else {
  window.plausible = (...args) => console.log("[PLAUSIBLE]", ...args);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
