import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { CREATE_ANNOUNCEMENT } from '../api/announcements/mutation';
import AnnouncementForm from '../components/partials/AnnouncementForm';
import { GET_ANNOUNCEMENTS } from '../api/announcements/query';

const CreateAnnouncement = ({ route, history }) => {
  const [type, _setType] = useState('');
  const [body, _setBody] = useState('');
  const [expireAt, _setExpireAt] = useState();

  const [selectedUserTypes, _setSelectedUserTypes] = useState(new Map());
  const [selectedFeatures, _setSelectedFeatures] = useState(new Map());
  const [selectedUserRoles, _setSelectedUserRoles] = useState(new Map());

  const _updateAnnouncementsInCache = (
    cache,
    { data: { createAnnouncement } }
  ) => {
    const announcementData = cache.readQuery({
      query: GET_ANNOUNCEMENTS
    });

    if (announcementData) {
      cache.writeQuery({
        query: GET_ANNOUNCEMENTS,
        data: {
          announcements: [...announcementData.announcements, createAnnouncement]
        }
      });
    }
  };

  const [
    _createAnnouncement,
    { error: createAnnouncementError, loading: createLoading }
  ] = useMutation(CREATE_ANNOUNCEMENT, {
    update: _updateAnnouncementsInCache,
    onCompleted: () => {
      history.push('/mgmt/announcements?create-success=true');
    }
  });

  const [
    _createAnnouncementAndNew,
    { error: createNewAnnouncementError, loading: createAndNewLoading }
  ] = useMutation(CREATE_ANNOUNCEMENT, {
    update: _updateAnnouncementsInCache,
    onCompleted: () => {
      history.push('/mgmt/announcements/new?create-success=true');
      _setType('');
      _setBody('');
      _setExpireAt(null);
      _setSelectedUserTypes(new Map());
      _setSelectedFeatures(new Map());
      _setSelectedUserRoles(new Map());
    }
  });

  const _getCreateAnnouncementPayload = () => ({
    variables: {
      data: {
        type,
        body,
        expireAt: new Date(expireAt),
        ...(selectedUserTypes && {
          userTypes: [...selectedUserTypes].map(val => val[0])
        }),
        ...(selectedUserRoles && {
          userRoles: [...selectedUserRoles].map(val => val[0])
        }),
        ...(selectedFeatures && {
          features: [...selectedFeatures].map(val => val[0])
        })
      }
    }
  });

  return (
    <AnnouncementForm
      loading={createAndNewLoading || createLoading}
      title={route.title}
      hasError={!!(createAnnouncementError || createNewAnnouncementError)}
      body={body}
      expireAt={expireAt}
      type={type}
      selectedFeatures={selectedFeatures}
      selectedUserRoles={selectedUserRoles}
      selectedUserTypes={selectedUserTypes}
      handleBodyInput={_setBody}
      handleExpireAtInput={_setExpireAt}
      handleTypeInput={_setType}
      handleUserRoleChange={_setSelectedUserRoles}
      handleUserTypeChange={_setSelectedUserTypes}
      handleFeatureChange={_setSelectedFeatures}
      handleSubmit={() => _createAnnouncement(_getCreateAnnouncementPayload())}
      handleSubmitAndNew={() => {
        _createAnnouncementAndNew(_getCreateAnnouncementPayload());
      }}
    />
  );
};

export default CreateAnnouncement;
