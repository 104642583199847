import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from '@apollo/client';
import OxTable from '../molecules/OxTable';
import { useModal } from '../molecules/OxModal';
import { logEvent } from '../../helpers/segmentLogger';
import { GET_USER_INFO } from '../../api/user/query';
import DocumentEmailForm from './DocumentEmailForm';
import { orderDocumentsHeaderPlaceholder } from '../../helpers/placeholderHelpers';
import { LANGUAGE_CODE_MAP } from '../../helpers/constants';
import '../../styles/components/_ox-order-documents.scss';
import { canShareDocument, isEmbedded } from '../../helpers';
import OxButton from '../molecules/OxButton';

const ListOfDocuments = ({
  accountName = "",
  documents,
  hiddenDocuments,
  loading,
  pageName,
  purchaseOrderNumber,
  sortFunc,
  defaultLanguage,
  onSelectionChange
}) => {
  ListOfDocuments.propTypes = {
    accountName: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.object),
    hiddenDocuments: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    pageName: PropTypes.string,
    purchaseOrderNumber: PropTypes.string,
    defaultLanguage: PropTypes.string,
    onSelectionChange: PropTypes.func
  };

  const [selectedDocs, _setSelectedDocs] = useState(new Map());
  const [showingHiddenDocs, _setShowingHiddenDocs] = useState(false);

  const { accountId = "", orderNumber, accountNumber } = useParams();

  // Engineering manual series documents will match this RegEx
  const beltSeriesRegEx = /S\d+/;

  // Formats the document type for the data-gi attribute
  const _getGiDataFormattedDocType = doc => {
    let formattedDocType;

    if (doc.documentType === 'Compliance Document') {
      formattedDocType = 'Compliance-Docs';
    } else if (
      doc.documentType === 'Engineering Manual' &&
      !beltSeriesRegEx.test(doc.name)
    ) {
      formattedDocType = doc.name.toLowerCase().includes('thermodrive')
        ? 'ThemoDrive-Engineering-Manual'
        : 'Engineering-Manual';
    } else if (
      doc.documentType === 'Engineering Manual' &&
      beltSeriesRegEx.test(doc.name)
    ) {
      const seriesNumber = doc.name.match(/\d+/)[0];
      formattedDocType = `Engineering-Manual-Series${seriesNumber}`;
    } else {
      formattedDocType = doc.documentType;
    }

    return formattedDocType;
  };

  const { data: userData } = useQuery(GET_USER_INFO);

  const ShareDocumentButton = documentInfo => {
    const { showModal } = useModal();

    const shareGiDataAttr = `${pageName}__share-button__${_getGiDataFormattedDocType(
      documentInfo
    )}${orderNumber ? `__#${orderNumber}` : ''}`;

    const _openModal = () => {
      logEvent({
        giDataAttr: shareGiDataAttr,
        eventName: `Shared ${documentInfo.name}`,
        eventData: documentInfo
      });

      showModal({
        childElement: (
          <DocumentEmailForm
            shareDirectly
            orderNumber={orderNumber}
            documents={[documentInfo]}
            purchaseOrderNumber={purchaseOrderNumber}
            pageName={pageName}
            defaultLanguage={defaultLanguage}
          />
        ),
        header: 'Share Document',
        giDataAttr: 'gi-data',
        firstElementId: 'email-form__subject-field',
        lastElementId: 'email-form__cancel-btn'
      });
    };

    return (
      <button
        className="btn-icon ox-table__share-document-button"
        aria-label="Share Document"
        type="button"
        onClick={_openModal}
        data-gi={shareGiDataAttr}
      >
        <FeatherIcon icon="send" />
      </button>
    );
  };

  /**
   * Returns true if document has been selected
   * @param {ProductDocument | GeneralDocument} doc
   * @returns
   */
  const isDocumentSelected = doc => {
    return selectedDocs.has(doc.name);
  };

  /**
   * Called whenever a checkbox is selected.  Adds or removes
   * documents from the selectedDocuments list
   * @param {Event} event
   * @param {ProductDocument | GeneralDocument} doc
   */
  const handleMultiSelect = (event, selectedDoc) => {
    // Can't save __typename to graphQL; this removes it
    const { __typename, ...sanitizedDoc } = selectedDoc;

    if (event.target.checked) {
      // Add doc to selected list
      _setSelectedDocs(
        new Map(selectedDocs.set(sanitizedDoc.name, sanitizedDoc))
      );
    } else {
      // Remove doc from list
      selectedDocs.delete(sanitizedDoc.name);
      _setSelectedDocs(new Map(selectedDocs));
    }

    // Call change handler
    if (onSelectionChange) {
      onSelectionChange(Array.from(selectedDocs.values()));
    }
  };

  /**
   * Return checkbox element for multi-selecting documents
   * @returns
   */
  const getDocumentSelect = doc => {
    return (
      <div className="units-row">
        <div className="unit-100">
          <input
            key={doc.name}
            type="checkbox"
            checked={isDocumentSelected(doc)}
            onChange={e => handleMultiSelect(e, doc)}
          />
        </div>
      </div>
    );
  };

  const createDocumentRow = document => {
    const giDataAttr = `${pageName}__download-link__${_getGiDataFormattedDocType(
      document
    )}${orderNumber ? `__#${orderNumber}` : ''}`;
    const downloadEventName = `Downloaded ${document.name}`;
    const downloadEventData = {
      orderNumber,
      documentType: document.documentType
    };
    const downloadEventActionName = `Downloaded from ${
      pageName === 'order-documents'
        ? 'Related Order Documents'
        : 'Resource Section'
    }`;
    const downloadEventCategory = document.documentType;
    const downloadEventLabel = document.name;

    return {
      _id: {
        value: Math.random()
      },
      multiselect: {
        func: () => getDocumentSelect(document)
      },
      name: {
        download: {
          giDataAttr,
          value: document.url,
          iconName: 'download-cloud',
          onClick: () => {
            logEvent({
              giDataAttr,
              downloadEventName,
              downloadEventData,
              actionName: downloadEventActionName,
              categoryName: downloadEventCategory,
              labelName: downloadEventLabel
            });

            const userType = isEmbedded() ? "Dynamics" : userData.userInfo.userType.name;

            window.plausible(`${userType} - Downloaded Document`, {
              props: {
                accountName,
                accountNumber: isEmbedded() ? accountNumber : accountId,
                documentType: document.documentType,
                pageName: pageName === 'order-documents' ? 'Related Order Documents' : 'Resource Section',
                jobTitle: userData.userInfo.jobTitle,
                role: userData.userInfo.userRole?.name,
              }
            });
          }
        },
        value: document.name
      },
      shareButton: canShareDocument(userData, document) && {
        func: () => ShareDocumentButton(document)
      },
      language: {
        value: LANGUAGE_CODE_MAP[document.language] || document.language
      },
      documentType: {
        value: document.documentType
      },
      fileType: {
        value: document.fileType
      },
      size: {
        value: document.size
      }
    };
  };

  const mergeHiddenDocs = () => {
    const giDataAttr = `${pageName}__show-hidden${
      orderNumber ? `__#${orderNumber}` : ''
    }`;
    logEvent({
      giDataAttr,
      eventName: `Showing hidden docs`
    });
    _setShowingHiddenDocs(true);
  };

  const createShowHiddenButton = () => {
    return (
      <div className="units-row justify-center">
        <div className="unit-100">
          <OxButton
            clickFunc={mergeHiddenDocs}
            helperClass="width-100"
            type="primary"
            element="button"
            noScale
            text="Show Older Documents"
          />
        </div>
      </div>
    );
  };

  const documentsToMap = [
    ...documents,
    ...(showingHiddenDocs && hiddenDocuments != null && hiddenDocuments.length
      ? hiddenDocuments
      : [])
  ].map(doc => createDocumentRow(doc));

  return (
    <div>
      <OxTable
        helperClass="ox-table--documents"
        noAnalytics
        loading={loading}
        loadingTitle="LOADING DOCUMENTS"
        data={{
          headers: orderDocumentsHeaderPlaceholder,
          data: documentsToMap
        }}
        sortFunc={sortFunc}
      />
      {hiddenDocuments != null && hiddenDocuments.length && !showingHiddenDocs
        ? createShowHiddenButton()
        : null}
    </div>
  );
};

export default ListOfDocuments;
