import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ADD_APPROVED_EMAIL } from '../api/approvedEmails/mutation';
import { GET_APPROVED_EMAILS } from '../api/approvedEmails/query';
import OxButton from '../components/molecules/OxButton';
import OxCard from '../components/molecules/OxCard';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxLoader from '../components/tokens/OxLoader';

const AddApprovedEmail = () => {
    // State to store the email input value
    const [email, setEmail] = useState('');

    // Used to navigate programatically
    const history = useHistory();

    // Runs the 'addApprovedEmail' mutation
    const [addEmail, { loading, error }] = useMutation(ADD_APPROVED_EMAIL, {
        // Updates Apollo's in memory cache so the UI will reflect this change
        update: (cache) => {
            // Get data currently in the cache
            const cachedData = cache.readQuery({
                query: GET_APPROVED_EMAILS
            });

            // Update the data in the cache
            if (cachedData) {
                cache.writeQuery({
                    query: GET_APPROVED_EMAILS,
                    data: {
                        approvedEmails: [
                            ...cachedData.approvedEmails,
                            {
                                email,
                                role: "Customer"
                            }
                        ]
                    }
                });
            }
        },
        onCompleted: () => {
            // Return the user to the approved email list
            history.push("/approvedEmails?success=true")
        }
    });

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Add the email to the list
        addEmail({
            variables: {
                data: {
                    email
                }
            }
        });
    };

    return (
        <OxCard header={{
            title: 'Add Approved Email'
        }}
        >
            <div>
                {error && (
                    <OxSoftAlert
                        alertType='error'
                        title="Unable to add email"
                        isOutlined={true}
                    />
                )}
                {loading && (
                    <OxLoader />
                )}
                {!loading && (
                    <form
                        onSubmit={handleSubmit}
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <label htmlFor='email'>Email:</label>
                        <input
                            type='email'
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div style={{ marginTop: "1rem" }}>
                            <OxButton
                                text='submit'
                                type='submit'
                                clickFunc={handleSubmit}
                            />
                        </div>
                    </form>)}
            </div>
        </OxCard>
    );
};

export default AddApprovedEmail;
