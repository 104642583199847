import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Route, Redirect } from 'react-router-dom';
import { GET_USER_INFO } from '../api/user/query';
import NoAccess from '../pages/NoAccess';
import NoMatch from '../pages/NoMatch';
import AppRoute from '../propTypes/AppRoute';
import useHasPermission from '../hooks/useHasPermission';
import useIsLoggedIn from '../hooks/useIsLoggedIn';

/**
 * Creates a private route by checking if the user is logged in and that they have the correct
 * permissions prior to routing.
 */
const PrivateRoute = ({
  component,
  routes,
  route,
  requiredPermissions,
  ...rest
}) => {
  PrivateRoute.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.shape({ ...AppRoute })),
    route: PropTypes.shape({ ...AppRoute })
  };

  const { data } = useQuery(GET_USER_INFO);

  // useHasPermission is a hook so must not be called conditionally
  const permissions = useHasPermission(requiredPermissions);
  const hasPermission =
    (requiredPermissions ? permissions : true) &&
    (route.authFunctions
      ? route.authFunctions.every(func => func(data))
      : true);

  const _getNoMatchRoute = () => <Route component={NoMatch} />;

  /**
   * Determines if user should see NoMatch or NoAccess page.
   *
   * shouldGoToNoMatchIfNoAccess is an array of functions that determines if the user should
   * got to NoMatch or NoAccess. If any of those functions return true go to NoMatch.
   *
   * If shouldGoToNoMatchIfNoAccess is not defined handle no access normally
   * and show NoAccess page.
   *
   * @param {*} route
   * @param {*} user
   */
  const _getNoAccessRoute = user => {
    if (route.shouldGoToNoMatchIfNoAccess) {
      return route.shouldGoToNoMatchIfNoAccess.some(func => func(user)) ? (
        _getNoMatchRoute()
      ) : (
        <Route component={NoAccess} />
      );
    }
    return <Route component={NoAccess} />;
  };

  /**
   * Get route. If route has a list of requiredPermissions then check permissions, otherwise return route.
   */
  const _getRoute = renderProps => {
    if (
      (requiredPermissions && requiredPermissions.length) ||
      (route.authFunctions && route.authFunctions.length)
    ) {
      if (data && data.userInfo && data.userInfo.userType) {
        return hasPermission ? (
          <route.component
            {...renderProps}
            routes={routes}
            route={route}
            {...rest}
          />
        ) : (
          _getNoAccessRoute(data)
        );
      }
      return null;
    }
    return (
      <route.component
        {...renderProps}
        routes={routes}
        route={route}
        {...rest}
      />
    );
  };

  const { isLoggedIn } = useIsLoggedIn();

  return (
    <Route
      {...rest}
      render={renderProps =>
        isLoggedIn ? (
          _getRoute(renderProps)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: renderProps.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
