import React from 'react';
import propTypes from 'prop-types';
import { useModal } from '../molecules/OxModal';
import { isEmbedded } from '../../helpers';
import OxButton from '../molecules/OxButton';
import MultiFileDownload from '../molecules/MultiFileDownload';
import DocumentEmailForm from '../partials/DocumentEmailForm';
import '../../styles/components/_download-files-modal.scss';
import OxIcon from './OxIcon';

const DocumentActionButton = ({
  action,
  documents,
  orderNumber,
  purchaseOrderNumber,
  defaultLanguage,
  tabIndex
}) => {
  DocumentActionButton.propTypes = {
    action: propTypes.oneOf(['share', 'download']),
    documents: propTypes.arrayOf(
      propTypes.shape({
        containsManuals: propTypes.bool,
        containsThermoDriveDocuments: propTypes.bool,
        documentType: propTypes.string,
        fileType: propTypes.string,
        id: propTypes.string,
        language: propTypes.string,
        name: propTypes.string,
        orderNumber: propTypes.string,
        size: propTypes.string,
        url: propTypes.string,
        _typename: propTypes.string
      })
    )
  };

  const { showModal, hideModal } = useModal();

  const _handleDownloadCompletion = downloadInfo => {
    if (
      downloadInfo &&
      downloadInfo.multiDocumentDownload &&
      downloadInfo.multiDocumentDownload.url
    ) {
      // Hide the 'generating file' modal when completed
      hideModal();

      // Show 'download ready' modal
      showModal({
        header: `Your download is ready`,
        childElement: (
          <MultiFileDownload
            docsToDownload={documents}
            handleDownloadCompletion={_handleDownloadCompletion}
          />
        )
      });
    }
  };

  const _handleButtonClick = () => {
    if (action === 'download') {
      showModal({
        header: `Preparing your download`,
        childElement: (
          <MultiFileDownload
            docsToDownload={documents}
            handleDownloadCompletion={_handleDownloadCompletion}
          />
        )
      });
    } else {
      showModal({
        childElement: (
          <DocumentEmailForm
            orderNumber={orderNumber}
            purchaseOrderNumber={purchaseOrderNumber}
            documents={documents}
            pageName={`${orderNumber ? 'order-documents' : 'resources'}`}
            defaultLanguage={defaultLanguage}
          />
        ),
        header: 'Share Document',
        firstElementId: 'email-form__subject-field',
        lastElementId: 'email-form__cancel-btn'
      });
    }
  };

  const _getIconForAction = () => {
    const actionToIconMap = {
      share: 'send',
      download: 'download'
    };

    return actionToIconMap[action];
  };

  return (
    <div className="ox_card__download-btn">
      {isEmbedded() ? (
        <div>
          <OxIcon
            helperClass="cursor-pointer tabbable"
            icon={_getIconForAction()}
            badgeText={documents.length}
            clickFunc={() => _handleButtonClick()}
            tabIndex={tabIndex}
          />
        </div>
      ) : (
        <OxButton
          isSquare
          size="default"
          type="primary"
          text={`${action === 'download' ? 'Download' : 'Share'} File${
            documents.length > 1 ? 's' : ''
          }`}
          element="span"
          clickFunc={() => _handleButtonClick()}
        />
      )}
    </div>
  );
};

export default DocumentActionButton;
