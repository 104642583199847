import React from 'react';
import PropTypes from 'prop-types';
import OxSoftAlert from '../molecules/OxSoftAlert';
import OxSearch from './OxSearch';
import {
  ERROR_TITLE,
  SEARCH_ACCOUNT_PLACEHOLDER,
  LOADING_TITLE,
  LOADING_SUBTITLE
} from '../../helpers/constants';
import {
  UnableToRetrieveData,
  customerHeaderPlaceholder,
  getTableBodyPlaceholder,
  customerBodyPlaceholder
} from '../../helpers/placeholderHelpers';
import OxCard from '../molecules/OxCard';
import OxLoaderOverlay from '../tokens/OxLoaderOverlay';
import OxTable from '../molecules/OxTable';
import Account from '../../propTypes/Account';
import Header from '../../propTypes/Header';

const Customers = ({
  title,
  history,
  helperClass,
  loading,
  data,
  collapsible,
  hasNoCustomers,
  error,
  giDataAttr,
  minTableCount = 10,
  children,
  showSearch
}) => {
  Customers.propTypes = {
    title: PropTypes.string,
    helperClass: PropTypes.string,
    collapsible: PropTypes.shape({
      canCollapse: PropTypes.bool,
      defaultCollapsed: PropTypes.bool
    }),
    loading: PropTypes.bool,
    hasNoCustomers: PropTypes.func,
    showSearch: PropTypes.bool,
    minTableCount: PropTypes.number,
    giDataAttr: PropTypes.string,
    data: PropTypes.shape({
      accounts: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({ ...Account })),
        headers: PropTypes.arrayOf(PropTypes.shape({ ...Header }))
      })
    })
  };

  const _hasNoCustomers = () => {
    return (
      !loading &&
      data &&
      data.accounts &&
      data.accounts.data &&
      !data.accounts.data.length
    );
  };

  const _getAlert = () => {
    if (_hasNoCustomers(data, 'accounts')) {
      return hasNoCustomers();
    }
    return (
      <OxSoftAlert
        title={ERROR_TITLE}
        alertType="warning"
        giDataAttr={`${giDataAttr}__network-error-alert`}
        isOutlined
        eventName="network"
      >
        {UnableToRetrieveData('customers')}
      </OxSoftAlert>
    );
  };

  return (
    <OxCard
      helperClass="no-bottom-margin"
      {...collapsible && { collapsible: { ...collapsible } }}
      header={{
        title,
        buttons: [
          () =>
            showSearch ? (
              <div className="ox_card__search-box" key="my-customer-search">
                <OxSearch
                  searchType="account"
                  searchPlaceholder={SEARCH_ACCOUNT_PLACEHOLDER}
                  history={history}
                  name="customer-search"
                  label="Search for a customer"
                />
              </div>
            ) : null
        ]
      }}
      id={giDataAttr}
    >
      {(error || _hasNoCustomers() || (data && !data.accounts)) && !loading ? (
        _getAlert()
      ) : (
        <OxLoaderOverlay
          isLoading={loading}
          title={LOADING_TITLE}
          subtitle={LOADING_SUBTITLE}
          giDataAttr={`${giDataAttr}__loading-overlay`}
        >
          <OxTable
            noAnalytics
            helperClass={helperClass}
            giDataAttr={
              loading
                ? `${giDataAttr}__placeholder-data`
                : `${giDataAttr}__data`
            }
            data={{
              headers: loading
                ? customerHeaderPlaceholder
                : data.accounts.headers,
              data: loading
                ? getTableBodyPlaceholder(2, customerBodyPlaceholder)
                : data.accounts.data.slice(0, minTableCount)
            }}
          />
          {children}
        </OxLoaderOverlay>
      )}
    </OxCard>
  );
};

export default Customers;
