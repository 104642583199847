import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_USER_INFO } from '../api/user/query';
import { userIsAdmin } from '../helpers';

export const hasPermission = (data, permissionsToCheck) => {
  if (
    permissionsToCheck &&
    permissionsToCheck.length &&
    data &&
    data.userInfo &&
    data.userInfo.allFeatures
  ) {
    // Remove potential duplicates from permissionsToCheck
    const requiredPermissions = [...new Set(permissionsToCheck)];

    // Every required permission should be in the user's feature list
    return (
      userIsAdmin(data) ||
      requiredPermissions.every(
        permission =>
          data.userInfo.allFeatures.findIndex(
            feature => feature.name === permission
          ) !== -1
      )
    );
  }
  return false;
};

export const hasAccountIdPermission = (data, accountIdToCheck) => {
  if (userIsAdmin(data)) return true;
  if (
    data &&
    data.userInfo &&
    data.userInfo.userType.name &&
    data.userInfo.userType.name === 'internal'
  ) {
    return true;
  }
  if (
    data &&
    data.accountId &&
    data.accountId.accountIds &&
    data.accountId.accountIds.length
  ) {
    const accountIdSet = new Set(data.accountId.accountIds);
    return accountIdSet.has(accountIdToCheck);
  }
  return false;
};

/**
 * permissionsToCheck - list of permissions to check against user permissions
 *
 * Returns true if the logged in user has at least one of the provided permissions in permissionsToCheck.
 */
const useHasPermission = permissionsToCheck => {
  useHasPermission.propTypes = {
    permissionsToCheck: PropTypes.arrayOf(PropTypes.string)
  };

  const { data } = useQuery(GET_USER_INFO);
  return hasPermission(data, permissionsToCheck);
};

export default useHasPermission;
