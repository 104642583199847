import { gql } from '@apollo/client';

const GET_PACKING_SLIPS = gql`
  query getPackingSlips($where: [PackingSlipWhereInput!]!) {
    packingSlips(where: $where) {
      name
      documentType
      language
      fileType
      size
      url
      orderNumber
    }
  }
`;

export default GET_PACKING_SLIPS;
