/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, Route } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import useSegment from '../hooks/useSegment';
import usePrevious from '../hooks/usePrevious';
import '../styles/pages/_interior.scss';
import '../styles/components/_ox-order-documents.scss';
import OxCard from '../components/molecules/OxCard';
import ListOfDocuments from '../components/partials/ListOfDocuments';
import { GET_ORDER_HEADER } from '../api/orders/query';
import GET_COMMERCIAL_INVOICES from '../api/commercialInvoices/query';
import GET_ACCOUNTING_INVOICES from '../api/accountingInvoices/query';
import GET_PACKING_SLIPS from '../api/packingSlips/query';
import {
  GET_GENERAL_DOCUMENTS,
} from '../api/documents/query';
import {
  FOOD_SAFE_SERIES,
  REGRIND_MATERIAL_CODES
} from '../helpers/constants';
import {
  isInternal,
  docTypeSortFunction,
  fileSizeSortFunction,
  sortEngineeringManuals,
  materialCodeGetsReachLetter,
  alphabeticalSortFunction,
  seperateOutdatedUniqueDocs,
  isEmbedded,
  canShareDocument,
  showCommercialInvoices
} from '../helpers';
import { logEvent } from '../helpers/segmentLogger';
import { GET_USER_INFO } from '../api/user/query';
import GET_MATERIAL_CODES_BY_ORDER_NUMBER from '../api/materialCodes/query';
import GET_ORDER_ITEMS_BY_ORDER_NUMBER from '../api/orderItems/query';
import DocumentActionButton from '../components/tokens/DocumentActionButton';
import NoMatch from './NoMatch';

const DOC_TYPES = {
  PACKING_SLIPS: 'packingSlips',
  INVOICES: 'accountingInvoices',
  STATIC_DOCS: 'generalDocuments',
  PRODUCT_DOCS: 'productDocuments', // this comes from name of the query
  COMMERICAL_INVOICES: 'commercialInvoices'
};

const OrderDocuments = ({
  onShowDownloadButtonChange,
  onShowShareButtonChange,
  onSelectedDocumentsChange
}) => {
  const [documents, setDocuments] = useState([]);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const { orderNumber, accountId } = useParams();
  const [orderHasReachLetter, setOrderHasReachLetter] = useState(false);

  const [sort, setSort] = useState({
    order: null,
    orderBy: null
  });
  const [showDownloadButton, _setShowDownloadButton] = useState(false);
  const [showShareButton, _setShowShareButton] = useState(false);
  const [selectedDocs, _setSelectedDocs] = useState([]);
  const queryParams = new URLSearchParams(useLocation().search);
  const loggedInUserEmail = queryParams.get('loggedInUserEmail');
  const { data: userData } = useQuery(GET_USER_INFO, {
    ...(!!loggedInUserEmail && {
      variables: {
        where: {
          email: loggedInUserEmail
        }
      }
    })
  });

  const {
    data: orderHeaderData,
    loading: orderHeaderLoading,
    error: orderHeaderError
  } = useQuery(GET_ORDER_HEADER, {
    variables: {
      where: {
        orderNumber,
        accountId
      }
    },
    onCompleted: data => {
      if (data && data.orderHeader && data.orderHeader.purchaseOrderId) {
        setPurchaseOrderNumber(data.orderHeader.purchaseOrderId);
        getProductSeriesData();
      }
    }
  });

  const {
    data: packingSlipData,
    loading: packingSlipsLoading,
    error: packingSlipError
  } = useQuery(GET_PACKING_SLIPS, {
    variables: {
      where: {
        orderNumber
      }
    }
  });

  const {
    data: invoiceData,
    loading: invoicesLoading,
    error: invoiceError
  } = useQuery(GET_ACCOUNTING_INVOICES, {
    variables: {
      where: [
        {
          orderNumber
        }
      ]
    }
  });


  const {
    data: commercialInvoicesData,
    loading: commercialInvoicesLoading,
    error: commercialInvoicesError
  } = useQuery(GET_COMMERCIAL_INVOICES, {
    skip: !isInternal(userData) || !showCommercialInvoices(userData),
    variables: {
      where: {
        orderNumber,
        accountId
      }
    }
  });

  const _getMaterialCodeQueryParams = () => {
    return [
      {
        orderNumber,
        orgId:
          orderHeaderData && orderHeaderData.orderHeader
            ? orderHeaderData.orderHeader.orgId
            : ''
      }
    ];
  };

  const { loading: materialCodesLoading } = useQuery(
    GET_MATERIAL_CODES_BY_ORDER_NUMBER,
    {
      variables: {
        orders: _getMaterialCodeQueryParams()
      },
      skip: !!orderHeaderLoading || !isInternal(userData),
      onCompleted: response => {
        if (
          response &&
          response.materialCodesByOrderNumber &&
          response.materialCodesByOrderNumber.data
        ) {
          const regrindCodes = new Set(REGRIND_MATERIAL_CODES);

          // Get list of material codes for order
          const materialCodesForOrder = response.materialCodesByOrderNumber.data[0].materialCodes.filter(
            materialCode =>
              !regrindCodes.has(materialCode.componentItemNumber) &&
              materialCodeGetsReachLetter(materialCode.componentItemNumber)
          );

          setOrderHasReachLetter(materialCodesForOrder.length > 0);
        }
      }
    }
  );

  const { data: complianceDocData, loading: complianceDocLoading } = useQuery(
    GET_GENERAL_DOCUMENTS,
    {
      variables: {
        where: {
          documentTypes: 'ORDER'
        }
      },
      skip: !isInternal(userData)
    }
  );

  const mapQueryResponse = (data, property) => {
    return data && data[property] && data[property].length
      ? data[property]
      : [];
  };

  const previousInvoiceData = usePrevious(invoiceData);

  useEffect(() => {
    // Determine which order to add documents by looking at the previous value.
    // This way the value that changed most recently is added first.
    let documentsToDisplay;

    if (previousInvoiceData === invoiceData) {
      documentsToDisplay = [
        ...mapQueryResponse(packingSlipData, DOC_TYPES.PACKING_SLIPS),
        ...mapQueryResponse(invoiceData, DOC_TYPES.INVOICES),
        ...mapQueryResponse(
          commercialInvoicesData,
          DOC_TYPES.COMMERICAL_INVOICES
        ),
      ];
    } else {
      documentsToDisplay = [
        ...mapQueryResponse(invoiceData, DOC_TYPES.INVOICES),
        ...mapQueryResponse(
          commercialInvoicesData,
          DOC_TYPES.COMMERICAL_INVOICES
        ),
        ...mapQueryResponse(packingSlipData, DOC_TYPES.PACKING_SLIPS),
      ];
    }

    // Sort the Engineering Manuals
    documentsToDisplay = sortEngineeringManuals(documentsToDisplay);

    // Attach REACH letter if this order number contains certain material codes
    if (
      !materialCodesLoading &&
      orderHasReachLetter &&
      !complianceDocLoading &&
      complianceDocData &&
      complianceDocData.generalDocuments.length
    ) {
      const reachLetter = complianceDocData.generalDocuments.find(doc =>
        doc.id.toLowerCase().includes('reach')
      );

      documentsToDisplay = [...documentsToDisplay, {
        ...reachLetter,
        documentType: 'Material Specific Compliance Letter',
      }];
    }

    // Filter for unique document names and seperate outdated
    const seperatedDocs = seperateOutdatedUniqueDocs(documentsToDisplay);

    // Sort the documents if a sort has been selected
    if (sort.order && sort.orderBy && sort.orderBy === 'size') {
      seperatedDocs.currentDocs.sort((fileA, fileB) => {
        return fileSizeSortFunction(fileA, fileB, sort.order);
      });
    } else if (sort.order && sort.orderBy && sort.orderBy === 'documentType') {
      seperatedDocs.currentDocs.sort((fileA, fileB) => {
        return docTypeSortFunction(fileA, fileB, sort.order);
      });
    } else if (
      sort.order &&
      sort.orderBy &&
      (sort.orderBy === 'language' || sort.orderBy === 'name')
    ) {
      seperatedDocs.currentDocs.sort((fileA, fileB) => {
        return alphabeticalSortFunction(fileA, fileB, sort);
      });
    }

    setDocuments(seperatedDocs.currentDocs);
  }, [
    invoiceData,
    commercialInvoicesData,
    packingSlipData,
    previousInvoiceData,
    sort,
    userData,
    complianceDocData,
    complianceDocLoading,
    materialCodesLoading,
    orderHasReachLetter,
  ]);

  useSegment({
    giDataAttr: 'packing-slip',
    error: packingSlipError,
    data: packingSlipData ? packingSlipData.packingSlip : packingSlipData,
    dataType: 'packing slips'
  });

  useSegment({
    giDataAttr: 'accounting-invoice',
    error: invoiceError,
    data: invoiceData ? invoiceData.accountingInvoices : invoiceData,
    dataType: 'accounting invoices'
  });

  useSegment({
    giDataAttr: 'commerical-invoices',
    error: commercialInvoicesError,
    data: commercialInvoicesData
      ? commercialInvoicesData.commercialInvoices
      : commercialInvoicesData,
    dataType: 'commerical invoices'
  });

  const handleSort = sortDetails => {
    setSort({
      order: sortDetails.order,
      orderBy: sortDetails.orderBy
    });

    if (sortDetails && sortDetails.orderBy) {
      logEvent({
        categoryName: 'Sort',
        actionName: 'Sort in Related Order Docs',
        labelName: sortDetails.order === 'ASC' ? 'Up' : 'Down'
      });
    }
  };

  /**
   * Called when a document checkbox is clicked,
   * shows download button if documents have been selected.
   * @param {ProductDocument[] | GeneralDocument[]} selectedDocuments
   */
  const handleSelectionChange = selectedDocuments => {
    const hasSelectedDocs = selectedDocuments.length > 0;

    _setShowShareButton(
      selectedDocuments.every(doc => canShareDocument(userData, doc)) &&
        selectedDocuments.length > 0
    );
    _setShowDownloadButton(hasSelectedDocs);
    _setSelectedDocs(selectedDocuments);

    // Notify parent component of changes
    if (onShowShareButtonChange) onShowShareButtonChange(hasSelectedDocs);
    if (onShowDownloadButtonChange) onShowDownloadButtonChange(hasSelectedDocs);
    if (onSelectedDocumentsChange) onSelectedDocumentsChange(selectedDocuments);
  };

  const _getListOfDocuments = () => (
    <ListOfDocuments
      accountName={orderHeaderData?.orderHeader.accountName}
      pageName="order-documents"
      documents={documents}
      loading={
        packingSlipsLoading ||
        invoicesLoading ||
        commercialInvoicesLoading ||
        materialCodesLoading
      }
      purchaseOrderNumber={purchaseOrderNumber}
      thermoDrive
      sortFunc={handleSort}
      defaultLanguage={
        orderHeaderData &&
        orderHeaderData.orderHeader &&
        orderHeaderData.orderHeader.accountDefaultLanguage
      }
      onSelectionChange={selectedDocuments => {
        handleSelectionChange(selectedDocuments);
      }}
    />
  );

  /**
   * Determine if user is assigned to account
   * @returns {Boolean}
   */
  const _userIsAssignedToAccount = useCallback(() => {
    return isInternal(userData)
      ? true
      : userData &&
          userData.accountId &&
          userData.accountId.accountIds.includes(accountId);
  }, [userData, accountId]);

  /**
   * Log an analytics event if user is trying to access an account that is not theirs
   */
  useEffect(() => {
    if (!_userIsAssignedToAccount()) {
      logEvent({
        categoryName: 'Authentication Error',
        actionName: 'User attempted to access unassigned account'
      });
    }
  }, [_userIsAssignedToAccount]);

  if (!isEmbedded()) {
    return _userIsAssignedToAccount() ? (
      <OxCard
        header={{
          title: 'Order For',
          buttons: [
            () =>
              showShareButton ? (
                <DocumentActionButton
                  action="share"
                  documents={selectedDocs}
                  orderNumber={orderNumber}
                  purchaseOrderNumber={purchaseOrderNumber}
                  defaultLanguage={
                    orderHeaderData &&
                    orderHeaderData.orderHeader &&
                    orderHeaderData.orderHeader.accountDefaultLanguage
                  }
                />
              ) : null,
            () =>
              showDownloadButton ? (
                <DocumentActionButton
                  action="download"
                  documents={selectedDocs}
                />
              ) : null
          ],
          emphasizedTitle: `${
            orderHeaderLoading || orderHeaderError
              ? '...'
              : orderHeaderData &&
                orderHeaderData.orderHeader &&
                orderHeaderData.orderHeader.accountName
          }`
        }}
        id="orders"
        key={`orders-${orderNumber}`}
      >
        {_getListOfDocuments()}
      </OxCard>
    ) : (
      <Route component={NoMatch} />
    );
  }
  return <div>{_getListOfDocuments()}</div>;
};

export default OrderDocuments;
