import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { GET_KEWILL_ORDERS, GET_ORDER_LINE_ITEMS } from '../api/orders/query';
import { GET_USER_INFO } from '../api/user/query';
import OrdersByCustomer from '../components/partials/OrdersByCustomer';
import {
  isGraphqlArrayEmptyForNested,
  isInternal,
  matchExternalAccountId,
  getAccountIdFromParams,
  isShipmentOverdue
} from '../helpers';
import NoMatch from './NoMatch';
import { ORDER_PAGINATION_OPTIONS } from '../helpers/constants';
import usePagination from '../hooks/usePagination';
import '../styles/pages/_interior.scss';
import AppRoute from '../propTypes/AppRoute';
import * as segment from '../helpers/segmentLogger';

const DEFAULT_ROW = ORDER_PAGINATION_OPTIONS[0].optionValue;

const AllOrdersByCustomer = ({ route, orderStatus }) => {
  AllOrdersByCustomer.propTypes = {
    props: PropTypes.shape({
      route: PropTypes.shape(AppRoute),
      orderStatus: PropTypes.shape(String)
    })
  };

  const [
    combinedShipmentInformation,
    setCombinedShipmentInformation
  ] = useState({
    accountsByAccountID: { name: { value: '' } }
  });

  const { data: userData } = useQuery(GET_USER_INFO);

  const userHasMultipleAccounts = userData.accountId.accountIds.length > 1;

  const { accountIds } = userData.accountId;

  const {
    getOffset,
    getRowsPerPage,
    handleNextPageQueryCompletion
  } = usePagination(DEFAULT_ROW);

  const params = useParams();

  const _generateAccountIdParams = () => {
    if (isInternal) {
      return getAccountIdFromParams(params);
    }
    return userHasMultipleAccounts
      ? getAccountIdFromParams(params)
      : accountIds[0];
  };

  const getOrderWhereInput = () => {
    const orderStatusMap = {
      BOOKED: {
        orderStatus: 'BOOKED'
      },
      CLOSED: {
        orderStatusNot: 'BOOKED'
      }
    };
    const applyOrderStatus = orderStatus && orderStatusMap[orderStatus];
    return {
      accountId: _generateAccountIdParams(),
      where: {
        accountId: _generateAccountIdParams(),
        ...(applyOrderStatus || null)
      }
    };
  };

  const { loading, error, data } = useQuery(GET_ORDER_LINE_ITEMS, {
    variables: {
      ...getOrderWhereInput(),
      offset: getOffset(),
      rowsPerPage: getRowsPerPage()
    },
    skip:
      (isInternal(userData) && !getAccountIdFromParams(params)) ||
      (!isInternal(userData) &&
        !matchExternalAccountId(userData, params) &&
        userHasMultipleAccounts),
    onCompleted: orderData => {
      handleNextPageQueryCompletion(orderData);
    }
  });

  const {
    data: kewillData,
    loading: kewillLoading,
    error: kewillError
  } = useQuery(GET_KEWILL_ORDERS, {
    variables: {
      ...getOrderWhereInput(),
      offset: getOffset(),
      rowsPerPage: getRowsPerPage()
    },
    skip:
      (isInternal(userData) && !getAccountIdFromParams(params)) ||
      (!isInternal(userData) &&
        !matchExternalAccountId(userData, params) &&
        userHasMultipleAccounts),
    onCompleted: handleNextPageQueryCompletion
  });

  useEffect(
    () => {
      if (
        !loading &&
        !kewillLoading &&
        !error &&
        !kewillError &&
        !isGraphqlArrayEmptyForNested(data, 'orders') &&
        kewillData
      ) {
        const kewillOrderMap = kewillData.kewillInfo.kewillOrders.reduce(
          (kewillMap, kewillOrder) => {
            kewillMap.set(kewillOrder.orderNumber.value, kewillOrder);
            return kewillMap;
          },
          new Map()
        );

        // For each order in Oracle...
        const conjoinedData = data.orders
          ? data.orders.data.map(order => {
              // Check if kewill info exists for the oracle order
              const matchingKewillOrder = kewillOrderMap.get(
                order.orderNumber.value
              );
              // If so, use the shipping info from kewill
              if (
                matchingKewillOrder &&
                matchingKewillOrder.shipments &&
                matchingKewillOrder.shipments.length
              ) {
                return {
                  ...order,
                  shipments: matchingKewillOrder.shipments
                };
              }
              return order;
            })
          : [];
        if (data.orders) {
          data.orders.pageDetails.currentPage.toString();
          // Display the updated shipping information
          setCombinedShipmentInformation({
            ...data,
            orders: {
              data: conjoinedData,
              pageDetails: data.orders.pageDetails
            }
          });
        }
      }
    },
    // eslint-disable-next-line
    [loading, kewillLoading]
  );
  useEffect(() => {
    if (!loading && data && data.orders) {
      data.orders.data.forEach(order => {
        if (order.shipments && order.shipments.length < 1) {
          segment.logEvent({
            giDataAttr: 'orders__no-shipments-for-order',
            eventName: segment.eventTypes.orderShipment,
            eventData: { orderNumber: order.orderNumber.value }
          });
        }

        // Log an event for overdue shipments
        if (order.shipments && order.shipments.length) {
          order.shipments.forEach(shipment => {
            if (isShipmentOverdue(shipment)) {
              segment.logEvent({
                categoryName: 'Order Shipment',
                actionName: 'Shipment Past Due',
                labelName: order.orderNumber.value
              });
            }
          });
        }
      });
    }
  }, [loading, data, error, kewillLoading, kewillData, kewillError]);

  if (
    !isInternal(userData) &&
    getAccountIdFromParams(params) &&
    !matchExternalAccountId(userData, params)
  ) {
    return <NoMatch />;
  }

  return (
    <OrdersByCustomer
      loading={loading || kewillLoading}
      data={combinedShipmentInformation}
      error={error}
      parentCardTitle={route.title}
      defaultRow={DEFAULT_ROW}
      route={route}
    />
  );
};

export default withRouter(AllOrdersByCustomer);
