import { useState, useEffect } from 'react';

/**
 * Returns the element clicked or false depending on if the provided ref has been clicked outside of.
 */
const useDetectOutsideClick = ref => {
  const [hasClickedOutsideOfElement, setHasClickedOutsideOfElement] = useState(
    false
  );

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setHasClickedOutsideOfElement(event.target);
    }
    setHasClickedOutsideOfElement(false);
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return hasClickedOutsideOfElement;
};

export default useDetectOutsideClick;
