import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Link, useParams, useHistory } from 'react-router-dom';
import OxCard from '../molecules/OxCard';
import OxSearch from './OxSearch';
import '../../styles/pages/_interior.scss';
import OxSoftAlert from '../molecules/OxSoftAlert';
import OxLoaderOverlay from '../tokens/OxLoaderOverlay';
import { UnableToRetrieveData } from '../../helpers/placeholderHelpers';
import {
  ERROR_TITLE,
  LOADING_TITLE,
  LOADING_SUBTITLE,
  SEARCH_ORDER_PLACEHOLDER_SHORT,
  ORDER_PAGINATION_OPTIONS
} from '../../helpers/constants';
import ListOfOrders from './ListOfOrders';
import Order from '../../propTypes/Order';
import {
  isGraphqlArrayEmptyForNested,
  isInternal,
  matchExternalAccountId,
  getAccountIdFromParams
} from '../../helpers';
import { OxPagination } from '../tokens/OxPagination';
import { GET_USER_INFO } from '../../api/user/query';
import AppRoute from '../../propTypes/AppRoute';

/**
 * Component that displays a list of orders for by customer.
 */
const OrdersByCustomer = ({
  parentCardTitle,
  data,
  loading,
  error,
  defaultRow,
  route
}) => {
  OrdersByCustomer.propTypes = {
    loading: PropTypes.bool,
    parentCardTitle: PropTypes.string,
    defaultRow: PropTypes.number.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.shape({
        orders: PropTypes.arrayOf(PropTypes.shape({ ...Order })),
        accountsByAccountID: PropTypes.shape({
          name: PropTypes.shape({
            value: PropTypes.string
          }),
          accountId: PropTypes.string
        })
      }),
      PropTypes.shape({})
    ]),
    route: PropTypes.shape(AppRoute).isRequired
  };
  const history = useHistory();

  const { data: userData } = useQuery(GET_USER_INFO);

  const params = useParams();

  const _unableToFindCustomer = () => {
    return (
      !error &&
      data &&
      data.accountsByAccountID &&
      !data.accountsByAccountID.name.value
    );
  };

  const _getAccountName = () => {
    return !loading && !error ? data.accountsByAccountID.name.value : '';
  };

  const _getAlert = () => {
    if (!loading) {
      if (
        (!getAccountIdFromParams(params) && isInternal(userData)) ||
        (_unableToFindCustomer() && isInternal(userData))
      ) {
        return (
          <OxSoftAlert
            alertType="info"
            icon="none"
            giDataAttr="orders__no-customer-alert"
            eventName="noData"
          >
            <p>
              We could not find this customer. Please select a customer from
              the&nbsp;
              <Link className="tabbable" to="/customers">
                customer table
              </Link>
              &nbsp;to view open orders by a customer.
            </p>
          </OxSoftAlert>
        );
      }
      if (
        !isInternal(userData) &&
        matchExternalAccountId(userData, params) &&
        data
      ) {
        if (!data.orders || data.orders === undefined) {
          return (
            <OxSoftAlert
              alertType="info"
              icon="none"
              giDataAttr="orders__no-customer-alert"
              eventName="noData"
            >
              <p>
                We could not find this account. Please select a account from the
                account table to view open orders by a account.
              </p>
            </OxSoftAlert>
          );
        }
      }
      if (isGraphqlArrayEmptyForNested(data, 'orders')) {
        return (
          <OxSoftAlert
            title={`${_getAccountName()} Has No ${parentCardTitle}`}
            alertType="info"
            icon="none"
            giDataAttr="orders__no-orders-alert"
            isOutlined
            eventName="noData"
          >
            <p>
              This account currently has no {parentCardTitle.toLowerCase()}.
            </p>
          </OxSoftAlert>
        );
      }
      if (error) {
        return (
          <OxSoftAlert
            title={ERROR_TITLE}
            alertType="warning"
            giDataAttr="orders__network-error-alert"
            isOutlined
          >
            {UnableToRetrieveData('orders')}
          </OxSoftAlert>
        );
      }
      return null;
    }
    return null;
  };

  const _getParentCardTitle = () => {
    return loading || _getAlert() ? parentCardTitle : `${parentCardTitle} for `;
  };

  return (
    <main className="orders height-100">
      <OxCard
        helperClass={_getAlert() ? 'has-alert' : ''}
        contentFlush
        header={{
          title: `${_getParentCardTitle()} ${
            loading || _getAlert() ? '' : _getAccountName()
          }`,
          buttons: [
            () => (
              <div className="ox_card__search-box" key="orders-by-customer">
                <OxSearch
                  searchType="order"
                  name="customer-search"
                  searchPlaceholder={SEARCH_ORDER_PLACEHOLDER_SHORT}
                  label={SEARCH_ORDER_PLACEHOLDER_SHORT}
                  history={history}
                />
              </div>
            )
          ]
        }}
        footer={() =>
          data &&
          data.orders &&
          data.orders.data &&
          data.orders.data.length &&
          !error ? (
            <OxPagination
              route={route}
              currentPage={
                data && data.orders
                  ? data.orders.pageDetails.currentPage.toString()
                  : null
              }
              totalPages={
                data && data.orders ? data.orders.pageDetails.totalPages : null
              }
              defaultRow={defaultRow}
              options={ORDER_PAGINATION_OPTIONS}
              gaLabel="Orders by Account"
            />
          ) : null
        }
        id="orders"
        key={`orders-${Math.random()}`}
      >
        <OxLoaderOverlay
          isLoading={loading}
          title={LOADING_TITLE}
          subtitle={LOADING_SUBTITLE}
          giDataAttr="orders__loading-overlay"
        >
          {_getAlert() || <ListOfOrders loading={loading} data={data} />}
        </OxLoaderOverlay>
      </OxCard>
    </main>
  );
};

export default OrdersByCustomer;
