import { gql } from '@apollo/client';

const SHARE_DOCUMENT = gql`
  mutation emailDocument($data: ShareDocumentInput!) {
    emailDocument(data: $data) {
      status
    }
  }
`;

export default SHARE_DOCUMENT;
