import React from 'react';
import GlobalHeader from '../components/partials/GlobalHeader';
import GlobalFooter from '../components/partials/GlobalFooter';
import { OxNotification } from '../components/molecules/OxNotification';
import { OxModal } from '../components/molecules/OxModal';
import OxSoftAlert from '../components/molecules/OxSoftAlert';

const ErrorAlert = () => (
  <OxSoftAlert
    title='Could not complete request'
    alertType='warning'
    isOutlined
    eventName='error'
  >
    <p>
      Sorry, we could not complete your request at this time. Please
      try again in a few minutes. If you need immediate assistance, please
      contact your&nbsp;
      <a
        target="_blank"
        rel="noreferrer"
        className="tabbable"
        href="https://www.intralox.com/support/phone-numbers/united-states"
      >
        customer service representative
      </a>
      .
    </p>
  </OxSoftAlert>
);

const ErrorPage = () => (
  <OxModal>
    <OxNotification>
      <GlobalHeader
        title="Intralox - A world leading conveyer belt company."
        links={[]}
        callOutLinks
      />
      <main className="container-fluid height-50">
        <div className="units-row justify-center">
          <div className="unit-60">
            <ErrorAlert />
          </div>
        </div>
      </main>
      <GlobalFooter />
    </OxNotification>
  </OxModal>
);

export default ErrorPage;
