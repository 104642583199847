import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { MAIN_CONTENT, ORDER_PAGINATION_OPTIONS } from '../helpers/constants';
import { isGraphqlArrayEmptyForNested } from '../helpers';

const usePagination = defaultRow => {
  usePagination.propTypes = {
    defaultRow: PropTypes.number.isRequired
  };

  const queryParams = new URLSearchParams(useLocation().search);
  const { pathname } = useLocation();
  const history = useHistory();

  const getOffset = useCallback(
    () =>
      queryParams.get('offset') ? parseInt(queryParams.get('offset'), 10) : 0,
    [queryParams]
  );

  const getRowsPerPage = useCallback(
    () =>
      queryParams.get('rowsPerPage')
        ? parseInt(queryParams.get('rowsPerPage'), 10)
        : defaultRow,
    [queryParams, defaultRow]
  );

  const handleNextPageQueryCompletion = queriedData => {
    // If the user has reached a page that does not exist
    // by changing the url instead of using the UI
    if (
      isGraphqlArrayEmptyForNested(queriedData, 'orders') &&
      getOffset() !== 0
    ) {
      history.push(`${pathname}?offset=0&rowsPerPage=${defaultRow}`);
    }
    if (getOffset() !== 0) {
      document.getElementById(MAIN_CONTENT).scrollIntoView();
    }
  };

  useEffect(() => {
    // If the offset or rowsPerPage are invalid
    if (
      getOffset() < 0 ||
      getRowsPerPage() <= 0 ||
      getRowsPerPage() >
        ORDER_PAGINATION_OPTIONS[ORDER_PAGINATION_OPTIONS.length - 1]
          .optionValue
    ) {
      history.push(`${pathname}?offset=0&rowsPerPage=${defaultRow}`);
    }
  }, [defaultRow, getOffset, getRowsPerPage, history, pathname, queryParams]);

  return { getOffset, getRowsPerPage, handleNextPageQueryCompletion };
};

export default usePagination;
