import React from 'react';
import { useModal } from '../components/molecules/OxModal';
import OxButton from '../components/molecules/OxButton';

const useConfirmationModal = () => {
  const { showModal, hideModal } = useModal();
  const FIRST_ELEMENT = 'ox-modal__primary-btn';
  const LAST_ELEMENT = 'ox-modal__secondary-btn';

  const showConfirmation = ({
    message,
    header,
    buttonProps,
    icon,
    giDataAttr
  }) => {
    const confirmation = (
      <div>
        <p className="ox-modal__message">{message}</p>
        <div className="display--flex nowrap ox-modal__buttons">
          {/* Mapping items does not work with tab trap hook. */}
          {buttonProps && buttonProps[0] ? (
            <OxButton {...buttonProps[0]} id={FIRST_ELEMENT} />
          ) : null}
          {buttonProps && buttonProps[1] ? (
            <OxButton {...buttonProps[1]} id={LAST_ELEMENT} />
          ) : null}
        </div>
      </div>
    );

    showModal({
      childElement: confirmation,
      header,
      giDataAttr,
      icon,
      firstElementId: FIRST_ELEMENT,
      lastElementId: LAST_ELEMENT
    });
  };

  const hideConfirmation = () => {
    hideModal();
  };

  return { showConfirmation, hideConfirmation };
};

export default useConfirmationModal;
