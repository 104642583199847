import PropTypes from 'prop-types';

const Header = {
  displayValue: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  sortable: PropTypes.bool
};

export default Header;
