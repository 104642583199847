import PropTypes from 'prop-types';
import { StringValue } from './Value';
import Url from './Url';

const Account = {
  industry: PropTypes.shape(StringValue),
  name: PropTypes.shape({
    value: PropTypes.string,
    url: PropTypes.shape({ ...Url })
  }),
  openOrders: PropTypes.shape({
    value: PropTypes.number,
    url: PropTypes.shape({ ...Url })
  }),
  segment: PropTypes.shape(StringValue),
  totalOrders: PropTypes.shape({
    value: PropTypes.number,
    url: PropTypes.shape(Url)
  }),
  _id: PropTypes.shape(StringValue)
};

export default Account;
