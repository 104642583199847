import { gql } from '@apollo/client';

export const GET_APPROVED_EMAILS = gql`
query {
 approvedEmails {
    email
    role
 }
}
`;
