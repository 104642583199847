import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { CREATE_USER } from '../api/user/mutation';
import OxCard from '../components/molecules/OxCard';
import OxInput from '../components/tokens/forms/OxInput';
import OxToggle from '../components/molecules/OxToggle';
import OxButton from '../components/molecules/OxButton';
import { OxNotificationContext } from '../components/molecules/OxNotification';
import PermissionMgmtPills from '../components/partials/PermissionMgmtPills';
import { getRandomAlphanumericString } from '../helpers';

const CreateUser = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password] = useState(getRandomAlphanumericString(24));
  const [originUrls, setOriginUrls] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [userType, setUserType] = useState(new Map());
  const [userRole, setUserRole] = useState(new Map());
  const [userFeatures, setUserFeatures] = useState(new Map());
  const { showNotification, hideNotification } = useContext(
    OxNotificationContext
  );
  const history = useHistory();

  /**
   * Mutation for creating user
   */
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: () => {
      const isApiUser = Array.from(userType.values())[0] === 'api';

      history.push(
        isApiUser
          ? '/mgmt/api-users?create-success=true'
          : '/mgmt/users?create-success=true'
      );
    }
  });

  /**
   * Handle form submission
   * @param {Event} e
   */
  const handleFormSubmit = e => {
    e.preventDefault();

    // Save user to DB
    createUser({
      variables: {
        data: {
          name,
          email,
          password,
          active: isActive,
          userType: Array.from(userType.keys())[0],
          userRole: Array.from(userRole.keys())[0],
          additionalFeatures: Array.from(userFeatures.keys()),
          ...(originUrls.length && {
            originUrls: originUrls.replaceAll(' ', '').split(',')
          })
        }
      }
    });
  };

  return (
    <OxCard header={{ title: 'Add User' }}>
      <form>
        <fieldset>
          <div className="units-row">
            <div className="unit-33">
              <OxInput
                required
                noAnalytics
                value={name}
                floatLabel={false}
                giDataAttr="Text"
                name="text"
                inputType="text"
                label="Name"
                handleKeyUp={e => setName(e.target.value)}
              />
            </div>
            <div className="unit-33">
              <OxInput
                required
                noAnalytics
                value={email}
                floatLabel={false}
                giDataAttr="Text"
                name="text"
                inputType="text"
                label="Email"
                handleKeyUp={e => setEmail(e.target.value)}
              />
            </div>
            <div className="unit-33">
              <OxToggle
                clickFunc={() => {
                  setIsActive(!isActive);
                }}
                title={isActive ? 'Active' : 'Inactive'}
                checked={isActive}
              />
            </div>
          </div>
          <div className="units-row">
            <div className="unit-50">
              <OxInput
                readOnly
                noAnalytics
                value={password}
                floatLabel={false}
                giDataAttr="Text"
                name="text"
                inputType={showPassword ? 'text' : 'password'}
                label="Password"
                tooltipContent={`
                After creating this user, you will not be able to view this password.
                `}
              />
            </div>
            <div className="unit-50">
              <div className="units-row">
                <div className="unit-50 margin-top">
                  <OxButton
                    element="span"
                    text="Copy to Clipboard &nbsp;"
                    helperClass="margin-top"
                    clickFunc={() => {
                      navigator.clipboard.writeText(password).then(() => {
                        showNotification(
                          'Success',
                          'Successfully copied to clipboard',
                          'clipboard'
                        );
                        setTimeout(() => {
                          hideNotification();
                        }, 2000);
                      });
                    }}
                    withIcon={{ icon: 'copy' }}
                    giDataAttr="copy"
                  />
                </div>
                <div className="unit-50">
                  <OxToggle
                    clickFunc={() => {
                      setShowPassword(!showPassword);
                    }}
                    title="Show Password"
                    checked={showPassword}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="units-row">
            <div className="unit-50">
              <OxInput
                noAnalytics
                value={originUrls}
                floatLabel={false}
                giDataAttr="Text"
                name="text"
                inputType="text"
                label="Origin URLs (Seperated by comma)"
                handleKeyUp={e => setOriginUrls(e.target.value)}
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <PermissionMgmtPills
            hasOnlyOneUserType
            hasOnlyOneUserRole
            selectedFeatures={userFeatures}
            selectedUserTypes={userType}
            selectedUserRoles={userRole}
            handleUserRoleChange={val => {
              setUserRole(val);
            }}
            handleUserTypeChange={val => {
              setUserType(val);
            }}
            handleFeatureChange={val => {
              setUserFeatures(val);
            }}
          />
        </fieldset>
        <fieldset>
          <OxButton
            id="save-user"
            giDataAttr="save"
            text="Save"
            buttonType="submit"
            element="button"
            type="blue"
            clickFunc={handleFormSubmit}
          />
        </fieldset>
      </form>
    </OxCard>
  );
};

export default CreateUser;
