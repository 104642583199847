import Interior from '../../pages/Interior';
import AllOrdersByCustomer from '../../pages/AllOrdersByCustomer';
import OrderSearchResult from '../../pages/OrderSearchResult';
import OrderDocuments from '../../pages/OrderDocuments';
import { orders, accounts } from '../../helpers/authHelpers';
import { isExternalUser, userIsAdmin } from '../../helpers';
import NoMatch from '../../pages/NoMatch';
import PendingOrders from '../../pages/PendingOrders';
import ProcessedOrders from '../../pages/ProcessedOrders';
import TotalOrders from '../../pages/TotalOrders';

/**
 * Home.js wil run this function to determine if a user can access these routes.
 * If a user has orders, they should be able to see accounts.
 * @param {*} user
 */
const userHasOrdersOrAccounts = user => {
  return (
    userIsAdmin(user) ||
    [orders, accounts].some(authFeature => {
      return (
        user.userInfo.allFeatures.findIndex(
          feature => feature.name === authFeature
        ) !== -1
      );
    })
  );
};

const myOrdersRoutes = {
  key: 'orders',
  icon: 'list',
  title: 'My Orders',
  path: '/my-orders',
  isPrivate: true,
  giDataAttr: 'ordersLink',
  component: Interior,
  routes: [
    {
      key: 'Total-orders',
      title: 'Total Orders',
      path: '/my-orders',
      exact: true,
      isPrivate: true,
      component: TotalOrders,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      isActiveForRoutes: ['/my-orders/total-orders', '/my-orders/account/']
    },
    {
      key: 'my-processed-orders',
      title: 'Processed Orders',
      path: '/my-orders/processed-orders',
      exact: true,
      isPrivate: true,
      component: ProcessedOrders,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      isActiveForRoutes: ['/my-orders/processed-orders'],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    },
    {
      key: 'my-pending-orders',
      title: 'Pending Orders',
      path: '/my-orders/pending-orders',
      exact: true,
      isPrivate: true,
      component: PendingOrders,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      isActiveForRoutes: ['/my-orders/pending-orders'],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    },
    {
      key: 'total-orders',
      title: 'Total Orders',
      hideInInteriorNav: true,
      path: '/my-orders/account/:accountId',
      exact: true,
      isPrivate: true,
      component: AllOrdersByCustomer,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    },
    {
      key: 'order-search-results',
      title: 'Order Details',
      path: '/my-orders/details',
      exact: true,
      isPrivate: true,
      component: OrderSearchResult,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      isActiveForRoutes: [/\/my-orders\/details\/(\d+|.+%40)/],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    },
    {
      key: 'customer-order-documents',
      title: 'Order Documents',
      path: '/my-orders/details/documents',
      exact: true,
      isPrivate: true,
      component: NoMatch,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      hideInInteriorNav: true
    },
    {
      key: 'customer-order-documents',
      title: 'Order Documents',
      path: '/my-orders/details/documents/:orderNumber',
      exact: true,
      isPrivate: true,
      component: OrderDocuments,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      hideInInteriorNav: true
    },
    {
      key: 'customer-order-documents',
      title: 'Order Documents',
      path: '/my-orders/details/documents/:orderNumber/:accountId',
      exact: true,
      isPrivate: true,
      component: OrderDocuments,
      backButtonTitle: 'Order Details',
      backButtonPath: locationUrlDetails => {
        locationUrlDetails.splice(3, 1);
        return locationUrlDetails.join('/');
      },
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to order details',
        labelName: 'Order Details'
      },
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      hideInInteriorNav: true
    },
    {
      key: 'order-search-results',
      title: 'Order Search Results',
      path: '/my-orders/details/:orderId',
      exact: true,
      isPrivate: true,
      component: OrderSearchResult,
      hideInInteriorNav: true,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    },
    {
      key: 'order-search-results',
      title: 'Order Search Results',
      path: '/my-orders/details/:orderId/:accountId',
      exact: true,
      isPrivate: true,
      component: OrderSearchResult,
      hideInInteriorNav: true,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    },
    {
      key: 'pending-orders',
      title: 'Pending Orders',
      hideInInteriorNav: true,
      path: '/my-orders/pending-orders/account/:accountId',
      exact: true,
      isPrivate: true,
      component: PendingOrders,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    },
    {
      key: 'processed-orders',
      title: 'Processed Orders',
      hideInInteriorNav: true,
      path: '/my-orders/processed-orders/account/:accountId',
      exact: true,
      isPrivate: true,
      component: ProcessedOrders,
      authFunctions: [userHasOrdersOrAccounts],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to my orders',
        labelName: 'My Orders'
      }
    }
  ],
  authFunctions: [userHasOrdersOrAccounts],
  shouldGoToNoMatchIfNoAccess: [isExternalUser]
};

export default myOrdersRoutes;
