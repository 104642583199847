import { gql } from '@apollo/client';

const GET_ORDER_LINE_ITEMS = gql`
  query(
    $accountId: String
    $where: OrdersWhereInput
    $offset: Int
    $rowsPerPage: Int
  ) {
    accountsByAccountID(accountId: $accountId) {
      accountId
      name {
        value
      }
      _id {
        value
      }
    }
    orders(where: $where, offset: $offset, rowsPerPage: $rowsPerPage) {
      pageDetails {
        totalPages
        hasNextPage
        currentPage
      }
      data {
        _id {
          value
        }
        invoices
        accountId
        accountName {
          value
        }
        orderNumber {
          value
        }
        shipmentCount {
          value
        }
        orderPlacedDate {
          value
        }
        orgId
        purchaseOrderId {
          value
        }
        expectedShipmentDate
        shipments {
          isCurrentTracking
          _id {
            value
          }
          invoices
          shipmentDate {
            value
          }
          expectedDeliveryDate {
            value
          }
          orderStatus {
            value
          }
          deliveryStatus {
            value
          }
          trackingNumber {
            value
            url {
              value
              iconState
            }
          }
          carrier: shippingService {
            value
          }
        }
      }
    }
  }
`;

const GET_ORDERS_SEARCH = gql`
  query orderSearch($where: OrdersWhereInput, $offset: Int, $rowsPerPage: Int) {
    orders(where: $where, offset: $offset, rowsPerPage: $rowsPerPage) {
      data {
        _id {
          value
        }
        invoices
        accountId
        accountName {
          value
        }
        orderNumber {
          value
        }
        orderPlacedDate {
          value
        }
        orgId
        purchaseOrderId {
          value
        }
        shipmentCount {
          value
        }
        expectedShipmentDate
        shipments {
          isCurrentTracking
          _id {
            value
          }
          invoices
          shipmentDate {
            value
          }
          expectedDeliveryDate {
            value
          }
          orderStatus {
            value
          }
          deliveryStatus {
            value
          }
          trackingNumber {
            value
            url {
              value
              iconState
            }
          }
          carrier: shippingService {
            value
          }
        }
      }
    }
  }
`;

const GET_ORDERS_LINE_ITEMS_BY_REP = gql`
  query getOrderLineItems(
    $where: OrdersWhereInput
    $offset: Int
    $rowsPerPage: Int
    $accountIdOffset: [accountIdOffset]
  ) {
    orders(
      where: $where
      offset: $offset
      rowsPerPage: $rowsPerPage
      accountIdOffset: $accountIdOffset
    ) {
      pageDetails {
        totalPages
        hasNextPage
        currentPage
      }
      data {
        _id {
          value
        }
        accountId
        accountName {
          value
        }
        orderNumber {
          value
          url {
            value
          }
        }
        orgId
        orderPlacedDate {
          value
        }
        purchaseOrderId {
          value
        }
        orderStatus {
          value
        }
        shipmentCount {
          value
        }
        expectedShipmentDate
        shipments {
          isCurrentTracking
          _id {
            value
          }
          invoices
          shipmentDate {
            value
          }
          expectedDeliveryDate {
            value
          }
          orderStatus {
            value
          }
          deliveryStatus {
            value
          }
          trackingNumber {
            value
            url {
              value
              iconState
            }
          }
          carrier: shippingService {
            value
          }
        }
      }
    }
  }
`;

const GET_ORDER_HEADER = gql`
  query($where: OrderHeaderInput) {
    orderHeader(where: $where) {
      orderNumber
      orgId
      orderedDate
      purchaseOrderId
      invoices
      accountName
      accountDefaultLanguage
    }
  }
`;

const GET_KEWILL_ORDERS = gql`
  query getKewillOrders(
    $where: OrdersWhereInput
    $offset: Int
    $rowsPerPage: Int
  ) {
    kewillInfo(where: $where, offset: $offset, rowsPerPage: $rowsPerPage) {
      kewillOrders {
        _id {
          value
        }
        accountId
        accountName {
          value
        }
        orderNumber {
          value
        }
        orderPlacedDate {
          value
        }
        purchaseOrderId {
          value
        }
        shipmentCount {
          value
        }
        shipments {
          isCurrentTracking
          _id {
            value
          }
          invoices
          shipmentDate {
            value
          }
          expectedDeliveryDate {
            value
          }
          orderStatus {
            value
          }
          deliveryStatus {
            value
          }
          trackingNumber {
            value
            url {
              value
              iconState
            }
          }
          carrier: shippingService {
            value
          }
        }
      }
    }
  }
`;

export {
  GET_ORDERS_SEARCH,
  GET_ORDER_LINE_ITEMS,
  GET_ORDERS_LINE_ITEMS_BY_REP,
  GET_ORDER_HEADER,
  GET_KEWILL_ORDERS
};
