import { useState, useEffect } from 'react';

/**
 * Returns a boolean if any activity has happened since reset
 */

const useDetectActivity = () => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key }) => {
    if (key) {
      setKeyPressed(true);
    }
  };

  const clickHandler = click => {
    if (click) {
      setKeyPressed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('click', clickHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('click', clickHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const resetDetectActivity = () => {
    setKeyPressed(false);
  };

  return { isActive: keyPressed, resetDetectActivity };
};

export default useDetectActivity;
