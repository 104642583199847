import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { isExternalUrl } from '../../helpers';
import '../../styles/components/_ox-table.scss';
import OxLoader from '../tokens/OxLoader';
import Header from '../../propTypes/Header';
import OxTabbable from './OxTabbable';
import OxButton from './OxButton';

const isIconWithoutText = (datum, header) =>
  !!(!datum[header.key].value && datum[header.key].iconName);

const getIconLinkStyles = (datum, header) =>
  isIconWithoutText(datum, header)
    ? 'ox-table__link-icon-svg--no-text'
    : 'ox-table__link-icon-svg';

const isString = val => {
  return typeof val === 'string' || val instanceof String;
};

const OxTable = ({
  data,
  giDataAttr,
  helperClass,
  noAnalytics,
  loading,
  loadingTitle,
  sortFunc
}) => {
  OxTable.propTypes = {
    data: propTypes.shape({
      data: propTypes.arrayOf(
        propTypes.shape({
          url: propTypes.shape(
            propTypes.shape({
              iconName: propTypes.string,
              value: propTypes.func
            })
          )
        })
      ),
      headers: propTypes.arrayOf(propTypes.shape(Header))
    }),
    noAnalytics: propTypes.bool,
    giDataAttr: propTypes.string,
    helperClass: propTypes.string,
    loading: propTypes.bool,
    loadingTitle: propTypes.string,
    sortFunc: propTypes.func
  };

  const [sort, setSort] = useState({});

  /**
   * Updates the state of the sort for the table
   * @param {string} key - the header key we are sorting on
   */
  const handleSort = (order, orderBy) => {
    const isToggle = sort.orderBy === orderBy;
    let sortDirection;

    // Flip the sort direction if we're toggling a column, otherwise use the given order
    if (isToggle) {
      sortDirection = sort.order === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortDirection = order;
    }

    setSort({
      order: sortDirection,
      orderBy
    });
  };

  /**
   * Calls the sort function whenever the sort state changes
   */
  useEffect(() => {
    if (sort.order && sort.orderBy && sortFunc) {
      sortFunc(sort);

      // set focus on the sort arrow so the user can repeatedly toggle by pressing the Enter key
      const sortDirectionArrow = document.getElementById(
        `sort-${sort.orderBy}-${sort.order.toLowerCase()}`
      );
      if (sortDirectionArrow) {
        sortDirectionArrow.focus();
      }
    }
    // eslint-disable-next-line
  }, [sort]);

  /**
   * Format table data by:
   *
   * 1. Set table values that are null, undefined or empty strings as N/A.
   * 2. Set table values that are of type string to lowercase. Text is capitalized
   * via css.
   */
  const _formatContent = dataToFormat => {
    if (
      (dataToFormat !== 0 && !dataToFormat) ||
      (dataToFormat &&
        isString(dataToFormat) &&
        (dataToFormat.toLowerCase() === 'undefined' || dataToFormat === ''))
    ) {
      return 'N/A';
    }
    return dataToFormat;
  };

  /**
   * Determines if a column is sortable
   * Return true if a column has more than 1 differing value
   * i.e. [1,1,1,1] would return false, but [1,1,2,1] would return true
   *
   * @param {String} key - the column we are checking for sortability
   */
  const columnIsSortable = key => {
    const columnData = data.data.map(item => item[key].value);

    return !columnData.every((value, idx, arr) => value === arr[0]);
  };

  const _renderHeaders = () => {
    const { headers } = data;

    const theads = headers.map(({ key, displayValue, sortable }, index) => {
      const textAlignmentClass =
        index !== 0 && key !== 'name' ? 'align-end' : '';
      const isSortable = !!sortable && columnIsSortable(key);

      return (
        <th
          scope="col"
          key={key}
          className={`nowrap ${isSortable ? 'sortable' : ''}`}
        >
          <div
            className={`units-row vertical-bottom oxtable__header--text ${textAlignmentClass}`}
          >
            <span>{displayValue}</span>
            {isSortable && (
              <div className="oxtable__header--sort-indicator margin-left">
                {/* Show an arrow indicating the sort direction */}
                {sort.order && sort.orderBy === key && (
                  <OxButton
                    text={
                      sort.order === 'ASC'
                        ? String.fromCharCode(9650)
                        : String.fromCharCode(9660)
                    }
                    element="button"
                    id={`sort-${key}-${sort.orderBy.toLowerCase()}`}
                    giDataAttr={`oxtable__button__sort-${sort.orderBy.toLowerCase()}`}
                    clickFunc={() => {
                      handleSort(sort.orderBy, key);
                    }}
                  />
                )}
                {/* Both arrows if this header is not the active sort */}
                {(!sort.order || sort.orderBy !== key) && (
                  <div>
                    <OxButton
                      text={String.fromCharCode(9650)}
                      element="button"
                      id={`sort-${key}-asc`}
                      giDataAttr="oxtable__button__sort-asc"
                      clickFunc={() => {
                        handleSort('ASC', key);
                      }}
                    />
                    <OxButton
                      text={String.fromCharCode(9660)}
                      element="button"
                      id={`sort-${key}-desc`}
                      giDataAttr="oxtable__button__sort-desc"
                      clickFunc={() => {
                        handleSort('DESC', key);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </th>
      );
    });

    return (
      <thead>
        <tr>{theads}</tr>
      </thead>
    );
  };

  /**
   * Determine content for internal link.
   *
   * If there is no text and an icon exists, only display icon.
   * If no icon exists, display text.
   */
  const _displayInternalLinkText = (datum, header) => {
    return isIconWithoutText(datum, header) ? null : (
      <span className="bold">{_formatContent(datum[header.key].value)}</span>
    );
  };

  const _getUrl = (datum, header, isFirstColumn, rowIndex) => {
    const { url } = datum[header.key];
    return isExternalUrl(url.value) ? (
      <OxTabbable>
        <a
          data-gi={url.giDataAttr ? url.giDataAttr : ''}
          aria-label={`${header.key} row ${rowIndex}`}
          rel="noopener noreferrer"
          target="_blank"
          href={url.value}
          onClick={url.onClick || null}
          className={
            isFirstColumn
              ? 'ox-table__link ox-table__link--external-link'
              : 'ox-table__link ox-table__link--reverse ox-table__link--external-link'
          }
        >
          <span className="ox-table__link-text">
            {_formatContent(datum[header.key].value)}
          </span>
        </a>
      </OxTabbable>
    ) : (
      <OxTabbable>
        <Link
          aria-label={`${header.key} row ${rowIndex}`}
          to={url.value}
          className={
            isFirstColumn
              ? 'ox-table__link'
              : 'ox-table__link ox-table__link--reverse'
          }
        >
          {_displayInternalLinkText(datum, header)}
          <span
            className={`ox-table__link-icon ${url.iconState === 'inactive' &&
              'ox-table__link-icon--inactive'}`}
          >
            <FeatherIcon
              className={`${getIconLinkStyles(datum, header)} margin-right`}
              icon={
                datum[header.key].iconName
                  ? datum[header.key].iconName.value
                  : 'external-link'
              }
            />
          </span>
        </Link>
      </OxTabbable>
    );
  };

  const _getDownload = (datum, header) => {
    const { download } = datum[header.key];
    return (
      <a
        className="ox-table__link"
        title={datum[header.key].value}
        href={download.value}
        onClick={download.onClick || null}
        target="_blank"
        rel="noopener noreferrer"
        data-gi={download.giDataAttr}
        download
      >
        <div className="units-row flex-no-wrap">
          <div className="unit-15 no-left-padding">
            <FeatherIcon icon="download-cloud" size="18" />
          </div>
          <div className="unit-85 text-left">
            <span className="bold">
              {_formatContent(datum[header.key].value)}
            </span>
          </div>
        </div>
      </a>
    );
  };

  const _getCellData = (datum, header, isFirstColumn, rowIndex) => {
    // If a valid React Element is passed in, display that.
    if (datum[header.key] && datum[header.key].func) {
      return datum[header.key].func(datum);
    }
    if (datum[header.key] && datum[header.key].url) {
      return _getUrl(datum, header, isFirstColumn, rowIndex);
    }
    if (datum[header.key] && datum[header.key].download) {
      return _getDownload(datum, header, isFirstColumn);
    }
    return datum && datum[header.key]
      ? _formatContent(datum[header.key].value)
      : null;
  };

  const _renderRow = () => {
    const { headers, data: rowData } = data;
    if (!rowData) {
      return null; // If no data is passed, rows don't get shown.
    }

    return (
      <tbody
        heap-ignore={noAnalytics ? 'true' : null}
        data-hj-suppress={noAnalytics ? 'true' : null}
      >
        {rowData.map((datum, rowIndex) => {
          return (
            <tr key={`row_${datum._id.value || Math.random()}`}>
              {headers.map((header, index) => {
                const isFirstColumn = index === 0;
                return (
                  <td
                    key={`${datum._id.value}_${header.key}`}
                    className={isFirstColumn ? null : 'text-right'}
                  >
                    {_getCellData(datum, header, isFirstColumn, rowIndex)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div
      className={`ox-table-wrapper${helperClass ? ` ${helperClass}` : ''}`}
      data-gi={`${giDataAttr}-wrapper`}
    >
      <table className="ox-table" data-gi={`${giDataAttr}`}>
        {data && data.data && data.data.length ? _renderHeaders() : null}
        {loading ? (
          <tbody>
            <tr className="ox-table-wrapper__loader">
              <td colSpan={data.headers.length}>
                <OxLoader title={loadingTitle} />
              </td>
            </tr>
          </tbody>
        ) : null}
        {_renderRow()}
      </table>
    </div>
  );
};

export default OxTable;
