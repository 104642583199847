import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import OxButton from '../molecules/OxButton';
import OxCard from '../molecules/OxCard';
import OxInput from '../tokens/forms/OxInput';
import OxSelect from '../tokens/forms/OxSelect';
import { LOADING_TITLE, LOADING_SUBTITLE } from '../../helpers/constants';
import OxLoaderOverlay from '../tokens/OxLoaderOverlay';
import { getAnnouncementMutationSuccessAlert } from '../../helpers';
import OxSoftAlert from '../molecules/OxSoftAlert';
import PermissionMgmtPills from './PermissionMgmtPills';
import useDirtyFormModal from '../../hooks/useDirtyFormModal';

const ANNOUNCEMENT_FORM_ID = 'announcement-form';

const announcementTypeOptions = [
  {
    optionText: 'User Role',
    optionValue: 'USER_ROLE'
  },
  {
    optionText: 'Order Information',
    optionValue: 'ORDER_INFORMATION'
  },
  {
    optionText: 'Document Information',
    optionValue: 'DOCUMENT_INFORMATION'
  },
  {
    optionText: 'Release Update',
    optionValue: 'RELEASE_UPDATE'
  },
  {
    optionText: 'Product Announcement',
    optionValue: 'PRODUCT_ANNOUNCEMENT'
  }
];

const getEndOfDay = date => {
  if (date) {
    return new Date(new Date(date).setUTCHours(23, 59, 59, 59)).toISOString();
  }
  return '';
};

const AnnouncementForm = ({
  title,
  body,
  expireAt,
  type,
  selectedFeatures,
  selectedUserRoles,
  selectedUserTypes,
  handleBodyInput,
  handleExpireAtInput,
  handleTypeInput,
  handleUserRoleChange,
  handleUserTypeChange,
  handleFeatureChange,
  handleSubmit,
  handleSubmitAndNew,
  hasError,
  loading
}) => {
  AnnouncementForm.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    body: PropTypes.string,
    expireAt: PropTypes.string,
    selectedFeatures: PropTypes.shape(new Map()),
    selectedUserRoles: PropTypes.shape(new Map()),
    selectedUserTypes: PropTypes.shape(new Map()),
    handleBodyInput: PropTypes.func,
    handleExpireAtInput: PropTypes.func,
    handleTypeInput: PropTypes.func,
    handleUserRoleChange: PropTypes.func,
    handleUserTypeChange: PropTypes.func,
    handleFeatureChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleSubmitAndNew: PropTypes.func,
    hasError: PropTypes.bool,
    loading: PropTypes.bool
  };
  const queryParams = new URLSearchParams(useLocation().search);
  const [isFormDirty, _setIsFormDirty] = useState(false);
  const [isFormSubmitted, _setIsFormSubmitted] = useState(false);

  const _isFormValid = useCallback(() => {
    return !!(body.trim() && new Date() <= new Date(expireAt) && type);
  }, [body, type, expireAt]);

  /**
   * Handle click events for save and save + new.
   */
  const _handleFormButtonSubmit = handleBtnClick => {
    if (_isFormValid()) {
      _setIsFormSubmitted(true);
      handleBtnClick();
    }
  };

  useDirtyFormModal({ isFormDirty, handleSubmit: _handleFormButtonSubmit });

  /**
   * Handle form input events.
   * i.e. body, type, etc.
   */
  const _handleFormInputEvent = handleEventFunc => {
    _setIsFormDirty(true);
    return handleEventFunc;
  };

  return (
    <OxCard id={title} header={{ title }}>
      <OxLoaderOverlay
        isLoading={loading}
        title={LOADING_TITLE}
        subtitle={LOADING_SUBTITLE}
        giDataAttr={`${title}__loading-overlay`}
      >
        {hasError ? (
          <OxSoftAlert
            alertType="warning"
            id={`${title}__announcement-form`}
            giDataAttr={`${title}__announcement-form-alert`}
            title={`An Error Occurred Attempting to ${title}`}
            isOutlined
          />
        ) : (
          getAnnouncementMutationSuccessAlert(queryParams)
        )}
        <form id={ANNOUNCEMENT_FORM_ID} onSubmit={e => e.preventDefault()}>
          <fieldset className="ox-fieldset">
            <div className="units-row">
              <div className="unit-33 margin-bottom">
                <OxInput
                  required={!isFormSubmitted}
                  noAnalytics
                  value={body}
                  handleKeyUp={e =>
                    _handleFormInputEvent(handleBodyInput)(e.target.value)
                  }
                  placeholder="Please Select"
                  floatLabel={false}
                  giDataAttr="Text"
                  name="text"
                  inputType="text"
                  label="Body"
                />
              </div>
              <div className="unit-33 margin-bottom">
                <OxInput
                  minDate={new Date().toISOString().split('T')[0]}
                  noAnalytics
                  label="Expiration Date"
                  name="expDate"
                  required={!isFormSubmitted}
                  giDataAttr="expDate"
                  floatLabel={false}
                  value={
                    expireAt
                      ? new Date(expireAt).toISOString().split('T')[0]
                      : ''
                  }
                  handleKeyUp={e =>
                    _handleFormInputEvent(handleExpireAtInput)(
                      getEndOfDay(e.target.value)
                    )
                  }
                  placeholder="Please Select"
                  inputType="date"
                />
              </div>
              <div className="unit-33 margin-bottom">
                <OxSelect
                  name="type"
                  floatLabel={false}
                  required={!isFormSubmitted}
                  label="Type"
                  options={announcementTypeOptions}
                  value={type}
                  placeholder={type ? null : 'Please Select'}
                  handleKeyUp={e =>
                    _handleFormInputEvent(handleTypeInput)(e.target.value)
                  }
                />
              </div>
            </div>
          </fieldset>
          <PermissionMgmtPills
            selectedFeatures={selectedFeatures}
            selectedUserTypes={selectedUserTypes}
            selectedUserRoles={selectedUserRoles}
            handleUserRoleChange={val =>
              _handleFormInputEvent(handleUserRoleChange)(val)
            }
            handleUserTypeChange={val =>
              _handleFormInputEvent(handleUserTypeChange)(val)
            }
            handleFeatureChange={val =>
              _handleFormInputEvent(handleFeatureChange)(val)
            }
          />
          <fieldset className="ox-fieldset">
            <div className="display--flex nowrap">
              <OxButton
                id="save-button"
                giDataAttr={`ox-fieldset__${title}-save-button`}
                text="Save"
                buttonType="submit"
                element="button"
                type="blue"
                clickFunc={() => _handleFormButtonSubmit(handleSubmit)}
                helperClass={`ox-fieldset__save-button
                ${!_isFormValid() ? 'btn-disabled' : ''}`}
              />
              {handleSubmitAndNew ? (
                <OxButton
                  id="save-and-new-button"
                  giDataAttr="save-and-new-button"
                  text="Save + New"
                  buttonType="submit"
                  element="button"
                  type="secondary"
                  helperClass={`${!_isFormValid() ? 'btn-disabled' : ''}`}
                  clickFunc={() => _handleFormButtonSubmit(handleSubmitAndNew)}
                />
              ) : null}
            </div>
          </fieldset>
        </form>
      </OxLoaderOverlay>
    </OxCard>
  );
};

export default AnnouncementForm;
