import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UPDATE_ANNOUNCEMENT } from '../api/announcements/mutation';
import { GET_ANNOUNCEMENTS } from '../api/announcements/query';
import AnnouncementForm from '../components/partials/AnnouncementForm';

const EditAnnouncement = ({ route, history }) => {
  const { id } = useParams();
  const [type, _setType] = useState('');
  const [body, _setBody] = useState('');
  const [expireAt, _setExpireAt] = useState(null);

  const [selectedUserTypes, _setSelectedUserTypes] = useState(new Map());
  const [selectedFeatures, _setSelectedFeatures] = useState(new Map());
  const [selectedUserRoles, _setSelectedUserRoles] = useState(new Map());

  const { loading, error: getAnnouncementError } = useQuery(GET_ANNOUNCEMENTS, {
    variables: {
      where: { id }
    },
    onCompleted: response => {
      const { announcements } = response;
      _setBody(announcements[0].body);
      _setType(announcements[0].type);
      _setExpireAt(announcements[0].expireAt);
      _setSelectedUserTypes(
        new Map(announcements[0].userTypes.map(item => [item.id, item.name]))
      );
      _setSelectedFeatures(
        new Map(announcements[0].features.map(item => [item.id, item.name]))
      );
      _setSelectedUserRoles(
        new Map(announcements[0].userRoles.map(item => [item.id, item.name]))
      );
    }
  });

  const [_updateAnnouncement, { error }] = useMutation(UPDATE_ANNOUNCEMENT, {
    onCompleted: () => {
      history.push('/mgmt/announcements?edit-success=true');
    }
  });

  const _getEditAnnouncementPayload = () => ({
    variables: {
      data: {
        type,
        body,
        expireAt: new Date(expireAt),
        ...(selectedUserTypes && {
          userTypes: [...selectedUserTypes].map(val => val[0])
        }),
        ...(selectedUserRoles && {
          userRoles: [...selectedUserRoles].map(val => val[0])
        }),
        ...(selectedFeatures && {
          features: [...selectedFeatures].map(val => val[0])
        })
      },
      where: {
        id
      }
    }
  });

  return (
    <AnnouncementForm
      loading={loading}
      hasError={!!(error || getAnnouncementError)}
      title={route.title}
      body={body}
      expireAt={expireAt}
      type={type}
      selectedFeatures={selectedFeatures}
      selectedUserRoles={selectedUserRoles}
      selectedUserTypes={selectedUserTypes}
      handleBodyInput={_setBody}
      handleExpireAtInput={_setExpireAt}
      handleTypeInput={_setType}
      handleUserRoleChange={_setSelectedUserRoles}
      handleUserTypeChange={_setSelectedUserTypes}
      handleFeatureChange={_setSelectedFeatures}
      handleSubmit={() => _updateAnnouncement(_getEditAnnouncementPayload())}
    />
  );
};

export default EditAnnouncement;
