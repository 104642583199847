import { gql } from '@apollo/client';

const CREATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement($data: AnnouncementCreateInput!) {
    createAnnouncement(data: $data) {
      id
      body
      action
      actionText
      type
      createdAt
      expireAt
      userRoles {
        id
        name
      }
      features {
        id
        name
      }
      userTypes {
        id
        name
      }
    }
  }
`;

const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement(
    $data: AnnouncementUpdateInput!
    $where: AnnouncementWhereUniqueInput!
  ) {
    updateAnnouncement(data: $data, where: $where) {
      id
      body
      action
      actionText
      type
      createdAt
      expireAt
      userRoles {
        id
        name
      }
      features {
        id
        name
      }
      userTypes {
        id
        name
      }
    }
  }
`;

const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($where: AnnouncementWhereUniqueInput!) {
    deleteAnnouncement(where: $where) {
      id
    }
  }
`;

export { CREATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT, DELETE_ANNOUNCEMENT };
