import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import AppRoute from '../propTypes/AppRoute';
import { isInternal } from '../helpers';
import AllOrdersByCustomer from './AllOrdersByCustomer';
import { GET_USER_INFO } from '../api/user/query';
import PrimaryOrderView from './PrimaryOrderView';

const ProcessedOrders = ({ route, history }) => {
  ProcessedOrders.propTypes = {
    props: PropTypes.shape({
      route: PropTypes.shape(AppRoute)
    })
  };
  const { data: userData } = useQuery(GET_USER_INFO);
  const params = useParams();

  const getAccountId = () => params.accountId;

  if (getAccountId()) {
    return <AllOrdersByCustomer route={route} orderStatus="CLOSED" />;
  }
  return isInternal(userData) || userData.accountId.accountIds.length > 1 ? (
    <PrimaryOrderView route={route} history={history} orderStatus="CLOSED" />
  ) : (
    <AllOrdersByCustomer route={route} orderStatus="CLOSED" />
  );
};

export default ProcessedOrders;
