import { withRouter, Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import FeatherIcon from 'feather-icons-react';
import { GET_USER_INFO } from '../../api/user/query';
import useTabTrap from '../../hooks/useTabTrap';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import useTabbableClass from '../../hooks/useTabbableClass';
import { LOGOUT } from '../../api/user/mutation';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';

const USER_INFO_ID = 'ox-global-header__user-info';
const FIRST_MENU_OPTION_ID = 'ox-global-header__user-info--email';
const LAST_MENU_OPTION_ID = 'login-test__logout-button';

const IS_USER_INFO_MENU_OPENED_WITH_KEYBOARD =
  'is-user-info-menu-opened-with-keyboard';

const UserInfo = withRouter(() => {
  const [isUserInfoMenuOpen, _setIsUserInfoMenuOpen] = useState('');
  const userInfoRef = useRef(null);
  const hasClickedOutsideOfUserInfo = useDetectOutsideClick(userInfoRef);
  const tabbableClass = useTabbableClass(USER_INFO_ID);
  const { client, loading, error, data } = useQuery(GET_USER_INFO);
  const { isLoggedIn } = useIsLoggedIn();
  useTabTrap(
    FIRST_MENU_OPTION_ID,
    LAST_MENU_OPTION_ID,
    USER_INFO_ID,
    isLoggedIn
      ? isUserInfoMenuOpen === IS_USER_INFO_MENU_OPENED_WITH_KEYBOARD
      : false
  );

  useEffect(() => {
    if (hasClickedOutsideOfUserInfo) {
      _setIsUserInfoMenuOpen('');
    }
  }, [hasClickedOutsideOfUserInfo]);

  const [logout] = useMutation(LOGOUT, {
    onCompleted: () => {
      client.clearStore();
      window.location.href = `${process.env.REACT_APP_SERVER_URL}/logout`;
    }
  });

  const _handleLogout = async () => {
    logout();
  };

  /**
   * Log user out by removing token, clearing cache and
   * manually routing to the login page.
   *
   * Note: history comes from withRouter
   */

  const _handleKeyDownLogout = event => {
    if (event.key === 'Enter') {
      _handleLogout();
    }
  };

  return !error && !loading ? (
    <div className="display--flex">
      <span
        role="menu"
        tabIndex="0"
        aria-label="User Information"
        ref={userInfoRef}
        id={USER_INFO_ID}
        onKeyDown={e => {
          if (isUserInfoMenuOpen && e.key === 'Enter') {
            _setIsUserInfoMenuOpen('');
          } else if (e.key === 'Enter') {
            _setIsUserInfoMenuOpen(IS_USER_INFO_MENU_OPENED_WITH_KEYBOARD);
          }
        }}
        className={`ox-global-header__user-info ${isUserInfoMenuOpen} ${tabbableClass} tabbable--negative-ol-offset tabbable--white-ol`}
        data-gi="ox-global-header__user-info"
      >
        <div className="ox-global-header__user-info-icons">
          <FeatherIcon
            icon="user"
            className="user-info-icon user-info-icon--large fill-white-outline"
          />
          <FeatherIcon
            icon="chevron-down"
            className="user-info-icon user-info-icon--small"
          />
        </div>
        <ul>
          <li heap-ignore="true" data-hj-suppress="true">
            <Link
              aria-label="Home link"
              to="/"
              role="menuitem"
              id={FIRST_MENU_OPTION_ID}
              className="ox-global-header__user-info-text"
              data-gi="login-test__user-email"
            >
              <span className="text-overflow">{data.userInfo.email}</span>
              <FeatherIcon icon="settings" className="user-info-action-icon" />
            </Link>
          </li>
          <li>
            <button
              type="button"
              id={LAST_MENU_OPTION_ID}
              role="menuitem"
              onClick={() => _handleLogout()}
              onKeyDown={event => _handleKeyDownLogout(event)}
              className="ox-global-header__user-info-text"
              data-gi="login-test__logout-button"
            >
              Logout
              <FeatherIcon icon="log-out" className="user-info-action-icon" />
            </button>
          </li>
        </ul>
      </span>
    </div>
  ) : (
    <div className="height-100">
      <button
        type="button"
        id={LAST_MENU_OPTION_ID}
        role="menuitem"
        onClick={() => _handleLogout()}
        className="ox-global-header__user-info-text height-100"
        data-gi="login-test__logout-button"
      >
        <div className="units-row color-white">
          <div className="unit-66">Logout</div>
          <div className="unit-33">
            <FeatherIcon icon="log-out" className="user-info-action-icon" />
          </div>
        </div>
      </button>
    </div>
  );
});

export default UserInfo;
