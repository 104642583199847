import { gql } from '@apollo/client';

const GET_COMMERCIAL_INVOICES = gql`
  query commercialInvoices($where: [CommercialInvoicesWhereInput]!) {
    commercialInvoices(where: $where) {
      name
      documentType
      language
      fileType
      size
      url
      id
      orderNumber
    }
  }
`;

export default GET_COMMERCIAL_INVOICES;
