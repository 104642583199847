import React from 'react';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import '../../styles/components/_ox-tooltip.scss';
import { generateUUIDV4 } from '../../helpers/placeholderHelpers';

/**
 * This tooltip uses https://www.npmjs.com/package/react-tooltip
 *
 * Inputs:
 * 1. content - Can be a string or element type
 * 2. children - Hoverable item
 *
 * How to use:
 * 1. Passing in text
 *    <OxTooltip content="Tool tip text!">
 *      <FeatherIcon icon="info">
 *     </OxTooltip>
 *
 * 2. Customizing content (i.e. width)
 *     <OxTooltip content="<div style={{ width: 'auto' }}><p>Overflow text</p><div>" direction="top">
 *      Overflow te....
 *    </OxTooltip>
 */
export const OxTooltip = props => {
  const { content, children, tooltipScrollable, helperClass } = props;

  OxTooltip.propTypes = {
    content: PropTypes.string.isRequired,
    tooltipScrollable: PropTypes.bool,
    helperClass: PropTypes.string
  };

  const randomID = generateUUIDV4();

  return (
    <div className="ox-tooltip">
      <div
        data-tooltip-html={content}
        className="ox-tooltip__hoverable"
        data-tooltip-id={randomID}
      >
        {children}
      </div>
      <Tooltip
        id={randomID}
        delayHide={tooltipScrollable ? 600 : 0}
        clickable
        multiline
        className={`ox-tooltip__content ${
          tooltipScrollable ? 'scrollable' : ''
        } ${helperClass || ''}`}
      />
    </div>
  );
};

export default OxTooltip;
