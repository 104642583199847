import PropTypes from 'prop-types';

const OxCardInput = {
  // Providing collapsible props make card optionally collapsible
  collapsible: PropTypes.shape({
    // Used to set default collapsed state, overwritten if isCollapsed is provided.
    defaultCollapsed: PropTypes.bool,
    // Called when user toggles collapsed state.
    handleCardToggleEvent: PropTypes.func,
    // Optionally, manage collapsed state.
    isCollapsed: PropTypes.bool
  }),
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    emphasizedTitle: PropTypes.string,
    buttons: PropTypes.array,
    isSmall: PropTypes.bool,
    iconName: PropTypes.string
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labels: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired
  }),
  contentSplit: PropTypes.bool,
  helperClass: PropTypes.string,
  contentFlush: PropTypes.bool,
  noPadding: PropTypes.bool,
  footer: PropTypes.func
};

export default OxCardInput;
