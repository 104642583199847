import { gql } from '@apollo/client';

const GET_GROUP_EMAIL = gql`
  query groupEmail($where: GroupEmailQueryInput) {
    groupEmail(where: $where) {
      email
    }
  }
`;

export default GET_GROUP_EMAIL;
