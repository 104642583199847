import { gql } from '@apollo/client';

const GET_MATERIAL_CODES_BY_ORDER_NUMBER = gql`
  query($orders: [MaterialCodesByOrderNumberInput]) {
    materialCodesByOrderNumber(orders: $orders) {
      data {
        orderNumber
        materialCodes {
          componentItemNumber
          description
        }
      }
    }
  }
`;

export default GET_MATERIAL_CODES_BY_ORDER_NUMBER;
