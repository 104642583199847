import React from 'react';
import '../../styles/components/_ox-global-footer.scss';
import useTabbableClass from '../../hooks/useTabbableClass';
import { toTitleCase } from '../../helpers';

const version = process.env.REACT_APP_REPOREF || '';
const GlobalFooter = () => {
  const PRIVACY_POLICY_LINK_ID = 'ox-global-footer__privacy-policy';
  const tabbableClass = useTabbableClass(PRIVACY_POLICY_LINK_ID);

  return (
    <footer className="ox-global-footer">
      <summary>
        &copy; Intralox <time>{new Date().getFullYear()}</time>
      </summary>
      <div className="display--flex">
        <div className="ox-global-footer__app-version">
          {toTitleCase(version).replace('Tags/', 'v')}
        </div>
        <a
          className={`ox-global-footer__privacy-policy ${tabbableClass}`}
          href="https://www.intralox.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
          id={PRIVACY_POLICY_LINK_ID}
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default GlobalFooter;
