/* eslint-disable no-param-reassign */
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GET_ORDERS_LINE_ITEMS_BY_REP } from '../api/orders/query';
import OxCard from '../components/molecules/OxCard';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxTable from '../components/molecules/OxTable';
import OxLoaderOverlay from '../components/tokens/OxLoaderOverlay';
import OxSearch from '../components/partials/OxSearch';
import { OxPagination } from '../components/tokens/OxPagination';
import { isGraphqlArrayEmptyForNested, isInternal } from '../helpers';
import {
  ERROR_TITLE,
  ORDER_PAGINATION_OPTIONS,
  SEARCHING_TITLE,
  SEARCH_ORDER_PLACEHOLDER_SHORT
} from '../helpers/constants';
import {
  allOrdersBodyPlaceholder,
  allOrdersHeaderPlaceholder,
  getTableBodyPlaceholder,
  UnableToRetrieveData
} from '../helpers/placeholderHelpers';
import { GET_USER_INFO } from '../api/user/query';
import usePagination from '../hooks/usePagination';
import '../styles/pages/_interior.scss';
import AppRoute from '../propTypes/AppRoute';
import * as segment from '../helpers/segmentLogger';

const DATA_GI_ATTR = 'all-orders';
const DEFAULT_ROW = ORDER_PAGINATION_OPTIONS[1].optionValue;
const RECENT_ORDERS_ERROR_TITLE = 'We had issues building recent orders.';

const RECENT_ORDERS_ERROR_MESSAGE = (
  <p>
    You may go back to the&nbsp;
    <Link className="tabbable" to="/customers/my-customers">
      customer list
    </Link>
    &nbsp;and find orders by customers.
  </p>
);

const PrimaryOrderView = ({ route, history, orderStatus }) => {
  PrimaryOrderView.propTypes = {
    props: PropTypes.shape({
      route: PropTypes.shape(AppRoute),
      orderStatus: PropTypes.shape(String)
    })
  };
  const { data: userData } = useQuery(GET_USER_INFO);
  const [accountOffset, setAccountOffset] = useState([]);
  const [accountSetExists, setAccountSetExists] = useState({});
  const {
    getOffset,
    getRowsPerPage,
    handleNextPageQueryCompletion
  } = usePagination(DEFAULT_ROW);

  const orderStatusMap = {
    BOOKED: {
      orderStatus: 'BOOKED'
    },
    CLOSED: {
      orderStatusNot: 'BOOKED'
    }
  };
  const applyOrderStatus = orderStatus && orderStatusMap[orderStatus];

  const _getAlert = queryData => {
    if (isGraphqlArrayEmptyForNested(queryData, 'orders')) {
      return (
        <OxSoftAlert
          alertType="info"
          icon="alert-circle"
          title={RECENT_ORDERS_ERROR_TITLE}
          isOutlined
          giDataAttr={`${DATA_GI_ATTR}__no-data-alert`}
          eventName="noData"
        >
          {RECENT_ORDERS_ERROR_MESSAGE}
        </OxSoftAlert>
      );
    }
    return (
      <OxSoftAlert
        alertType="warning"
        icon="alert-circle"
        title={ERROR_TITLE}
        isOutlined
        giDataAttr={`${DATA_GI_ATTR}__network-error-alert`}
        eventName="network"
      >
        {UnableToRetrieveData('customer')}
      </OxSoftAlert>
    );
  };

  const { loading, error, data } = useQuery(
    GET_ORDERS_LINE_ITEMS_BY_REP,
    {
      variables: {
        offset: getOffset(),
        rowsPerPage: getRowsPerPage(),
        accountIdOffset: accountOffset,
        where: {
          ...(isInternal(userData)
            ? {
                assignedCustomersOnly: false
              }
            : null),
          ...(applyOrderStatus || null)
        }
      },
      errorPolicy: 'all',
      onCompleted: () => {
        handleNextPageQueryCompletion();
        const accountDataSet = accountOffset;
        const accountDataSetExists = accountSetExists;

        if (data && data.orders && data.orders.data) {
          data.orders.data.forEach(order => {
            if (accountDataSet.length) {
              accountDataSet.forEach(set => {
                if (set && set.accountId === order.accountId) {
                  set.offset += 1;
                  accountSetExists[order.accountId] = true;
                }
                if (!accountSetExists[order.accountId]) {
                  accountDataSet.push({
                    accountId: order.accountId,
                    offset: 1
                  });
                  accountSetExists[order.accountId] = true;
                }
              });
            }
            if (!accountDataSet.length) {
              accountDataSet.push({
                accountId: order.accountId,
                offset: 1
              });
              accountSetExists[order.accountId] = true;
            }
          });
          setAccountSetExists(accountDataSetExists);
          setAccountOffset(accountDataSet);
        }
      }
    },
    [accountOffset, setAccountOffset, accountSetExists, setAccountSetExists]
  );

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.orders &&
      data.orders.data &&
      data.orders.data.length
    ) {
      data.orders.data.forEach(order => {
        if (order.shipments && order.shipments.length < 1) {
          segment.logEvent({
            giDataAttr: 'orders__no-shipments-for-order',
            eventName: segment.eventTypes.orderShipment,
            eventData: { orderNumber: order.orderNumber.value }
          });
        }
      });
    }
  }, [data, loading]);

  return (
    <OxCard
      header={{
        title: route.title,
        buttons: [
          () => (
            <div className="ox_card__search-box" key="all-orders-search">
              <OxSearch
                searchType="order"
                name="customer-search"
                searchPlaceholder={SEARCH_ORDER_PLACEHOLDER_SHORT}
                label={SEARCH_ORDER_PLACEHOLDER_SHORT}
                history={history}
              />
            </div>
          )
        ]
      }}
      footer={() =>
        data &&
        data.orders &&
        data.orders.data &&
        data.orders.data.length &&
        !error ? (
          <OxPagination
            totalPages={
              data && data.orders ? data.orders.pageDetails.totalPages : null
            }
            currentPage={
              data && data.orders
                ? data.orders.pageDetails.currentPage.toString()
                : null
            }
            options={ORDER_PAGINATION_OPTIONS}
            defaultRow={DEFAULT_ROW}
            route={route}
            gaLabel="My Orders"
          />
        ) : null
      }
      id="orders"
      key={`orders-${Math.random()}`}
    >
      {error ||
      isGraphqlArrayEmptyForNested(data, 'orders') ||
      (data && !data.orders) ? (
        _getAlert(data)
      ) : (
        <OxLoaderOverlay
          isLoading={loading}
          title={SEARCHING_TITLE}
          giDataAttr={`${DATA_GI_ATTR}__loading-overlay`}
        >
          <OxTable
            noAnalytics
            giDataAttr={DATA_GI_ATTR}
            data={{
              headers: allOrdersHeaderPlaceholder,
              data:
                data &&
                data.orders &&
                data.orders.data &&
                !isGraphqlArrayEmptyForNested(data, 'orders')
                  ? data.orders.data
                  : getTableBodyPlaceholder(2, allOrdersBodyPlaceholder)
            }}
          />
        </OxLoaderOverlay>
      )}
    </OxCard>
  );
};

export default PrimaryOrderView;
