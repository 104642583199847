import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import usePagination from '../../hooks/usePagination';
import useTabbableClass from '../../hooks/useTabbableClass';
import '../../styles/components/_ox-pagination.scss';
import OxSelect from './forms/OxSelect';
import getRequiredPaginationParameters from '../../helpers/routes';
import AppRoute from '../../propTypes/AppRoute';
import { logEvent } from '../../helpers/segmentLogger';

export const OxPagination = props => {
  const {
    currentPage,
    totalPages,
    options,
    defaultRow,
    route,
    gaLabel
  } = props;

  OxPagination.propTypes = {
    currentPage: PropTypes.string,
    totalPages: PropTypes.string,
    defaultRow: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        optionText: PropTypes.string.isRequired,
        optionValue: PropTypes.number.isRequired
      })
    ).isRequired,
    route: PropTypes.shape(AppRoute).isRequired,
    gaLabel: PropTypes.string
  };
  // Accessibility styling for select
  const PAGINATION_SELECT = 'pagination-select';
  const tabbableClass = useTabbableClass(PAGINATION_SELECT);

  const { getOffset, getRowsPerPage } = usePagination(defaultRow);
  const { pathname } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const getOffsetForNextPage = () => getOffset() + getRowsPerPage();
  const getOffsetForPreviousPage = () => getOffset() - getRowsPerPage();

  const getPaginationQueryParams = (newOffset, newRowsPerPage) =>
    `offset=${newOffset}&rowsPerPage=${newRowsPerPage}`;

  /**
   * Returns any required pagination parameters (i.e id) if they exist in the url
   * and pagination parameters.
   *
   * @param {*} paginationParameters
   */
  const getAllQueryParms = paginationParameters => {
    if (getRequiredPaginationParameters(route, queryParams)) {
      return `${getRequiredPaginationParameters(
        route,
        queryParams
      )}&${paginationParameters}`;
    }
    return paginationParameters;
  };

  const handleRowSelection = selectedRow => {
    if (gaLabel) {
      logEvent({
        actionName: `Update Rows Per Page to ${selectedRow.target.value}`,
        categoryName: 'Pagination',
        labelName: gaLabel
      });
    }

    history.push(
      `${pathname}?${getAllQueryParms(
        getPaginationQueryParams(0, parseInt(selectedRow.target.value, 10))
      )}`
    );
  };

  const hasBtnRight = () => currentPage && currentPage !== totalPages;

  const hasBtnLeft = () => currentPage && currentPage !== '1';

  /**
   * Called whenever an arrow button is clicked
   * @param {string} iconName - one of 'arrow-left' or 'arrow-right'
   */
  const onClick = iconName => {
    if (gaLabel) {
      logEvent({
        actionName: `Paginate ${
          iconName === 'arrow-left' ? 'Backward' : 'Forward'
        }`,
        categoryName: 'Pagination',
        labelName: gaLabel
      });
    }
  };

  const getArrowButton = (iconName, paginationQueryParams) => {
    return (
      <Link
        aria-label={iconName === 'arrow-left' ? 'previous page' : 'next page'}
        to={{ pathname, search: `${getAllQueryParms(paginationQueryParams)}` }}
        role="button"
        tabIndex={currentPage && totalPages ? 0 : -1}
        data-gi={`page-of__${iconName}`}
        className="page-of__btn btn-icon tabbable"
        onClick={() => {
          onClick(iconName);
        }}
      >
        <FeatherIcon icon={iconName} />
      </Link>
    );
  };

  return (
    <div className="pagination-container">
      <div className="pagination-container__rows-per-page">
        <div className="rows-per-page__text">Rows Per Page</div>
        <OxSelect
          helperClass={tabbableClass.toString()}
          id={PAGINATION_SELECT}
          name="pagination-select"
          options={options}
          handleKeyUp={handleRowSelection}
          value={getRowsPerPage().toString()}
        />
      </div>
      <div className="pagination-container__page-of">
        {hasBtnLeft()
          ? getArrowButton(
              'arrow-left',
              getPaginationQueryParams(
                getOffsetForPreviousPage(),
                getRowsPerPage()
              )
            )
          : null}
        <div className="page-of__text">
          Page {currentPage} of {totalPages}
        </div>
        {hasBtnRight()
          ? getArrowButton(
              'arrow-right',
              getPaginationQueryParams(getOffsetForNextPage(), getRowsPerPage())
            )
          : null}
      </div>
    </div>
  );
};

export default OxPagination;
