import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from '../api/user/query';
import { isInternal } from '../helpers';
import {
  accounts,
  caseCreator,
  salesLinksPermission,
  orders
} from '../helpers/authHelpers';
import {
  DASHBOARD_LINKS,
  NO_MY_CUSTOMERS_ALERT_TITLE,
  NO_MY_CUSTOMERS_ALERT_MESSAGE,
  ACCOUNT_TYPE_OPTIONS,
  SEARCH_CUSTOMER_PLACEHOLDER,
  SEARCH_ORDER_PLACEHOLDER
} from '../helpers/constants';
import Customers from '../components/partials/Customers';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxButton from '../components/molecules/OxButton';
import { ACCOUNTS_FOR_CUSTOMER_DASHBOARD } from '../api/account/query';
import useHasPermission from '../hooks/useHasPermission';
import Dashboard from '../components/partials/Dashboard';
import { identify, logPageView } from '../helpers/segmentLogger';

const MyDashboard = () => {
  const { data: userData } = useQuery(GET_USER_INFO, {
    onCompleted: res => {
      // Identify user to analytics
      identify(res.userInfo);

      // Log a page view
      logPageView();
    }
  });
  const hasOrders = useHasPermission([orders]);
  const hasAccounts = useHasPermission([accounts]);
  const hasCaseCreatorPermission = useHasPermission([caseCreator]);
  const hasSalesPermission = useHasPermission([salesLinksPermission]);
  const hasDSAppStatus = userData.userInfo.dsAppStatus === 'success';
  const [isMyLinksCollapsed, setIsMyLinksCollapsed] = useState(false);

  const _getSearchOptions = () => {
    const options = [];

    if (hasOrders || hasAccounts) {
      options.push({
        optionText: 'Order',
        optionValue: 'order',
        placeholder: SEARCH_ORDER_PLACEHOLDER
      });

      if (isInternal(userData)) {
        options.push({
          optionText: 'Account',
          optionValue: 'account',
          placeholder: SEARCH_CUSTOMER_PLACEHOLDER
        });
      }
    }

    return options.length ? options : null;
  };

  const _getLinks = () => {
    const internalDashboardLinks = [
      ...(hasCaseCreatorPermission ? [DASHBOARD_LINKS.get('CaseCreator')] : []),
      ...(hasSalesPermission
        ? [DASHBOARD_LINKS.get('Dynamics'), DASHBOARD_LINKS.get('CalcLab')]
        : [
            DASHBOARD_LINKS.get('IntraloxIntranet'),
            DASHBOARD_LINKS.get('LaitramIntranet'),
            DASHBOARD_LINKS.get('MicrosoftOffice')
          ])
    ];

    const externalDashboardLinks = [
      DASHBOARD_LINKS.get('IntraloxWebsite'),
    ];

    // External users should not see CalcLab link unless they have a DS App Status of "Success"
    if (!isInternal(userData) && hasDSAppStatus) {
      externalDashboardLinks.push(DASHBOARD_LINKS.get('CalcLab'));
    }

    return isInternal(userData)
      ? internalDashboardLinks
      : externalDashboardLinks;
  };

  const DashboardCustomers = () => {
    const { loading, data, error } = useQuery(ACCOUNTS_FOR_CUSTOMER_DASHBOARD, {
      variables: {
        where: { accountType: ACCOUNT_TYPE_OPTIONS.CURRENT_USER }
      },
      errorPolicy: 'all'
    });

    return (
      <Customers
        title="My Customers"
        helperClass="margin-bottom--extra"
        collapsible={{
          isCollapsed: !isMyLinksCollapsed,
          handleCardToggleEvent: () =>
            setIsMyLinksCollapsed(!isMyLinksCollapsed)
        }}
        hasNoCustomers={() => {
          return (
            <>
              <OxSoftAlert
                alertType="info"
                icon="alert-circle"
                title={NO_MY_CUSTOMERS_ALERT_TITLE}
                isOutlined
                giDataAttr="dashboard-my-customers__no-data"
                eventName="noData"
              >
                {NO_MY_CUSTOMERS_ALERT_MESSAGE}
              </OxSoftAlert>
              <OxButton
                text="View All Customers"
                href="/customers/all-customers"
                element="link"
                id="dashboard-my-customers__see-all-customer-btn"
                size="default"
                type="blue"
                helperClass="width-100"
                noScale
                giDataAttr="dashboard-my-customers__see-all-customer-btn"
              />
            </>
          );
        }}
        loading={loading}
        data={data}
        error={error}
        minTableCount={4}
        giDataAttr="dashboard-my-customers"
      >
        <OxButton
          isDisabled={loading}
          text="View All My Customers"
          href="/customers/my-customers"
          id="dashboard-my-customers__see-all-my-customer-btn"
          element="link"
          size="default"
          type="blue"
          helperClass="width-100"
          noScale
          giDataAttr="dashboard-my-customers__see-all-my-customer-btn"
        />
      </Customers>
    );
  };

  return (
    <Dashboard
      searchOptions={_getSearchOptions()}
      showMyCustomerTable={isInternal(userData) && (hasAccounts || hasOrders)}
      {...(hasAccounts ||
        (hasOrders && {
          cardInputPropsForLinks: {
            collapsible: {
              isCollapsed: isMyLinksCollapsed,
              handleCardToggleEvent: () =>
                setIsMyLinksCollapsed(!isMyLinksCollapsed)
            }
          }
        }))}
      links={_getLinks()}
    >
      {/* Note: Customers are temporarily, not rendered for 'Sales/Commercial' users, but will
      be rendered for them in the future. */}
      {isInternal(userData) && (hasAccounts || hasOrders) ? (
        <DashboardCustomers />
      ) : null}
    </Dashboard>
  );
};

export default MyDashboard;
