import Interior from '../../pages/Interior';
import Announcements from '../../pages/Announcements';
import Users from '../../pages/Users';
import EditUser from '../../pages/EditUser';
import EditAnnouncement from '../../pages/EditAnnouncement';
import CreateAnnouncement from '../../pages/CreateAnnouncement';
import { admin } from '../../helpers/authHelpers';
import { isExternalUser, isInternal } from '../../helpers';
import CreateUser from '../../pages/CreateUser';

const adminRoutes = {
  key: 'admin',
  icon: 'lock',
  title: 'Admin',
  path: '/mgmt',
  giDataAttr: 'adminLink',
  component: Interior,
  routes: [
    {
      key: 'announcements',
      title: 'Announcements',
      path: '/mgmt/announcements',
      exact: true,
      isPrivate: true,
      component: Announcements,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    },
    {
      key: 'users',
      title: 'Users',
      path: '/mgmt/users',
      exact: true,
      isPrivate: true,
      component: Users,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to announcements',
        labelName: 'Announcements'
      }
    },
    {
      key: 'api-users',
      title: 'API Users',
      path: '/mgmt/api-users',
      exact: true,
      isPrivate: true,
      component: Users,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    },
    {
      key: 'add-user',
      title: 'Add User',
      path: '/mgmt/user/create',
      exact: true,
      isPrivate: true,
      hideInInteriorNav: true,
      component: CreateUser,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser]
    },
    {
      key: 'edit-user',
      title: 'Edit User',
      path: '/mgmt/user/:id/edit',
      exact: true,
      isPrivate: true,
      hideInInteriorNav: true,
      component: EditUser,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to announcements',
        labelName: 'Announcements'
      }
    },
    {
      key: 'new-announcement',
      title: 'Add New Announcement',
      path: '/mgmt/announcements/new',
      exact: true,
      isPrivate: true,
      hideInInteriorNav: true,
      component: CreateAnnouncement,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to announcements',
        labelName: 'Announcements'
      }
    },
    {
      key: 'edit-announcement',
      title: 'Edit Announcement',
      path: '/mgmt/announcements/:id/edit',
      exact: true,
      isPrivate: true,
      hideInInteriorNav: true,
      component: EditAnnouncement,
      requiredPermissions: [admin],
      authFunctions: [isInternal],
      shouldGoToNoMatchIfNoAccess: [isExternalUser],
      backButtonEvent: {
        categoryName: 'General Navigation',
        actionName: 'User return to announcements',
        labelName: 'Announcements'
      }
    }
  ],
  isPrivate: true,
  requiredPermissions: [admin],
  authFunctions: [isInternal],
  shouldGoToNoMatchIfNoAccess: [isExternalUser]
};

export default adminRoutes;
