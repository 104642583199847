import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import '../styles/components/_list-of-orders.scss';
import { isGraphqlArrayEmptyForNested, IsValidInteger } from '../helpers/index';
import * as segment from '../helpers/segmentLogger';
import {
  GET_ORDERS_SEARCH,
  GET_KEWILL_ORDERS,
  GET_ORDER_HEADER
} from '../api/orders/query';
import OxLoader from '../components/tokens/OxLoader';
import OrderDocuments from './OrderDocuments';
import { OxModal } from '../components/molecules/OxModal';
import DocumentActionButton from '../components/tokens/DocumentActionButton';
import { GET_USER_INFO } from '../api/user/query';

import '../styles/pages/_interior.scss';
import OxCard from '../components/molecules/OxCard';
import OrderShipments from '../components/partials/OrderShipments';

const EmbedOrderShipments = () => {
  const params = useParams();
  const loggedInUserEmail = new URLSearchParams(useLocation().search).get(
    'loggedInUserEmail'
  );
  const [showDownloadButton, _setShowDownloadButton] = useState(false);
  const [showShareButton, _setShowShareButton] = useState(false);
  const [selectedDocuments, _setSelectedDocs] = useState([]);

  const [
    combinedShipmentInformation,
    setCombinedShipmentInformation
  ] = useState([]);

  /**
   * Identify user to Google Analytics and log a page view
   */
  useQuery(GET_USER_INFO, {
    ...(!!loggedInUserEmail && {
      variables: {
        where: {
          email: loggedInUserEmail
        }
      }
    }),
    onCompleted: res => {
      segment.identify(res.userInfo);
      segment.logPageView();
    }
  });

  const { data, loading, error } = useQuery(GET_ORDERS_SEARCH, {
    variables: {
      offset: 0,
      rowsPerPage: 15,
      where: {
        ...(IsValidInteger(params.orderNumber) && {
          orderNumber: params.orderNumber,
          accountId: params.accountNumber
        })
      }
    },
    fetchPolicy: 'network-only'
  });

  // Order Header data used to determine account default language
  const { data: orderHeaderData } = useQuery(GET_ORDER_HEADER, {
    variables: {
      where: {
        orderNumber: params.orderNumber,
        accountId: params.accountNumber
      }
    }
  });

  const {
    data: kewillData,
    loading: kewillLoading,
    error: kewillError
  } = useQuery(GET_KEWILL_ORDERS, {
    variables: {
      offset: 0,
      rowsPerPage: 15,
      where: {
        ...(IsValidInteger(params.orderNumber) && {
          orderNumber: params.orderNumber,
          accountId: params.accountNumber
        })
      }
    },
    fetchPolicy: 'network-only'
  });

  useEffect(
    () => {
      if (
        !loading &&
        !kewillLoading &&
        !error &&
        !kewillError &&
        !isGraphqlArrayEmptyForNested(data, 'orders') &&
        kewillData
      ) {
        let kewillOrderMap = new Map();

        if (
          kewillData &&
          kewillData.kewillInfo &&
          kewillData.kewillInfo.kewillOrders
        ) {
          kewillOrderMap = kewillData.kewillInfo.kewillOrders.reduce(
            (kewillMap, kewillOrder) => {
              kewillMap.set(kewillOrder.orderNumber.value, kewillOrder);
              return kewillMap;
            },
            new Map()
          );
        }

        if (data.orders && data.orders.data) {
          // For each order in Oracle...
          const conjoinedData = data.orders.data.map(order => {
            // Check if kewill info exists for the oracle order
            const matchingKewillOrder = kewillOrderMap.get(
              order.orderNumber.value
            );
            // If so, use the shipping info from kewill
            if (
              matchingKewillOrder &&
              matchingKewillOrder.shipments &&
              matchingKewillOrder.shipments.length
            ) {
              return {
                ...order,
                shipments: matchingKewillOrder.shipments
              };
            }
            return order;
          });
          // Display the updated shipping information
          setCombinedShipmentInformation(conjoinedData);
        }
      }
    },
    // eslint-disable-next-line
    [loading, kewillLoading]
  );

  // Analytics
  useEffect(() => {
    if (
      !loading &&
      !error &&
      !isGraphqlArrayEmptyForNested(data, 'orders') &&
      combinedShipmentInformation
    ) {
      if (data.orders && data.orders.data) {
        data.orders.data.forEach(order => {
          if (order.shipments && order.shipments.length < 1) {
            segment.logEvent({
              giDataAttr: 'orders__no-shipments-for-order',
              eventName: segment.eventTypes.orderShipment,
              eventData: { orderNumber: order.orderNumber.value }
            });
          }
        });
      }
    }
  }, [combinedShipmentInformation, data, loading, error]);

  const _handleShowDownloadButton = showDownloadBtn => {
    _setShowDownloadButton(showDownloadBtn);
  };

  const _handleShowShareButton = showShareBtn => {
    _setShowShareButton(showShareBtn);
  };

  const _handleSelectedDocumentsChange = selectedDocs => {
    _setSelectedDocs(selectedDocs);
  };

  if (loading || kewillLoading) {
    return <OxLoader title="Loading" />;
  }
  return (
    <div>
      <OxModal>
        <OxCard
          helperClass="ox-card--no-shadow"
          header={{
            title: `Tracking and Documents for Order ${params.orderNumber}`,
            buttons: [
              () =>
                showShareButton ? (
                  <DocumentActionButton
                    action="share"
                    documents={selectedDocuments}
                    tabIndex="0"
                    purchaseOrderNumber={
                      data.orders?.data[0].purchaseOrderId.value
                    }
                    defaultLanguage={
                      orderHeaderData &&
                      orderHeaderData.orderHeader &&
                      orderHeaderData.orderHeader.accountDefaultLanguage
                    }
                    orderNumber={params.orderNumber}
                  />
                ) : null,
              () =>
                showDownloadButton ? (
                  <DocumentActionButton
                    action="download"
                    documents={selectedDocuments}
                    tabIndex="0"
                  />
                ) : null
            ]
          }}
        >
          <OrderShipments
            loading={loading || kewillLoading}
            shipmentInformation={combinedShipmentInformation}
          />
          <OrderDocuments
            onShowDownloadButtonChange={e => _handleShowDownloadButton(e)}
            onShowShareButtonChange={e => _handleShowShareButton(e)}
            onSelectedDocumentsChange={e => _handleSelectedDocumentsChange(e)}
          />
        </OxCard>
      </OxModal>
    </div>
  );
};

export default EmbedOrderShipments;
