import React from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import '../../styles/components/_ox-pill.scss';

const OxPill = ({ id, text, isSelected, clickFunc, withIcon, size }) => {
  OxPill.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    clickFunc: PropTypes.func,
    withIcon: PropTypes.shape({
      icon: PropTypes.string.isRequired
    }),
    size: PropTypes.oneOf(['small']) // Default is used if none is provided
  };

  const getTextWithIcon = icon => (
    <div className="display--flex nowrap pill__content">
      <span>{text}</span>
      <div className="content__icon">
        <FeatherIcon icon={icon} />
      </div>
    </div>
  );

  const getContent = () => (withIcon ? getTextWithIcon(withIcon.icon) : text);

  const _getClass = () => {
    return `
      display--flex
      pill 
      pill-default
      ${size ? `pill-size-${size}` : 'pill-size-default'} 
      ${isSelected ? 'pill-is-selected' : ''} `;
  };

  return (
    <button
      type="button"
      id={id}
      className={_getClass()}
      onClick={event => {
        if (clickFunc) {
          clickFunc(event);
        }
      }}
      onKeyUp={event => {
        if (event.key === 'Enter') {
          clickFunc(event);
        }
      }}
      data-gi={`${text}__pill`}
    >
      {getContent()}
    </button>
  );
};

export default OxPill;
