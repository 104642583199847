import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation, withRouter } from 'react-router-dom';
import AppRoute from '../../propTypes/AppRoute';
import '../../styles/components/_ox-nav-item.scss';

const OxNavItem = ({
  icon,
  title,
  path,
  target,
  handleClickEvent,
  giDataAttr,
  routes,
  id
}) => {
  OxNavItem.propTypes = {
    icon: propTypes.oneOf([
      'grid',
      'list',
      'folder',
      'file-text',
      'pie-chart',
      'life-buoy',
      'user',
      'briefcase',
      'life-buoy',
      'menu',
      'lock',
      'mail'
    ]).isRequired,
    title: propTypes.string.isRequired,
    giDataAttr: propTypes.string,
    path: propTypes.string.isRequired,
    target: propTypes.string,
    handleClickEvent: propTypes.func,
    routes: propTypes.arrayOf(propTypes.shape({ ...AppRoute })), // children of provided path
    history: propTypes.shape({}),
    id: propTypes.string
  };
  const { pathname } = useLocation();

  /**
   * Determine if a link should receive the active styles.
   *
   * Note: If a route's subroute is selected, the parent route should still be considered active.
   */
  const _isLinkActive = () => {
    return (
      // If provided path is current path
      path === pathname ||
      (routes &&
        // Or provided path is child of current path
        routes.find(
          route => route.path.split('/')[1] === pathname.split('/')[1]
        ))
    );
  };

  /**
   * Get path for link.
   *
   * If the route has children set first child as the default route,
   * other use the parent route.
   */
  const _getPath = () => {
    return routes && routes.length ? routes[0].path : path;
  };

  return (
    <li className="ox-side-nav__item">
      <NavLink
        id={id}
        onClick={handleClickEvent}
        to={_getPath()}
        target={target}
        exact
        activeClassName="selected"
        data-gi={giDataAttr}
        isActive={() => _isLinkActive()}
      >
        <span className="ox-side-nav__icon">
          <FeatherIcon icon={icon} />
        </span>
        {title}
      </NavLink>
    </li>
  );
};

export default withRouter(OxNavItem);
