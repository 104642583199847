import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useModal } from '../molecules/OxModal';
import SHARE_DOCUMENT from '../../api/shareDocument/mutation';
import OxInput from '../tokens/forms/OxInput';
import OxTextarea from '../tokens/forms/OxTextarea';
import OxButton from '../molecules/OxButton';
import OxSoftAlert from '../molecules/OxSoftAlert';
import * as segment from '../../helpers/segmentLogger';
import OxSelect from '../tokens/forms/OxSelect';
import '../../styles/components/_documentEmailForm.scss';
import {
  EMAIL_SHARE_LANGUAGE_OPTIONS,
  LONG_LANGUAGE_CODE_MAP,
  SHARE_EMAIL_SUBJECTS
} from '../../helpers/constants';
import { isEmailFormatValid, isEmbedded } from '../../helpers';
import { GET_USER_INFO } from '../../api/user/query';
import GET_GROUP_EMAIL from '../../api/groupEmail/query';

const DocumentEmailForm = ({
  shareDirectly,
  documents,
  purchaseOrderNumber,
  orderNumber,
  pageName,
  defaultLanguage
}) => {
  const [currentDefaultLanguage, _setDefaultLanguage] = useState('');
  const [sendTo, _setSendTo] = useState('');
  const [comments, _setComments] = useState('');
  const [language, _setLanguage] = useState(
    LONG_LANGUAGE_CODE_MAP.get(currentDefaultLanguage) || 'en'
  );
  const [bcc, _setBcc] = useState(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const loggedInUserEmail = queryParams.get('loggedInUserEmail');
  const [replyTo, _setReplyTo] = useState('');
  const [replyToOptions, _setReplyToOptions] = useState([]);
  const [subject, _setSubject] = useState('');
  const { data: userData, loading: userDataLoading } = useQuery(GET_USER_INFO);
  const { data: groupEmailData, loading: groupEmailLoading } = useQuery(
    GET_GROUP_EMAIL,
    {
      variables: {
        where: { orderNumber, purchaseOrderNumber }
      }
    }
  );
  const [isRequired, _setIsRequired] = useState(true);

  useEffect(() => {
    // Set to true whenever the modal opens
    _setIsRequired(true);
  }, []);

  // Set the default subject when the page changes
  useEffect(() => {
    _setSubject(
      pageName === 'resources'
        ? SHARE_EMAIL_SUBJECTS.resources[language]
        : SHARE_EMAIL_SUBJECTS.orderDocuments[language][
            documents.length === 1 ? 'singleDocument' : 'multipleDocuments'
          ](orderNumber)
    );
  }, [documents.length, pageName, purchaseOrderNumber, language, orderNumber]);

  useEffect(() => {
    // Add logged in user to reply options
    if (
      !userDataLoading &&
      userData &&
      userData.userInfo &&
      userData.userInfo.email &&
      !replyToOptions.some(
        option =>
          option.optionValue === userData.userInfo.email ||
          option.optionValue === loggedInUserEmail
      )
    ) {
      _setReplyToOptions([
        ...replyToOptions,
        {
          optionText: loggedInUserEmail || userData.userInfo.email,
          optionValue: loggedInUserEmail || userData.userInfo.email
        }
      ]);
    }

    // Add group email to reply to options if it exists and has not already been added
    if (
      !groupEmailLoading &&
      groupEmailData &&
      groupEmailData.groupEmail &&
      !replyToOptions.some(
        x => x.optionValue === groupEmailData.groupEmail.email
      )
    ) {
      _setReplyToOptions([
        {
          optionText: groupEmailData.groupEmail.email,
          optionValue: groupEmailData.groupEmail.email
        },
        ...replyToOptions
      ]);

      _setReplyTo(groupEmailData.groupEmail.email);
    }
  }, [
    loggedInUserEmail,
    replyToOptions,
    userData,
    userDataLoading,
    groupEmailData,
    groupEmailLoading
  ]);

  if (currentDefaultLanguage !== defaultLanguage) {
    _setDefaultLanguage(defaultLanguage);
    _setLanguage(LONG_LANGUAGE_CODE_MAP.get(defaultLanguage) || 'en');
  }

  const { hideModal } = useModal();
  const FIRST_ELEMENT = 'email-form__subject-field';
  const CANCEL_ELEMENT = 'email-form__cancel-btn';
  const _closeModal = async () => {
    // Keeps the "Please fill in field" message from appearing when closing the modal
    _setIsRequired(false);
    hideModal();
    _setSendTo('');
    _setReplyTo('');
    _setReplyToOptions([]);
    _setComments('');
    _setLanguage(LONG_LANGUAGE_CODE_MAP.get(currentDefaultLanguage) || 'en');
  };

  const [emailDocument, { error, loading }] = useMutation(SHARE_DOCUMENT, {
    onCompleted: () => {
      if (!error) {
        _closeModal();
      }
    }
  });

  const _handleToggleBcc = () => _setBcc(!bcc);

  const _handleSubmitForm = async event => {
    event.preventDefault();

    // Make sure this field isn't on any docs to prevent graphql errors
    // eslint-disable-next-line no-param-reassign
    documents.forEach(doc => delete doc.__typename);

    const shareSection =
      pageName === 'order-documents'
        ? 'Related Order Documents'
        : 'Resource Section';

    if (isEmailFormatValid(sendTo)) {
      // Build out share event
      const sharedDocType =
        documents.length === 1 ? documents[0].documentType : undefined;
      const sharedDocName =
        documents.length === 1 ? documents[0].name : undefined;
      const shareEventActionName = `Shared from ${shareSection}`;

      const shareEvent = {
        actionName: isEmbedded()
          ? `${
              shareDirectly
                ? sharedDocName
                : `Multi File Share ${documents.length}`
            }`
          : shareEventActionName,
        categoryName: isEmbedded()
          ? `${userData.userInfo.name} - ${
              shareDirectly ? 'Direct Share' : 'Multi Share'
            } ${shareDirectly ? sharedDocType : documents.length}`
          : sharedDocType,
        labelName: isEmbedded() ? userData.userInfo.name : sharedDocName
      };

      segment.logEvent(shareEvent);

      if (bcc) {
        segment.logEvent({
          categoryName: 'BCC',
          actionName: `Bcc self shared from ${shareSection}`
        });
      }

      emailDocument({
        variables: {
          data: {
            ...(shareDirectly && { shareDirectly }),
            documents,
            sendTo,
            orderNumber,
            comments,
            language,
            bcc,
            ...(bcc && {
              bccEmail: loggedInUserEmail || userData.userInfo.email
            }),
            purchaseOrderId: purchaseOrderNumber,
            ...(replyTo
              ? { replyTo }
              : { replyTo: replyToOptions[0].optionValue }),
            subject
          }
        }
      });
    }
  };

  const _getShareMessage = () => {
    return EMAIL_SHARE_LANGUAGE_OPTIONS.find(
      option => option.optionValue === language
    ).getMessageText(purchaseOrderNumber, orderNumber);
  };

  return (
    <div>
      <form onSubmit={_handleSubmitForm}>
        <div className="units-row stacked">
          <div className="unit-100">
            <OxInput
              name={FIRST_ELEMENT}
              inputType="text"
              placeholder="Enter Recipient Email"
              floatLabel={false}
              label="Send To"
              required={isRequired}
              giDataAttr="email-form__subject"
              handleKeyUp={event => _setSendTo(event.target.value)}
              maxLength={144}
              value={sendTo}
              tooltipScrollable={false}
              tooltipContent="Send To must be a valid email"
              helperClass="no-bottom-margin"
            />
            <label
              htmlFor="checkbox_id"
              className="display--flex align-center nowrap cursor-pointer"
            >
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox_id"
                value={bcc}
                onClick={_handleToggleBcc}
              />
              <p className="padding margin-bottom ox_input__icon--rightb">
                I want to receive this email in my personal inbox.
              </p>
            </label>
          </div>
        </div>
        <div className="units-row stacked">
          <div className="unit-100">
            <OxInput
              name="subject"
              floatLabel={false}
              label="Email Subject"
              required
              hasError={subject.length === 0}
              handleKeyUp={e => _setSubject(e.target.value)}
              value={subject}
            />
          </div>
        </div>
        <div className="units-row stacked">
          <div className="unit-100">
            <OxSelect
              name="replyTo"
              label="Reply To"
              value={replyTo}
              options={replyToOptions}
              handleKeyUp={e => {
                _setReplyTo(e.target.value);
              }}
              tooltipContent="When your customer replies to this email, this is where the email will go."
            />
          </div>
        </div>
        <div className="units-row stacked email-form__language-select">
          <div className="unit-35">
            <OxSelect
              name="lang"
              options={EMAIL_SHARE_LANGUAGE_OPTIONS}
              value={language}
              handleKeyUp={event => {
                _setLanguage(event.target.value);
              }}
              label="Select Language"
              giDataAttr="email-form__lang-select"
              required={isRequired}
            />
          </div>
          <div className="unit-65 email-form__msg-preview">
            {_getShareMessage()}
          </div>
        </div>
        <div className="units-row stacked margin-top">
          <div className="unit-100">
            <OxTextarea
              required={false}
              name="body"
              placeholder="Enter additional comments"
              label="Enter additional comments"
              floatLabel={false}
              giDataAttr="email-form__body"
              handleKeyUp={event => {
                _setComments(event.target.value);
              }}
              value={comments}
            />
          </div>
        </div>
        <div className="display--flex nowrap email-form-btns">
          <OxButton
            id="email-form-btns__submit"
            buttonType="submit"
            text="Submit Email"
            type="blue"
            element="button"
            giDataAttr="email-form-btns__submit"
            helperClass={`${
              !isEmailFormatValid(sendTo) || !subject
                ? 'btn-disabled email-form-btns__submit'
                : 'email-form-btns__submit'
            }`}
            loading={loading}
          />
          <OxButton
            key="ox-modal__btn-secondary"
            id={CANCEL_ELEMENT}
            giDataAttr="email-form-btns__secondary"
            text="Cancel"
            type="secondary"
            element="button"
            clickFunc={_closeModal}
          />
        </div>
        {!loading && error && (
          <OxSoftAlert
            title="there's an error"
            alertType="warning"
            giDataAttr="email-form__network-error-alert"
            isOutlined
            eventName="network"
            helperClass="margin"
          >
            <p>We were unable to send the email</p>
          </OxSoftAlert>
        )}
      </form>
    </div>
  );
};

export default DocumentEmailForm;
