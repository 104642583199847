import React from 'react';

import OxCard from '../components/molecules/OxCard';
import OxSoftAlert from '../components/molecules/OxSoftAlert';

const CaseCreator = () => {
  return (
    <OxCard header={{ title: 'Case Creator' }}>
      <OxSoftAlert
        isOutlined
        alertType="info"
      >
        <p>
          As of 9-23-2024, all equipment cases are entered through Dynamics.
          For Equipment support, please create a case in Dynamics using the following link:&nbsp;
          <a href="https://intraloxllc0.crm.dynamics.com/">https://intraloxllc0.crm.dynamics.com</a>
        </p>
      </OxSoftAlert>
    </OxCard>
  );
};

export default CaseCreator;
