/**
 * Used to tie user info to events in Google Analytics
 *
 * Note: DO NOT pass personally identifiable information here, such
 * as emails, phone numbers, names, etc.
 * @param {*} userInfo
 */
const identify = userInfo => {
  window.analytics.identify({
    ...(userInfo.userType && {
      userType: userInfo.userType.name
    }),
    ...(userInfo.userRole && {
      userRole: userInfo.userRole.name
    }),
    ...(userInfo.jobTitle && {
      jobTitle: userInfo.jobTitle
    }),
    ...(userInfo.userType.name === 'api' && {
      appName: userInfo.name
    })
  });
};

const logEvent = ({
  giDataAttr = 'No giData',
  eventData = {},
  eventName = 'Error',
  actionName = '',
  categoryName = '',
  labelName = ''
}) => {
  if (eventName !== 'ignore' && window.analytics && window.analytics.user) {
    window.analytics.track(actionName || giDataAttr, {
      action: actionName,
      category: categoryName,
      label: labelName,
      event: giDataAttr,
      anonymousId: window.analytics.user().anonymousId() || 'userInfoMissing',
      properties: {
        eventName,
        eventData,
        ...(!!localStorage.ajs_user_traits && {
          userInfo: JSON.parse(localStorage.ajs_user_traits)
        })
      },
      integrations: {
        All: true
      },
      context: { ip: '0.0.0.0' }
    });
  }
};

const logPageView = () => {
  window.analytics.page({
    properties: {
      ...(localStorage.ajs_user_traits && {
        userInfo: JSON.parse(localStorage.ajs_user_traits)
      })
    }
  });
};

const eventTypes = {
  noData: 'Missing Data',
  network: 'Network Error',
  system: 'System Error',
  process: 'Could Not Complete request',
  unexpected: 'Unexpected Error',
  error: 'Error',
  info: 'Info Alert',
  notification: 'Notification',
  modal: 'Modal',
  password: 'Password Alert',
  access: 'Access Alert',
  noMatch: '404 Alert',
  ignore: 'ignore',
  connectivityIssue: 'Connectivity Issue',
  packingSlip: 'Packing Slip Not Available',
  accountInvoice: 'Accounting Invoice Not Available',
  orderShipment: 'No Shipment In Order',
  shareDoc: 'Shared Document',
  clicked: 'Element Selected',
  sort: 'Sorted Data'
};

export { identify, logEvent, eventTypes, logPageView };
