import Interior from '../../pages/Interior';
import EmbeddedShipmentList from '../../pages/EmbedOrderShipments';

const embedRoutes = {
  key: 'embed',
  icon: 'grid',
  title: 'Embed',
  path: '/embed',
  exact: true,
  giDataAttr: 'dashboardLink',
  component: Interior,
  isPrivate: false,
  routes: [
    {
      key: 'embed',
      icon: 'grid',
      title: 'embed',
      path: '/embed/orderShipments/:orderNumber/:accountNumber',
      exact: true,
      giDataAttr: 'dashboardLink',
      component: EmbeddedShipmentList,
      isPrivate: false
    }
  ]
};

export default embedRoutes;
