import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import '../../styles/components/_ox-soft-alert.scss';
import OxButton from './OxButton';
import * as segment from '../../helpers/segmentLogger';

const OxSoftAlert = ({
  alertType = 'default',
  icon = 'alert-circle',
  alertButton,
  isOutlined,
  giDataAttr,
  children,
  emphasizedTitle,
  id,
  title,
  eventName,
  eventData,
  helperClass
}) => {
  OxSoftAlert.propTypes = {
    isOutlined: PropTypes.bool,
    alertButton: PropTypes.shape({
      text: PropTypes.string,
      clickFunc: PropTypes.func,
      type: PropTypes.string
    }),
    alertType: PropTypes.oneOf([
      'default',
      'success',
      'warning',
      'error',
      'info'
    ]),
    title: PropTypes.string,
    emphasizedTitle: PropTypes.string,
    id: PropTypes.string,
    icon: PropTypes.string,
    giDataAttr: PropTypes.string.isRequired,
    eventName: PropTypes.oneOf(Object.keys(segment.eventTypes)),
    eventData: PropTypes.shape({}),
    helperClass: PropTypes.string
  };

  const _renderAlertButton = () => {
    const { clickFunc, text, type } = alertButton;
    let buttonType = type;

    if (!buttonType) {
      if (isOutlined) {
        switch (alertType) {
          case 'success':
            buttonType = 'success';
            break;
          case 'warning':
            buttonType = 'warning';
            break;
          case 'error':
            buttonType = 'error';
            break;
          case 'info':
            buttonType = 'secondary';
            break;
          default:
            buttonType = 'black';
        }
      } else {
        switch (alertType) {
          case 'success':
            buttonType = 'white';
            break;
          case 'warning':
            buttonType = 'white';
            break;
          case 'error':
            buttonType = 'white';
            break;
          case 'info':
            buttonType = 'secondary';
            break;
          default:
            buttonType = 'black';
        }
      }
    }

    return (
      <div className="ox-soft-alert__button">
        <OxButton
          id={`ox-soft-alert__buton-${Math.random()}`}
          text={text}
          type={buttonType}
          element="button"
          helperClass="width-100"
          isDisabled={false}
          clickFunc={clickFunc}
          giDataAttr={`${giDataAttr}_button`}
        />
      </div>
    );
  };

  const _getEmphasizedTitle = () => {
    if (emphasizedTitle) {
      return (
        <span className="ox-card__title--emphasized-title">
          {emphasizedTitle}
        </span>
      );
    }
    return null;
  };

  const _renderAlertTitle = () => {
    return !title ? null : (
      <h2
        className={`ox-soft-alert__title ${alertType}`}
        data-gi={`${giDataAttr}_title`}
      >
        {title} {_getEmphasizedTitle()}
      </h2>
    );
  };

  const _renderAlertIcon = () => {
    return icon === 'none' ? null : (
      <div className="ox-soft-alert__icon" data-gi={`${giDataAttr}_icon`}>
        <FeatherIcon icon={icon} />
      </div>
    );
  };

  const _getAlertClass = () => {
    return `ox-soft-alert 
      ${isOutlined && `outlined`}
      ${children && 'has-children'} 
      ${alertType !== 'default' && alertType}`;
  };

  const _getContentClass = () => {
    return `ox-soft-alert__content 
      ${alertButton && `has-button`}`;
  };

  useEffect(() => {
    if (eventName) {
      segment.logEvent({
        giDataAttr,
        eventName: segment.eventTypes[eventName],
        eventData
      });
    }
  });

  return (
    <label
      htmlFor={id}
      className={`${_getAlertClass()} ${helperClass}`}
      data-gi={giDataAttr}
    >
      {_renderAlertIcon()}
      <div className={_getContentClass()}>
        <div className="ox-soft-alert__text">
          {_renderAlertTitle()}
          {children}
        </div>
        <div>{alertButton ? _renderAlertButton() : null}</div>
      </div>
    </label>
  );
};

export default OxSoftAlert;
