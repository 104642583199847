import React from 'react';
import { Redirect, Route } from 'react-router';
import NoMatch from '../pages/NoMatch';

/**
 * Used to redirect users from routes with query params to routes with path params
 */
const RedirectWithQueryParams = ({ to, queryParams }) => {
  const urlParams = new URLSearchParams(window.location.search);

  /**
   * Builds the route to redirect to
   * @returns
   */
  const _getRedirectToUrl = () => {
    let redirectTo = to;

    /**
     * Loop through the query params array, continually replacing params in the
     * 'to' URL with their corresponding values from the urlParams in the 'from' URL
     */
    queryParams.forEach(param => {
      redirectTo = redirectTo.replace(`:${param}`, urlParams.get(param));
    });

    return redirectTo;
  };

  /**
   * Determine if we have all the parameters needed to redirect
   */
  const _allParamsMatch = () => {
    return queryParams.every(param => !!urlParams.get(param));
  };

  return _allParamsMatch() ? (
    <Redirect to={_getRedirectToUrl()} />
  ) : (
    <div className="interior-layout">
      <div className="interior-layout__content">
        <Route component={NoMatch} />
      </div>
    </div>
  );
};

export default RedirectWithQueryParams;
