import React from 'react';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import '../../styles/components/_dashboard-link.scss';

const DashboardLink = ({
  id,
  icon,
  localIcon,
  headline,
  tagline,
  url,
  target,
  giDataAttr,
  isInternalLink,
  onClick
}) => {
  DashboardLink.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string.isRequired,
    localIcon: PropTypes.element,
    headline: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isInternalLink: PropTypes.bool,
    target: PropTypes.string,
    giDataAttr: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  return isInternalLink ? (
    <Link
      to={url}
      className="dashboard-link"
      id={id}
      data-gi={giDataAttr}
      onClick={onClick}
    >
      <span className="dashboard-link__icon">
        {(localIcon && React.createElement(localIcon)) || (
          <FeatherIcon icon={icon} />
        )}
      </span>
      <span className="dashboard-link__copy">
        <h4>{headline}</h4>
        <p>{tagline}</p>
      </span>
    </Link>
  ) : (
    <a
      href={url}
      id={id}
      target={target}
      className="dashboard-link"
      data-gi={giDataAttr}
      onClick={onClick}
    >
      <span className="dashboard-link__icon">
        {(localIcon && React.createElement(localIcon)) || (
          <FeatherIcon icon={icon} />
        )}
      </span>
      <span className="dashboard-link__copy">
        <h4>{headline}</h4>
        <p>{tagline}</p>
      </span>
    </a>
  );
};

export default DashboardLink;
